 import React from "react";
import {
  Image,
  Dimensions,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  TextInput
} from "react-native";

import axios from "axios";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";
import DeviceComponent from "../components/DeviceComponent";

//import './WebshopEditScreen.css';

const DefaultImg = "";




export default class UserScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user:this.props.navigation.getParam("item", "<<<<empty>>>>"),
      name: this.props.navigation.getParam("item", "<<<<empty>>>>").name,
      phone: this.props.navigation.getParam("item", "<<<<empty>>>>").phone,
      email: this.props.navigation.getParam("item", "<<<<empty>>>>").email,
      firstName: this.props.navigation.getParam("item", "<<<<empty>>>>").firstName,
      lastName: this.props.navigation.getParam("item", "<<<<empty>>>>").lastName,
      username: this.props.navigation.getParam("item", "<<<<empty>>>>").username,
      roles: this.props.navigation.getParam("item", "<<<<empty>>>>").roles,
      devices: this.props.navigation.getParam("item", "<<<<empty>>>>").devices,
      language: this.props.navigation.getParam("item", "<<<<empty>>>>").language,
    };
  }

  componentDidMount() {

  }

  handleTextInputClick = (text, x) => {
    this.setState({ [x]: text });
  };

  
  updateUser = async (i, value) => {
  
    let data = {
      name: this.state.name,
      username: this.state.username,
      email: this.state.email,
      firstName: this.state.firstName,
      password: this.state.password,
      infixes: this.state.infixes,
      lastName:this.state.lastName,
      birthdate: this.state.birthdate,
      verificationCode: this.state.verificationCode,
      senderName: this.state.senderName,
      country: this.state.country,
      language: this.state.language,
      phone: this.state.phone,
      
     
      // color:'pink'
    };

    const userId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.updateUser + userId, data)
          .then(res => {
            alert("gelukt");
          });
      } catch (err) {
        // this.updateWebshop();
        alert("jammer joh");
      }
    }
  };





  setToActive = async ( ) => {
   
    let data = {
    stateId:2,
   
    };

    const userId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.updateUser + userId, data)
          .then(res => {
            alert("gelukt");
          });
      } catch (err) {
        // this.updateWebshop();
        alert("jammer joh");
      }
    }
    
  }
  sendTestNotification = async ( ) => {
   
    let data = {
    message:'Dit is een test notificatie, message',
    title:'Dit is een test notificatie, title',
    userId:2
    };

   

    const userId = this.props.navigation.getParam("item").id;

    
      try {
        await axios
          .post(apiEndpoint.sendPushNotification, data)
          .then(res => {
            alert("gelukt");
          });
      } catch (err) {
        // this.updateWebshop();
        alert("jammer joh");
      }
    
    
  }
  render() {
    const {state} = this.state.user

    
    return (
      <div
        style={{
          position: "absolute",

          height: Dimensions.get("screen").height,
          width: Dimensions.get("screen").width,
          backgroundColor: colors.background
        }}
      >
        <BobsHeader
          title={"UserEditScreen"}
          handleBack={() => this.props.navigation.navigate("Users")}
        />
       
       <testIng/>
       
       {/* <View style={{marginTop:60}}/> */}
       
        <View style={{ flexDirection: "row", }}>
        <View style={{   }}>
        <View style={{ flexDirection: "row", marginVertical: 20 }}>
          <TextInputComponent
          label={'username'}
          placeholder={"<<<<empty>>>>"}
          onChangeText={text => this.handleTextInputClick(text, "username")}
          value={this.state.username}
          />
          <TextInputComponent
           label={'firstName'}
          placeholderTextColor={colors.text}
          placeholder={"<<<<empty>>>>"}
          onChangeText={text => this.handleTextInputClick(text, "firstName")}
          value={this.state.firstName}
          />
          </View>
        <View style={{ flexDirection: "row", marginVertical: 20 }}>
          <TextInputComponent
          label={'lastName'}
          placeholder={"<<<<empty>>>>"}
          onChangeText={text => this.handleTextInputClick(text, "lastName")}
          value={this.state.lastName}
          />
          <TextInputComponent
           label={'phone'}
          placeholderTextColor={colors.text}
        placeholder={"<<<<empty>>>>"}
          onChangeText={text => this.handleTextInputClick(text, "phone")}
          value={this.state.phone}
          />
          </View>
        <View style={{ flexDirection: "row", marginVertical: 20 }}>
          <TextInputComponent
          label={'infixes'}
          placeholder={"<<<<empty>>>>"}
          onChangeText={text => this.handleTextInputClick(text, "infixes")}
          value={this.state.infixes}
          />
          <TextInputComponent
          label={'Email'}
          placeholder={"<<<<empty>>>>"}
          onChangeText={text => this.handleTextInputClick(text, "email")}
          value={this.state.email}
          />
          </View>
          <TextInputComponent
           label={'rollen'}
          placeholderTextColor={colors.text}
          //  placeholder={"item.placeholder"}
            placeholder={"<<<<empty>>>>"}
          onChangeText={text => alert(text, "rollen")}
          value={this.state.roles ? this.state.roles.map(item => item.name) : null}
          /> 
          <TextInputComponent
           label={'language'}
          placeholderTextColor={colors.text}
          //  placeholder={"item.placeholder"}
            placeholder={"<<<<empty>>>>"}
          onChangeText={text => this.handleTextInputClick(text, "language")}
        
          value={this.state.language}
          /> 
       

</View>
   
      <View>

      <TouchableOpacity
          onPress={() => this.setToActive()}
          style={{
           // top: 70,
            // left: 330,
            borderRadius: 6,
            borderColor: colors.text,
            borderWidth: 1,
            // alignSelf:"center",
            backgroundColor: colors.component,
            width: 150,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            alignSelf:'center',
            margin:5
          }}
        >
          <Text style={{ color: colors.text }}>Set to Active</Text>
        </TouchableOpacity>
<TouchableOpacity
          onPress={() => this.updateUser()}
          style={{
           // top: 70,
            // left: 330,
            borderRadius: 6,
            borderColor: colors.text,
            borderWidth: 1,
            // alignSelf:"center",
            backgroundColor: colors.component,
            width: 100,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            alignSelf:'center',
            margin:5
          }}
        >
          <Text style={{ color: colors.text }}>Update User</Text>
        </TouchableOpacity>
<TouchableOpacity
          onPress={() => this.sendTestNotification()}
          style={{
           // top: 70,
            // left: 330,
            borderRadius: 6,
            borderColor: colors.text,
            borderWidth: 1,
            // alignSelf:"center",
            backgroundColor: colors.component,
            width: 100,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            alignSelf:'center',
            margin:5
          }}
        >
          <Text style={{ color: colors.text }}>Send test notificatie</Text>
        </TouchableOpacity>




   </View>
         


         
 
</View>
       
     
      


   
      

        {this.state.devices.map(item =>
        
        
        <DeviceComponent 
        item={item}
        
        />
 
         )}
      

        <Text>{JSON.stringify(this.props.navigation.getParam("item", "<<<<empty>>>>"), null, 4)}</Text>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    position: "absolute",
    fontWeight: "bold",
    bottom: 40,
    color: colors.text,
    fontSize: 14,
    left: 5
  },
  loginButton: {
    position: "absolute",
    bottom: 10,
    borderTopWidth: 1,
    width: "100%",
    height: 40,
    justifyContent: "center",
    borderTopColor: colors.white,
    alignSelf: "center",
    marginRight: 30
  },
  registerButton: {
    width: "60%",
    borderWidth: 1,
    alignSelf: "center",
    height: 30,
    marginTop: 10,
    justifyContent: "center",
    borderRadius: 6,
    borderColor: colors.white,
    bottom: 40,
    width: 350,
    backgroundColor: colors.component,
    alignSelf: "center"
  },
  logoContainer: {
    width: "100%",
    top: 30,
    position: "absolute",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  logo: {
    color: colors.white,
    fontSize: 80,
    alignSelf: "center"
    // opacity:0.5
  },
  textInput: {
    height: 40,
    // marginLeft: 10,
    width: Dimensions.get("window").width - 40,
    alignSelf: "center",
    color: colors.white,
    paddingLeft: 30
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center"
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.white,
    marginTop: 10,
    borderRadius: 8,
    bottom: 40,
    width: 350,
    //alignSelf:'center',
    backgroundColor: colors.component
  }
});
