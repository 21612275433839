import React from 'react'
import {View, TouchableOpacity, Text} from 'react-native';

import colors from '../../../styles/colors'

export default class ListButtonComponent extends React.Component {
    render(){

        const icon = this.props.icon
        const text = this.props.text
        const number = this.props.number
        return(

            <View style={{flexDirection:'row'}}>

<View style={{width:20, height:20, borderRadius:10,
    marginHorizontal:10,
    alignSelf:'center',
    backgroundColor:colors.component}}/>
          
            <TouchableOpacity 
            onPress={()=> this.props.onPress()}
            style={{
                backgroundColor:colors.component, 
                width:200,
                height:30,
                borderRadius:6,
                marginVertical:5,
              //  justifyContent:'space-between',
                alignSelf:'flex-end',
                alignItems:'center',
                flexDirection:'row'
                }}>
                    
                    <View style={{
                        position:'absolute',
                        left:5
                        }}>
                        {icon}
                    </View>
<Text style={{color:colors.text, position:'absolute', left:15}}> {text}</Text>
<Text style={{color:colors.text,fontSize:20, color:colors.green, position:'absolute', right:10}}> {number}</Text>



            </TouchableOpacity>
            </View>
        )
    }


}