import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";
import moment from 'moment'

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import ListHeaderComponent from "../../dashboard/components/ListHeaderComponent";
import ListButtonComponent from "../../dashboard/components/ListButtonComponent";

import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import DvrIcon from "@material-ui/icons/Dvr";
import GroupIcon from "@material-ui/icons/Group";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EuroIcon from "@material-ui/icons/Euro";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import CreateIcon from '@material-ui/icons/Create';

export default class TabletScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: "",
      isLoading: true
    };
  }

  componentDidMount() {
    const refreshTime = 20000  // 20 seconds
    this._getDataSource();
    this.interval = setInterval(() =>  this._getDataSource(), refreshTime);
  }

  onRefresh = () => {
    this._getDataSource();
  };

  handleLogOut = () => {
    localStorage.clear(this.props.navigation.navigate("Login"));
  };

  _getDataSource = async () => {

    await axios
      .get(apiEndpoint.getTablet)
      .then(res => this.setState({ dataSource: res.data, isLoading: false }))
      .catch(err => {
        console.log("err: steffiescherm" + err);
        //   this._getDataSource()
      });
  };

  handleNavigation = (route) => {
    this.props.navigation.navigate(route
      );

   // console.log("Item: =>" + console.table(item));
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background
          }}
        >
          {/* <ActivityIndicator /> */}
        </View>
      );
    } else {
      return (
        <View
          style={{
             flex:1,
            position:'absolute',
            height: Dimensions.get("window").height,
            width: Dimensions.get("window").width,
             flex:1,
            backgroundColor: colors.background,
          // flexDirection: "row",
         //   flexWrap: "wrap"
          }}
        >
               <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Tablet"}
          />
          {/* <ListComponent /> */}

<View>

<View style={{ marginTop: 20, marginHorizontal: 10 }}>
            <ListHeaderComponent text={'To Validate'} icon={<VisibilityIcon style={{fontSize:35, color:colors.red}}/>}/>
            {Object.entries(this.state.dataSource.toValidate).map(([key, val]) => (
            <ListButtonComponent
            onPress={()=> this.handleNavigation(key)}
            key={key}
            text={key}
            number={val}
            />
            ))}
          </View>

          <View style={{ marginTop: 20, marginHorizontal: 10  }}>
        <ListHeaderComponent text={'Monitor'} icon={<DvrIcon style={{fontSize:35, color:colors.green}} />}/>
            {Object.entries(this.state.dataSource.toMonitor).map(([key, val]) => (
            <ListButtonComponent 
            onPress={()=> this.handleNavigation(key)}
            key={key}
            text={key}
            number={val}
            />
            ))}
          </View>
          <View style={{ marginTop: 20, marginHorizontal: 10 }}>
            <ListHeaderComponent text={'Scrape'} icon={<BlurLinearIcon style={{fontSize:35, color:colors.blue}} />}/>
            {Object.entries(this.state.dataSource.toScrape).map(([key, val]) => (
            <ListButtonComponent 
            onPress={()=> this.handleNavigation(key)}
            key={key}
            text={key}
            number={val}
            />
            ))}
          </View>


</View>
<View style={{
              position:'absolute',
              left:200,
              flexDirection:'row',
              bottom:200,
              }}>


            <Text style={{
             
              color:colors.white}}>Last refresh: </Text>
            <Text style={{
             
              color:colors.green}}> {moment(new Date()).format('YYYY-MMMM-DD     HH:mm:ss')}</Text>

              </View>

        </View>
      );
    }
  }
}


