import React, { Component } from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";

import AppNavigator from "./app/navigation/AppNavigator";
import axios from 'axios';

import apiEndpoint from "./app/helpers/networking/apiEndpoint";
import colors from "./app/styles/colors";




function getAccessToken(){
  return localStorage.getItem('accessToken');
}

//Use interceptor to inject the token to requests
axios.interceptors.request.use(request => {
  console.log('request => ', request)
  request.headers['auth-token'] = `${getAccessToken()}`;
  return request;
},error => {
  console.log(error)
  return Promise.reject(error)
}
);



axios.interceptors.response.use(response => {
  console.log('response => ', response)

  return response;
},error => {

  if (error.response.status !== 401) {
    return Promise.reject(error);
  }
  const refreshToken =  localStorage.getItem("refreshToken");
  const header = {
    "Content-Type": "application/json",
    token: refreshToken
  };

  return axios
  .post(

    apiEndpoint.refreshToken,
    {
    },
    {
      headers: header
    }
  )
    .then(response => {
      localStorage.setItem("accessToken", response.data.accessToken);
      axios.defaults.headers.common["auth-token"] = `${
        response.data.accessToken
      }`;
      error.hasRefreshedToken = true;
     return axios.request(error.config);
    })
    .catch(() => {
      const tokenError = new Error("Cannot refresh token");
      tokenError.originalError = error;
      return Promise.reject(tokenError);
    });
}
);

class App extends Component { 
  state = {
    users: []
  };

  render() {
    return (
      <View>
        {

apiEndpoint.mode==='production' ? 

<TouchableOpacity
style={{
    // marginVertical:3,
    justifyContent:'center',alignItems:'center',
    alignSelf:'center',backgroundColor:colors.green, width:Dimensions.get('screen').width, height:30}}
onPress={()=> this.handleLogOut()}
>

        <Text style={{color:'white', fontSize:20}}> Production version </Text>
       
</TouchableOpacity> : null

}
        {

apiEndpoint.mode=='development' ? 

<TouchableOpacity
style={{
    // marginVertical:3,
    justifyContent:'center',alignItems:'center',
    margin:1,
    alignSelf:'center',backgroundColor:colors.red, width:Dimensions.get('screen').width, height:30}}
onPress={()=> this.handleLogOut()}
>

        <Text style={{color:'white', fontSize:20}}> Development version </Text>
       
</TouchableOpacity> : null

}
        <AppNavigator />
      </View>
    );
  }
}

export default App;
