import React, { Component } from "react";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Linking
} from "react-native";
import axios from "axios";
import moment from 'moment'

import ReactDataGrid from 'react-data-grid';
import colors from '../../styles/colors'

export default class LineItemsReactDataGrid extends Component {
render(){
    const ErrorComponent = ( {text} ) => {
        // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
      return <div style={{
        borderRadius:3,
        width:120,
        justifyContent:'center',
        alignItems:'center',
        display:"flex",
        backgroundColor:colors.red}}> <a style={{
     
        color:colors.white, 
       // fontWeight:'bold'
      }}>{text}</a> </div>;
          };

    let data = this.props.data ? this.props.data : [{dummy:''}]

    const productFormatter = ({ value }) => {
        // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
        return <View >{value ? <Text style={{color:colors.green,}}>{value.name}</Text>:<ErrorComponent text={'name'}/>} </View>;
          };
    const serialNumbersFormatter = ({ value }) => {
        // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
        return <View >{value ? <Text style={{color:colors.green,}}>{JSON.stringify(value)}</Text>:<ErrorComponent text={'name'}/>} </View>;
          };
    const createdAtFormatter = ({ value }) => {
        // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
        return <View >{value ? <Text style={{color:colors.green,}}>{moment(value).format(`DD-MM '19 HH:mm`)}</Text>:<ErrorComponent text={'name'}/>} </View>;
          };

    return(

     <ReactDataGrid

     columns={ [
      {
        width: 40,
        key: 'id', name: 'id' , sortable : true, },
        { key: 'productId', name: 'productId', width: 90, },
        { key: 'product', name: 'name', width: 300,formatter:productFormatter},
        { key: 'pricePaid', name: 'pricePaid', width: 100, },
        { key: 'createdAt', name: 'createdAt', width: 120, formatter:createdAtFormatter},
        { key: 'serialNumbers', name: 'serialNumbers', width: 140,formatter:serialNumbersFormatter },

    ]}
    onGridSort={this.handleGridSort}
     //  rowGetter={i => rows[i]}
     rowGetter={i => data[i] }
     rowsCount={data ? data.length :  0}
    // rowsCount={20}
     width={100}
     minHeight={400}
     onRowDoubleClick={(index,item, attribute)=> this.onRowDoubleClick(index,item, attribute)}
  
     />
   
    )
}

}