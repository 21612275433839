import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import axios from "axios";

import colors from "../../../styles/colors";
import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import { Document, Page, pdfjs } from "react-pdf";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import InvoiceComponent from "../components/InvoiceComponent";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import CheckIcon from "@material-ui/icons/Check";
import ListButtonComponent from "../../dashboard/components/ListButtonComponent";
export default class SteffieToValidateScreen extends React.Component {
  constructor(props) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.navigation.getParam("item").id) {
      this._getDataSource();
      this.getInvoice();
    }
  }

  _getDataSource = async () => {
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getSteffie + steffieId)
        .then((res) => this.setState({ dataSource: res.data }));
    } catch (err) {
      alert("err");
    }
  };
  getInvoice = async () => {
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getSteffieBase64 + steffieId)
        .then((res) =>
          this.setState({ invoice: res.data.invoice, isLoading: false })
        );
    } catch (err) {
      console.log("Wt is er aan de hand? ", err);
      alert("error");
    }
  };

  // sendPushNotification = async () => {
  //   const fcmTokenOfDevice = req.body.fcmTokenOfDevice;
  //   const message = req.body.message
  //   const title = 'Je Steffie voor je bestelling biis klaar'

  //   try {
  //     await axios
  //       .post(apiEndpoint.sendPushNotification + invoiceId, data)
  //       .then((res) => {
  //         alert("jup");
  //         console.log("uh huh huh uh u : => ", res);
  //         // alert("gelukt");
  //       }).then(()=> this.sendPushNotification());
  //   } catch (err) {
  //     alert("jammer joh");
  //   }
  // }

  handleInvoiceToAccepted = async (i, value) => {
    const userId = this.props.navigation.getParam("item").userId;
    const webshopId = this.props.navigation.getParam("item").webshopId;
    let data = {
      queStatus: this.state.queStatus,
      emailComplaints: this.state.emailComplaints,
      invoiceDate: this.state.invoiceDate,
      paymentAmount: this.state.paymentAmount,
      deliveryCosts: this.state.deliveryCosts,
      endReturnPeriodDate: this.state.endReturnPeriodDate,
      webshopId: webshopId,
      queueId: 4,
      userId: userId,
      //    returnPeriodDays: this.state.twitter
    };

    // const item = this.props.navigation.getParam('item')

    const invoiceId = this.props.navigation.getParam("item").id;

    try {
      await axios
        .put(apiEndpoint.putSteffie_lineItems_accepted + invoiceId, data)
        .then((res) => {
          alert("jup");
          console.log("uh huh huh uh u : => ", res);
          // alert("gelukt");
        });
    } catch (err) {
      alert("jammer joh");
    }
  };

  // handleInvoice

  render() {
    const { numPages } = this.state;
    const { refeuroInvoiceId } = this.props.navigation.getParam("item");

    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background,
          }}
        >
          <ActivityIndicator />
        </View>
      );
    }
    return (
      <View
        style={{
          backgroundColor: colors.background,
          height: Dimensions.get("screen").height,
          width: Dimensions.get("screen").width,
          position: "absolute",
        }}
      >
        <BobsHeader
          handleBack={() => this.props.navigation.navigate("Dashboard")}
          title={"SteffieToValidateScreen"}
        />

        <View style={{ flexDirection: "row" }}>
        {this.state.invoice ?  <Document
            // file={samplePDF}
            // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
            file={`data:application/pdf;base64,${this.state.invoice}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document> : null}

          {/* <View
            style={{
              flex: 1,
              backgroundColor: colors.blue,
              position: "absolute",
              zIndex: 1,
              height: 1000,
              width: 600,
              opacity: 0.4,
            }}
          /> */}
          <View style={{ marginHorizontal: 20 }}>
          {this.state.invoice ?    <InvoiceComponent item={this.state.dataSource} /> : null}

            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => this.handleInvoiceToAccepted()}
                style={{
                  marginVertical: 40,
                  backgroundColor: colors.component,
                  borderWidth: 1,
                  borderColor: colors.text,
                  width: 200,
                  height: 40,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 6,
                }}
              >
                <ThumbUpIcon
                  style={{
                    color: colors.green,
                    position: "absolute",
                    left: 10,
                  }}
                />
                <Text style={{ color: colors.text }}>Steffie is goed</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  alert(
                    "Denk dat we dan alle facturen die worden geupload voor deze webshop moeten stopzetten"
                  )
                }
                style={{
                  marginVertical: 40,
                  backgroundColor: colors.component,
                  borderWidth: 1,
                  borderColor: colors.text,
                  width: 200,
                  height: 40,
                  marginLeft: 10,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 6,
                }}
              >
                <Text style={{ color: colors.text }}>Steffie is niet goed</Text>
                <ThumbDownIcon
                  style={{ color: colors.red, position: "absolute", right: 10 }}
                />
              </TouchableOpacity>
           
            </View>
          </View>

          <View
            style={{
              // marginHorizontal: 30,
              flexDirection: "column",
              // display: "flex",
            }}
          >
       
        </View>
        </View>

        <Text style={{color:colors.red, fontSize:17}}>{JSON.stringify(this.state.dataSource, null, 4)}</Text>
      </View>
    );
  }
}
