import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import ListHeaderComponent from "../components/ListHeaderComponent";
import ListButtonComponent from "../components/ListButtonComponent";

// ########## ICONS 4 ##############
import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";

import StoreIcon from "@material-ui/icons/Store";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import DvrIcon from "@material-ui/icons/Dvr";
import GroupIcon from "@material-ui/icons/Group";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EuroIcon from "@material-ui/icons/Euro";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment'


class DashboardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: "",
      isLoading: true,
      refreshedAt:'initial',
      counter:0
    };
  }

  componentDidMount() {
   const refreshTime = 20000  // 20 seconds
    this._getDataSource();
    this.interval = setInterval(() =>  this._getDataSource(), refreshTime);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onRefresh = () => {
    this._getDataSource();
  };

  handleLogOut = () => {
    localStorage.clear(this.props.navigation.navigate("Login"));
  };

  _getDataSource = async () => {
    // const auth_header = await authJWT.getHeader_Auth(apiEndpoint.getDashboard);

    // const accessToken = await authJWT.getAccessTokenFromAsyncStorage();
    // const getHeader_Auth = {
    //   "Content-Type": "application/json",
    //   "auth-token": accessToken
    // };

    const refreshedAt = moment(new Date()).format('HH:mm:ss')
    const counter = this.state.counter

    await axios
      .get(apiEndpoint.getDashboard,
        
      //  { headers: auth_header }
        
        )
      .then(res => this.setState({ dataSource: res.data, isLoading: false, refreshedAt:refreshedAt, counter:counter+1 }))
      .catch(err => {
        console.log("err: steffiescherm" + err);
        //   this._getDataSource()
      });
  };

  handleNavigation = (route) => {
    this.props.navigation.navigate(route
      );

   // console.log("Item: =>" + console.table(item));
  };

  handleLogOut = () => {
    localStorage.clear();
  };

  handleDeleteLineItemsNewLineItems =  async () => {
    await axios
    .post(apiEndpoint.deleteLineItemsNewLineItems,
      
    //  { headers: auth_header }
      
      )
    // .then(res => this.setState({ dataSource: res.data, isLoading: false,}))
    .then(res => alert('handleDeleteLineItemsNewLineItems function gelukt'))
    .catch(err => {
      console.log("err: steffiescherm" + err);
      //   this._getDataSource()
    });
  }


  render() {

    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background
          }}
        >
          {/* <ActivityIndicator /> */}
        </View>
      );
    } else {
      return (
        <View
          style={{
            // flex:1,
            height: Dimensions.get("screen").height,
            //  flex:1,
            backgroundColor: colors.background,
          //  flexDirection: "row",
            flexWrap: "wrap"
          }}
        >


<Text style={{color:colors.green, fontSize:30}}>{this.state.refreshedAt}</Text>
     
<View style={{flexDirection:'row'}}>


          <View style={{ marginTop: 20, marginHorizontal: 10  }}>
        <ListHeaderComponent text={'Monitor'} icon={<DvrIcon style={{fontSize:35, color:colors.green}} />}/>
            {Object.entries(this.state.dataSource.toMonitor).map(([key, val]) => (
            <ListButtonComponent 
            onPress={()=> this.handleNavigation(key)}
            key={key}
            text={key}
            number={val}
            />
            ))}
          </View>
          
          <View style={{ marginTop: 20, marginHorizontal: 10 }}>
            <ListHeaderComponent text={'Scrape'} icon={<BlurLinearIcon style={{fontSize:35, color:colors.blue}} />}/>
            {Object.entries(this.state.dataSource.toScrape).map(([key, val]) => (
            <ListButtonComponent 
            onPress={()=> this.handleNavigation(key)}
            key={key}
            text={key}
            number={val}
            />
            ))}
          </View>
          <View style={{ marginTop: 20, marginHorizontal: 10 }}>
            <ListHeaderComponent text={'To Validate'} icon={<VisibilityIcon style={{fontSize:35, color:colors.red}}/>}/>
            {Object.entries(this.state.dataSource.toValidate).map(([key, val]) => (
            <ListButtonComponent 
            onPress={()=> this.handleNavigation(key)}
            key={key}
            text={key}
            
            number={val}
            />
            ))}
          </View>
         
          <View style={{flexDirection:'row'}}>
          <View style={{ marginTop: 20, marginHorizontal: 10 }}>
            <ListHeaderComponent text={'Overview'} icon={<ViewComfyIcon style={{fontSize:35, color:colors.background}} />}/>
            {Object.entries(this.state.dataSource.overview).map(([key, val]) => (
            <ListButtonComponent 
            onPress={()=> this.handleNavigation(key)}
            key={key}
            text={key}
            number={val}
            />
            ))}
          </View>
          <TouchableOpacity 
              style={{width:300,borderRadius:6,
                borderColor:colors.green,
                borderWidth:5,
                justifyContent:'center', alignItems:'center', height:40, backgroundColor:colors.component}}
          onPress={()=> this.handleDeleteLineItemsNewLineItems()}>
                    <Text style={{color:colors.text}}>Delete LineImems, new lineItems</Text>
          </TouchableOpacity>
        
          </View>

          </View>         


        </View>
      );
    }
  }
}

export default DashboardScreen;
