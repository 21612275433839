import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";

import moment from "moment";
import DatePicker from "../../../globalComponents/pickers/DatePicker";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import axios from "axios";

import colors from "../../../styles/colors";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TextInputComponent from "../components/TextInputComponent";

import ThumbDownIcon from "@material-ui/icons/ThumbDown";

import SearchComponent from "../../../globalComponents/search/SearchComponent";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import LineItemComponent from "../components/LineItemComponent";
import CreateLineItemComponent from "../components/CreateLineItemComponent";
import TextFieldComponent from "../components/TextFieldComponent";
import PriceInputComponent from "../components/PriceInputComponent";
import EditLineItemModal from "../components/EditLineItemModal";
import SnoozeIcon from "@material-ui/icons/Snooze";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
export default class SteffieScrapeScreen extends Component {
  constructor(props) {
    super(props);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      numPages: null,
      rejectionModal: false,
      isLoading: true,
      webshopId: this.props.navigation.getParam("item").webshopId,
      webshop: this.props.navigation.getParam("item").webshop,
      userId: this.props.navigation.getParam("item").userId,
      steffieId: this.props.navigation.getParam("item").id,
      orderNumber: this.props.navigation.getParam("item").orderNumber,
      invoiceNumber: this.props.navigation.getParam("item").invoiceNumber,
      paymentAmount: this.props.navigation.getParam("item").paymentAmount,
      orderDate: this.props.navigation.getParam("item").orderDate,
      invoiceDate: this.props.navigation.getParam("item").invoiceDate,
      discount: this.props.navigation.getParam("item").discount,
      invoices: this.props.navigation.getParam("item").invoices,
      // products: this.props.navigation.getParam('item').lineItems,
      products: this.props.navigation.getParam("item").lineItems,
      totalAmountCheck: "",
      //  webshopProducts: [],

      productName: "",
      pricePaid: "",
      lineItems: [],
      selectedLineItem: "",
      editLineItemModal: false,
      invoiceToOld: false,
      webshopName: this.props.navigation.getParam("item").webshop
        ? this.props.navigation.getParam("item").webshop.name
        : "",
      lineItemsCheckDone: false,
      uniqueIdentifier: "",

      // webshopName: "",
    };
  }

  componentDidMount() {
    this._getDataSource();
    //this.getInvoiceLineItems()  //
    this.getSteffieLineItems();
    this.setDefaultDates();
  }

  calculateTotalAmount = () => {
    // alle lineItems
    // Discount

    if (this.state.lineItems.length !== 0) {
      const lineItemsPricePaid = this.state.lineItems.map(
        (item) => item.pricePaid
      );

      const totalAmount = lineItemsPricePaid.reduce((accumulator, item) => {
        console.log("******************************************");
        console.log("accumulator => ", accumulator);
        console.log("item => ", item);
        console.log("******************************************");
        return Number(accumulator) + Number(item);
      });

      const totalAmountCheck = totalAmount - this.state.discount;
      this.setState({ totalAmountCheck: totalAmountCheck });
    }
  };

  setDefaultDates = () => {
    if (!this.props.navigation.getParam("item").invoiceDate) {
      const invoiceDate = new Date();

      const orderDate = new Date();
      this.setState({
        invoiceDate: invoiceDate,
        orderDate: orderDate,
      });
    }
  };

  _removeTextLayerOffset = () => {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.display = "none";
    });
  };

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
    });
  };
  removeTextLayerOffset = () => {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  };

  _getDataSource = async () => {
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getSteffieBase64 + steffieId)
        .then((res) =>
          this.setState({ dataSource: res.data.invoice, isLoading: false })
        );
    } catch (err) {
      this._getDataSource();
    }
  };

  getSteffieLineItems = async () => {
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getSteffieLineItems + steffieId)
        .then((res) =>
          this.setState({ lineItems: res.data, isLoading: false }, () =>
            this.calculateTotalAmount()
          )
        );
    } catch (err) {
      //  this._getDataSource();
    }
  };

  updateText = (text, type, index) => {
    const newItems = [...this.state.products];
    newItems[index][type] = text;
    this.setState({
      products: newItems,
    });
  };

  handleTextInputSearch = (text, x) => {
    if (!text) {
      console.log("geen text");
    } else {
      this.setState({ [x]: text.name, productId: text.id });
    }
  };
  onChangeText = (text, x) => {
    this.setState({ [x]: text });
    // setTimeout(()=> alert(text), 3000)
  };

  updateSteffie = async (i, value) => {
    let data = {
      invoiceNumber: this.state.invoiceNumber,
      orderNumber: this.state.orderNumber,
      queStatus: this.state.queStatus,
      emailComplaints: this.state.emailComplaints,
      invoiceDate: this.state.invoiceDate,
      orderDate: this.state.orderDate,
      paymentAmount: this.state.paymentAmount,
      deliveryCosts: this.state.deliveryCosts,
      discount: this.state.discount,
      webshopId: this.state.webshopId,
      //   queueId:2,
      //    returnPeriodDays: this.state.twitter
    };

    const steffieId = this.props.navigation.getParam("item").id;

    try {
      await axios.put(apiEndpoint.putSteffie + steffieId, data).then((res) => {
        console.log("uh huh huh uh u : => ", res);
        this.calculateTotalAmount();
        // alert("gelukt");
      });
    } catch (err) {
      alert("jammer joh");
    }
  };

  setWebshopId = async () => {
    let data = {
      webshopId: this.state.webshopId,
    };

    const steffieId = this.props.navigation.getParam("item").id;

    try {
      await axios.put(apiEndpoint.putSteffie + steffieId, data).then((res) => {
        this.calculateTotalAmount();
      });
    } catch (err) {
      alert("jammer joh");
    }
  };

  deleteLineItem = async (item, index) => {
    const lineItemId = this.state.selectedLineItem.id;
    try {
      await axios
        .delete(apiEndpoint.deleteLineItem + lineItemId)
        .then((res) => {
          console.log("res: " + JSON.stringify(res));
          this.getSteffieLineItems();
          this.handleEditLineItemModal();
          //  alert("gelukt: ", JSON.stringify(res));
        });
    } catch (err) {
      alert("jammer joh");
    }
  };

  handleDate = (date) => {
    setTimeout(() => alert(date), 1000);
  };

  handleReadyForValidation = async (i, value) => {
    // if(this.state.lineItems.length <=1) return alert('No lineItems')
    if (this.state.pricePaid.length <= 1) return alert("PricePaid onjuist");
    if (this.state.productName.length <= 3) return alert("productName onjuist");
    let data = {
      steffieId: this.state.steffieId,
    };

    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .put(apiEndpoint.putReadyForValidation + steffieId, data)
        .then((res) => {
          console.log("uh huh huh uh u : => ", res);

          this.props.navigation.navigate("SteffiesToScrape");
        });
    } catch (err) {
      alert("jammer joh");
    }
  };

  // queueId:5
  // messageToUser

  rejectSteffie = () => {
    this.props.navigation.navigate("SteffieRejection", {
      item: this.props.navigation.getParam("item"),
    });
    this.handleRejectionModal();
  };

  handleRejectionModal = () => {
    const rejectionModal = this.state.rejectionModal;
    this.setState({
      rejectionModal: !rejectionModal,
    });
  };

  handleTextInput = (type, text) => {
    this.setState({
      [type]: text,
    });
  };

  duplicateLineItem = async () => {};
  addNewLineItem = async (type) => {
    if (this.state.pricePaid.length <= 1) return alert("PricePaid onjuist");
    if (this.state.productName.length <= 3) return alert("productName onjuist");

    const data = {
      productName: this.state.productName,
      pricePaid: this.state.pricePaid,
      webshopId: this.state.webshopId,
      invoiceId: this.state.invoices[0].id,
      steffieId: this.state.steffieId,
      userId: this.state.userId,
    };
    //alert(JSON.stringify(data))
    try {
      await axios
        .post(apiEndpoint.createLineItemCreateProductIfNotExist, data)
        .then((res) => {
          this.handleReadyForValidation();
        });
      //   this.getSteffieLineItems();
      //   if (type === "next") {
      //     this.setState({
      //       pricePaid: "",
      //       productName: "",
      //     });
      //   }
      // });
    } catch (error) {
      alert("Niet gelukt");
    }
  };

  handleWebshopInput = () => {
    if (!this.state.webshopName) return alert("Fucker, this is too short");
    if (this.state.webshopName.length <= 3)
      return alert("Fucker, this is too short");
    this.findOrCreateWebshop();
  };
  handleUniqueIdentifier = () => {
    if (!this.state.uniqueIdentifier) return alert("Fucker, this is too short");
    if (this.state.uniqueIdentifier.length <= 3)
      return alert("Fucker, this is too short");
    this.findOrCreateUniqueIdentifier();
  };
  findOrCreateUniqueIdentifier = async () => {
    if (!this.state.uniqueIdentifier) return alert("Fucker, this is too short");
    if (this.state.uniqueIdentifier.length <= 3)
      return alert("Fucker, this is too short");

    const data = {
      identifier: this.state.uniqueIdentifier,
      type: "text_string",
      webshopId: this.state.webshopId,
      // hier invoice en userId meesturen
    };

    try {
      await axios.post(apiEndpoint.createUniqueIdentifier, data).then((res) => {
        // await axios.post(apiEndpoint.findOrCreateWebshop, data).then((res) => {

        console.log("het is gelukt");
        // this.setState(
        //   { webshop: res.data, webshopId: res.data.webshop.id },
        //   () => this.setWebshopId()
        // );
      });
    } catch (error) {
      alert("Niet gelukt");
    }
  };

  // deze functie is dus een find or create dat webshopId en webshopName returns
  findOrCreateWebshop = async () => {
    if (!this.state.webshopName) return alert("Fucker, this is too short");
    if (this.state.webshopName.length <= 3)
      return alert("Fucker, this is too short");

    const data = {
      webshopName: this.state.webshopName,
      // hier invoice en userId meesturen
    };

    try {
      await axios.post(apiEndpoint.findOrCreateWebshop, data).then((res) => {
        // await axios.post(apiEndpoint.findOrCreateWebshop, data).then((res) => {
        this.setState(
          { webshop: res.data, webshopId: res.data.webshop.id },
          () => this.setWebshopId(res.data.webshop.id)
        );
      });
    } catch (error) {
      alert("Niet gelukt");
    }
  };
  findOrCreateWebshopStartDataExtraction = async () => {
    const data = {
      webshopName: this.state.webshopName,
      userId: this.state.userId,
      // hier invoice en userId meesturen
    };
    try {
      await axios
        .post(apiEndpoint.findOrCreateWebshopStartDataExtraction, data)
        .then((res) => {
          // await axios.post(apiEndpoint.findOrCreateWebshop, data).then((res) => {
          this.setState(
            { webshop: res.data, webshopId: res.data.webshop.id },
            () => this.updateSteffie()
          );
        });
    } catch (error) {
      alert("Niet gelukt");
    }
  };

  handleEditLineItemModal = () => {
    const editLineItemModal = this.state.editLineItemModal;
    this.setState({ editLineItemModal: !editLineItemModal });
  };

  handleInvoiceTooOld = async () => {
    if (!this.state.webshopName) return alert("Fucker, this is too short");
    if (this.state.webshopName.length <= 3)
      return alert("Fucker, this is too short");

    let data = {
      queueId: 14,
      userId: this.state.userId,
    };
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .put(apiEndpoint.invoiceTooOld + steffieId, data)
        .then((res) => {
          this.props.navigation.navigate("SteffiesToScrape");
          // alert("gelukt");
        });
    } catch (err) {
      alert("jammer joh");
    }

    //Invoice_to_old, id:14. <=invoiceQueue
    // in het begin gewoon de facturen afkeuren

    const invoiceToOld = this.state.invoiceToOld;
    this.setState({ invoiceToOld: !invoiceToOld });
  };

  handleInvoiceIsNoInvoice = async () => {
    if (!this.state.webshopName) return alert("Fucker, this is too short");
    if (this.state.webshopName.length <= 3)
      return alert("Fucker, this is too short");

    let data = {
      queueId: 14,
      userId: this.state.userId,
    };
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios.put(apiEndpoint.noInvoice + steffieId, data).then((res) => {
        this.props.navigation.navigate("SteffiesToScrape");
        // alert("gelukt");
      });
    } catch (err) {
      alert("jammer joh");
    }
  };
  handleInvoiceTooBlurry = async () => {
    if (!this.state.webshopName) return alert("Fucker, this is too short");
    if (this.state.webshopName.length <= 3)
      return alert("Fucker, this is too short");

    let data = {
      queueId: 14,
      userId: this.state.userId,
    };
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios.put(apiEndpoint.notReadable + steffieId, data).then((res) => {
        this.props.navigation.navigate("SteffiesToScrape");
        // alert("gelukt");
      });
    } catch (err) {
      alert("jammer joh");
    }
  };
  //afwijs bericht
  //afwijs bericht

  // invoiceToOld "invoice_to_old" id:14. <=invoiceQueue
  // [ ] invoice (tijdelijk) afkeuren
  // [ ] Dus bericht naar gebruiker
  // [ ] SteffieId statusId (invoiceQueueId: 14 ) geven
  // [ ]

  // Gaat terug naar de lijst
  // createOr
  // activeerd textract_complete
  handleMoreThenOneLineItem = async () => {
    if (!this.state.webshopName) return alert("Fucker, this is too short");
    if (this.state.webshopName.length <= 3)
      return alert("Fucker, this is too short");
    const steffieId = this.props.navigation.getParam("item").id;

    let data = {
      queueId: 14,
      steffieId: steffieId,
      userId: this.state.userId,
      file: this.state.dataSource,
      webshopId: this.state.webshopId,
      numberOfPages: this.state.numPages, //<== deze moet op de backend dus uitmonden in een andere functie (met jobId)
    };

    //  return alert(JSON.stringify(data))

    try {
      await axios.post(apiEndpoint.multipleLineItems, data).then((res) => {
        // await axios.post(apiEndpoint.multipleLineItems, data).then((res) => {
        this.props.navigation.navigate("SteffiesToScrape");
        // alert("gelukt");
      });
    } catch (err) {
      alert(err);
    }
  };

  // activeerd textract_form_only
  // opent een nieuw tablet met de factuur (zodat product gescraped kan worden)
  // toont het lineItem invoer scherm
  justOneLineItem = () => {
    if (!this.state.webshopName) return alert("Fucker, this is too short");
    if (this.state.webshopName.length <= 3)
      return alert("Fucker, this is too short");

    this.findOrCreateWebshop();
    this.setState({
      oneLineItem: true,
      lineItemsCheckDone: true,
    });

    this.openInvoiceInNewTab();
  };

  openInvoiceInNewTab = async (item) => {
    const steffieId = this.state.steffieId;
    axios({
      url: apiEndpoint.getSteffiePdfDownload + steffieId, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      //    const link = document.createElement('a');
      //    link.href = url;
      //    link.setAttribute('download', 'file.pdf'); //or any other extension
      //    document.body.appendChild(link);
      //  //
      //    link.click();
      window.open(url);
    });
  };

  // if invoiceToOld
  handleDone = () => {
    this.addNewLineItem("Next");
  };

  render() {
    const { numPages } = this.state;

    if (this.state.isLoading === true)
      return (
        <View
          style={{
            backgroundColor: colors.background,
            width: Dimensions.get("screen").width,
            height: Dimensions.get("screen").height,
            position: "absolute",
          }}
        />
      );

    return (
      <View
        style={{
          backgroundColor: colors.background,
          width: Dimensions.get("screen").width,
          height: Dimensions.get("screen").height,
          //  position: "absolute",
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            //  left:30
          }}
          onPress={() => this.props.navigation.navigate("SteffiesToScrape")}
        >
          <ChevronLeftIcon
            style={{
              color: colors.text,
              fontSize: 50,
            }}
          />

          <View style={{ right: 10 }}>
            <Text
              style={{
                alignSelf: "center",
                color: colors.text,
                fontSize: 20,
              }}
            >
              Terug
            </Text>
            <Text
              style={{ color: colors.text, alignSelf: "center", fontSize: 10 }}
            >
              ctrl + b
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            //  flexDirection:'row',
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            left: 130,
            top: 10,
          }}
          onPress={() => this.rejectSteffie()}
        >
          <ThumbDownIcon
            style={{
              color: colors.red,
              fontSize: 30,
            }}
          />

          {/* <View style={{right:10}}>

                <Text style={{
                    alignSelf:'center',
                    color:colors.text, fontSize:20}}>
                    Reject
                </Text>
               
                    </View> */}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            //  flexDirection:'row',
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            left: 190,
            top: 7,
          }}
          onPress={() =>
            this.props.navigation.navigate(
              "PDF",

              { item: this.props.navigation.getParam("item") }
            )
          }
        >
          <MoreVertIcon
            style={{
              color: colors.text,
              fontSize: 40,
            }}
          />
        </TouchableOpacity>

        <Text style={{ color: colors.red }}>
          webshopId: {this.state.webshopId}
        </Text>

        <View
          style={{
            flexDirection: "row",
            position: "absolute",
            //  top:' 50%',
            //   left: '50%',
            width: 595,
            height: 841,
            //  color: 'transparent',
            //  transform: translate(-'50%' ,-'50%') rotate(0deg);
            // pointer-events: none;
          }}
        >
          <Document
            //     style={{ position: "absolute" }}
            // file={samplePDF}
            // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
            file={`data:application/pdf;base64,${this.state.dataSource}`}
            onLoadSuccess={this._removeTextLayerOffset}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>

          <View style={{ top: 60, left: 10 }}>
            <TextInputComponent
              autoFocus={true}
              // onBlur={() => this.updateSteffie()}
              onBlur={() =>
                this.state.webshopName === "" ? null : this.handleWebshopInput()
              }
              label={"Webshop"}
              // value={this.state.webshop ? this.state.webshop.name : null}
              value={this.state.webshopName}
              onChangeText={(text) => this.onChangeText(text, "webshopName")}
            />

            <TextInputComponent
              //autoFocus={true}
              // onBlur={() => this.updateSteffie()}
              onBlur={() =>
                this.state.uniqueIdentifier === ""
                  ? null
                  : this.handleUniqueIdentifier()
              }
              label={"Unique Identifier"}
              // value={this.state.webshop ? this.state.webshop.name : null}
              value={this.state.uniqueIdentifier}
              onChangeText={(text) =>
                this.onChangeText(text, "uniqueIdentifier")
              }
            />

            {this.state.webshopId &&
            this.state.lineItemsCheckDone === true ? null : (
              <View>
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => this.justOneLineItem()}
                    style={{
                      //top: 50,
                      //    left: 330,
                      alignSelf: "center",
                      borderRadius: 6,
                      borderColor: colors.green,
                      borderWidth: this.state.invoiceToOld ? 3 : 1,
                      marginHorizontal: 10,
                      bottom: 40,
                      // alignSelf:"center",
                      backgroundColor: colors.component,
                      width: 80,
                      height: 40,
                      //marginTop: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: colors.text,
                        fontWeight: this.state.invoiceToOld ? "bold" : "normal",
                      }}
                    >
                      Ok
                    </Text>
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <View style={{ justifyContent: "center" }}>
                    <SnoozeIcon
                      onClick={() => this.handleInvoiceTooOld()}
                      style={{
                        alignSelf: "center",
                        color: this.state.invoiceToOld
                          ? colors.green
                          : colors.text,
                        fontSize: this.state.invoiceToOld ? 40 : 30,
                      }}
                    />
                    <Text style={{ color: colors.text }}>To Old</Text>
                  </View>
                  <View style={{ justifyContent: "center" }}>
                    <VisibilityOffIcon
                      onClick={() => this.handleInvoiceTooBlurry()}
                      style={{
                        color: colors.yellow,

                        alignSelf: "center",
                        fontSize: 30,
                      }}
                    />
                    <Text style={{ color: colors.yellow }}>To Blurry</Text>
                  </View>

                  <View style={{ justifyContent: "center" }}>
                    <ThumbDownIcon
                      onClick={() => this.handleInvoiceIsNoInvoice()}
                      style={{
                        color: colors.red,

                        alignSelf: "center",
                        fontSize: 30,
                      }}
                    />
                    <Text style={{ color: colors.red }}>No Invoice</Text>
                  </View>
                </View>
                <View style={{ justifyContent: "center", marginVertical: 20 }}>
                  <Text
                    style={{
                      alignSelf: "center",
                      fontSize: 20,
                      color: colors.red,
                    }}
                  >
                    {moment(new Date())
                      .subtract(40, "days")
                      .format("DD MMMM YYYY")}
                  </Text>
                  <Text
                    style={{
                      color: colors.text,
                      alignSelf: "center",
                      fontSize: 12,
                    }}
                  >
                    Invoice Date should be before:
                  </Text>
                  <Text
                    style={{
                      alignSelf: "center",
                      fontSize: 40,
                      color: colors.red,
                    }}
                  >
                    {moment(new Date()).subtract(40, "days").format("MMMM")} /{" "}
                    <Text>
                      {moment(new Date()).subtract(40, "days").format("MM")}
                    </Text>
                  </Text>

                  <View
                    style={{
                      borderWidth: 0.5,
                      width: 60,
                      height: 70,
                      justifyContent: "center",
                      marginTop: 10,
                      borderRadius: 4,
                      backgroundColor: colors.subComponent,
                      alignItems: "center",
                      alignSelf: "center",
                      borderColor: colors.text,
                    }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 40,

                        fontWeight: "bold",
                        color: colors.text,
                      }}
                    >
                      {moment(new Date()).subtract(40, "days").format("D")}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            {this.state.webshopId && this.state.lineItemsCheckDone === true ? (
              <View>
                <View style={{ flexDirection: "row" }}>oneLineItem</View>

                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      width: 5,
                      alignSelf: "center",
                      bottom: 40,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></View>
                </View>

                <View style={{ minHeight: 70, flexDirection: "row", top: 20 }}>
                  <TextFieldComponent
                    onBlur={() => console.log("jpo")}
                    label={"productName"}
                    value={this.state.productName}
                    onChangeText={(text) =>
                      this.onChangeText(text, "productName")
                    }
                  />
                  <View style={{ left: 10 }}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ height: 40 }}>
                        <PriceInputComponent
                          onBlur={() => console.log("jpo")}
                          label={"pricePaid"}
                          value={this.state.pricePaid}
                          onChangeText={(text) =>
                            this.onChangeText(text, "pricePaid")
                          }
                        />
                      </View>
                      <TouchableOpacity
                        onPress={() => this.handleDone()}
                        style={{
                          //top: 50,
                          //    left: 330,
                          //  alignSelf: "center",
                          borderRadius: 6,
                          borderColor: colors.green,
                          borderWidth: 1,
                          //  marginHorizontal: 10,
                          // alignSelf:"center",
                          backgroundColor: colors.component,
                          width: 20,
                          height: 70,
                          //
                          left: 10,
                          bottom: 40,

                          alignSelf: "center",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ color: colors.text }}>D</Text>
                        <Text style={{ color: colors.text }}>O</Text>
                        <Text style={{ color: colors.text }}>N</Text>
                        <Text style={{ color: colors.text }}>E</Text>
                      </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                      onPress={() => this.addNewLineItem("duplicate")}
                      style={{
                        //top: 50,
                        //    left: 330,
                        //  alignSelf: "center",
                        borderRadius: 6,
                        borderColor: colors.green,
                        borderWidth: 1,
                        //  marginHorizontal: 10,
                        // alignSelf:"center",
                        backgroundColor: colors.component,
                        width: 70,
                        height: 30,
                        bottom: 40,
                        alignSelf: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ color: colors.text }}>Duplicate</Text>
                    </TouchableOpacity>
                  </View>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() =>
                      this.setState({ pricePaid: "", productName: "" })
                    }
                    style={{
                      //top: 50,
                      //    left: 330,
                      //  alignSelf: "center",
                      borderRadius: 6,
                      borderColor: colors.green,
                      borderWidth: 1,
                      //  marginHorizontal: 10,
                      // alignSelf:"center",
                      backgroundColor: colors.component,
                      width: 70,
                      height: 30,
                      // bottom: 40,
                      alignSelf: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ color: colors.text }}>Clear</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => this.addNewLineItem("next")}
                    style={{
                      //top: 50,
                      //    left: 330,
                      alignSelf: "center",
                      borderRadius: 6,
                      borderColor: colors.green,
                      borderWidth: 1,
                      marginHorizontal: 10,
                      // alignSelf:"center",
                      backgroundColor: colors.component,
                      width: 160,
                      height: 40,
                      //marginTop: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ color: colors.text }}>Next lineItem</Text>
                  </TouchableOpacity>
                </View>

                {this.state.lineItems.length >= 1
                  ? this.state.lineItems.map((item) => (
                      <TouchableOpacity
                        onPress={() => {
                          this.handleEditLineItemModal();
                          this.setState({ selectedLineItem: item });
                        }}
                        style={{
                          justifyContent: "space-between",
                          flexDirection: "row",
                          //  flex: 1,
                          marginVertical: 4,
                          marginHorizontal: 5,
                          width: 300,
                        }}
                      >
                        <Text style={{ color: colors.yellow, width: 20 }}>
                          {item.id}
                        </Text>
                        {item.product.name ? (
                          <Text
                            numberOfLines={1}
                            style={{ color: colors.green, width: 250 }}
                          >
                            {item.product.name.length < 31
                              ? `${item.product.name}`
                              : `${item.product.name.substring(0, 28)}...`}
                          </Text>
                        ) : null}
                        <Text
                          style={{
                            color: colors.red,
                            //  alignSelf: "flex-end",
                            width: 100,
                          }}
                        >
                          € {item.pricePaid}
                        </Text>
                      </TouchableOpacity>
                    ))
                  : null}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ color: colors.text }}>
                    Totaal{" "}
                    <Text style={{ color: colors.green }}> € 22200.00</Text>
                  </Text>
                  <Text style={{ color: colors.text, alignSelf: "flex-end" }}>
                    Totaal <Text style={{ color: colors.red }}>€ 22200.00</Text>
                  </Text>
                </View>
              </View>
            ) : null}
          </View>

          <Text>{JSON.stringify(this.state.lineItems, null, 4)}</Text>
        </View>

        <EditLineItemModal
          deleteLineItem={() => this.deleteLineItem()}
          handleEditLineItemModal={() => this.handleEditLineItemModal()}
          selected={this.state.selectedLineItem}
          editLineItemModal={this.state.editLineItemModal}
        />
      </View>
    );
  }
}
