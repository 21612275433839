import React, { Component } from "react";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Linking
} from "react-native";
import axios from "axios";
import moment from 'moment'

import ReactDataGrid from 'react-data-grid';

import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import TableComponent from "../components/TableComponent";

import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
// import { Data } from "react-data-grid-addons";


  
  const rows = [{id: 0, title: 'row1', count: 20}, {id: 1, title: 'row1', count: 40}, {id: 2, title: 'row1', count: 60}];
  
//   function HelloWorld() {
//     return (<ReactDataGrid
//     columns={columns}
//     rowGetter={i => rows[i]}
//     rowsCount={3}
//     minHeight={150} />);
//   }

export default class Scrape_errorScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      dataSource:[],
      invoiceQue: [],
      isLoading: true,
      dataSourceComparer:[],
      selectedIndexes:[]
    };
  }

  componentDidMount() {
    this._getDataSource();
  }

  _getDataSource = async () => {
    try {
      await axios
        .get(apiEndpoint.getScrape_error)
        .then(res => this.setState({ dataSource: res.data,dataSourceComparer:res.data, isLoading: false }));
    } catch (err) {
      alert('Niet geluk: ', err)
    }
  };

 

  // localhost:3000/invoices/admin/orders/processing

  handleNavigation = async (route, item) => {

    this.props.navigation.navigate(route, {
      item: item,

    });
  };

 
  handleOnClick = row => {
 //Linking.openURL('http://localhost:3000/invoices/invoiceAsPdf/1')
    
    //this.getDownload(row.id, row.userId)
    this.props.navigation.navigate("Steffie", { item: row });
    console.log('userId: ' , row.userId)
    console.log('row: ' , row)
  //  alert('row: ' , row)


  };

  onRowClick = (index, item) => {
      alert(JSON.stringify(index))
  }

     handleGridSort =  (sortColumn, sortDirection) => {
    var comparer = function(a, b) {
      if(sortDirection === 'ASC'){
        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
      }else if(sortDirection === 'DESC'){
        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
      }
    }
    // var rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);
    var rows = sortDirection === 'NONE' ? this.state.dataSource.slice(0) : this.state.dataSource.sort(comparer);
    this.setState({rows : rows});
  }

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map(r => r.rowIdx)
      )
    });
  };

  onRowDoubleClick = (index,item, attribute) => {
    this.props.navigation.navigate('_SteffieEdit', {
      item:item,
      attribute:attribute
    })
  }

  render() {

    const ErrorComponent = ( {text} ) => {
      // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
    return <div style={{
      borderRadius:3,
      width:120,
      justifyContent:'center',
      alignItems:'center',
      display:"flex",
      backgroundColor:colors.red}}> <a style={{
   
      color:colors.white, 
     // fontWeight:'bold'
    }}>{text}</a> </div>;
        };


    // const openFormatter = ({ value }) => {
    // // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
    // return <View style={{flexDirection:'row'}} > <Text style={{ color:colors.blue, fontWeight:'bold', position:'absolute', right:40}}>open</Text> {value}</View>;
    //   };
    const webshopFormatter = ({ value }) => {
    // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
    return <View >{value ? <Text style={{color:colors.green,}}>{value.name}</Text>:<ErrorComponent text={'Webshop'}/>} </View>;
      };
    const orderNumberFormatter = ({ value }) => {
    return <View  >{value ? <Text style={{color:colors.green,}}>{value.orderNumber }</Text> :<ErrorComponent text={'orderNumber'}/>} </View>;
      };
    const orderDateFormatter = ({ value }) => {
    return <View  >{value ? <Text style={{color:colors.green,}}>{value.orderDate }</Text> :<ErrorComponent text={'orderDate'}/>} </View>;
      };
    const invoiceDateFormatter = ({ value }) => {
    return <View  >{value ? <Text style={{color:colors.green,}}>{value.invoiceDate }</Text> :<ErrorComponent text={'invoiceDate'}/>} </View>;
      };
    const createdAtFormatter = ({ value }) => {
    return <View  ><Text>{moment(value).format(`HH:mm DD MMMM 'YY`)}</Text> </View>;
      };
    const lineItemFormatter = ({ value }) => {
    return <View >{value.length ===0 ? <ErrorComponent text={'0'}/>  : value.length}</View>;
      };
    const queueFormatter = ({ value }) => {
    return <View  >{value.status}</View>;
      };

    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background
          }}
        >
          <ActivityIndicator />
        </View>
      );
    } else {
      return (
        <View
          style={{
            backgroundColor: colors.background,
            height: Dimensions.get("screen").height,
            width: Dimensions.get("screen").width,
            position: "absolute"
          }}
        >

          <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Scrape_errorScreen " + this.state.dataSource.length}
          />
          {/* {HelloWorld()} */}



     <ReactDataGrid

     columns={ [
      {
        resizable: true, 
        width: 80,
        key: 'id', name: 'steffieId' , sortable : true, },
      { key: 'createdAt', name: 'upload date', width: 140,formatter:createdAtFormatter },
    
     { key: 'webshop', name: 'webshop',
     width: 160,
     formatter:webshopFormatter },
     { key: 'orderNumber', name: 'orderNumber',
     width: 160,
     formatter:orderNumberFormatter },
     { key: 'invoiceNumber',     width: 160, name: 'invoiceNumber',formatter:orderNumberFormatter },
     { key: 'invoiceDate',     width: 160, name: 'invoiceDate',formatter:invoiceDateFormatter },
     { key: 'orderDate',     width: 160, name: 'orderDate',formatter:orderDateFormatter },
     { key: 'lineItems',  width: 170, name: 'Number of lineItems', formatter:lineItemFormatter },
     { key: 'queue', name: 'queueId',width:80, formatter:queueFormatter },
     { key: 'userId', name: 'userId',width: 60, },
    //  { key: 'queueId', name: 'queueId' },
    ]}
    onGridSort={this.handleGridSort}
     //  rowGetter={i => rows[i]}
     rowGetter={i => this.state.dataSource[i]}
     rowsCount={this.state.dataSource.length}
    // rowsCount={20}
    //  width={200}
     minHeight={740}
     onRowDoubleClick={(index,item, attribute)=> this.onRowDoubleClick(index,item, attribute)}

     />

        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    // width: "100%",
    height: Dimensions.get("window").height,
    // headers:'100%',
    backgroundColor: colors.background
  }
});


