import React, { Component } from "react";

import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from "react-native";

import axios from 'axios'
import moment from 'moment'
import ReactDataGrid from "react-data-grid";
import colors from "../../../styles/colors";
import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";



export default class PricesScreen extends React.Component {


    constructor(props){
        super(props)
        this.state = {
            dataSource: []
        }
    }

    componentDidMount(){
        this.getPrices()
    }

    getPrices = async () =>{
        await axios.get(apiEndpoint.getPrices).then((res)=> this.setState({dataSource: res.data}))
    }

    onRowDoubleClick = (index, item, attribute) => {
        alert(JSON.stringify(item))
    }

    handleGridSort = (sortColumn, sortDirection) => {
        var comparer = function (a, b) {
          if (sortDirection === "ASC") {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else if (sortDirection === "DESC") {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
        };
        // var rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);
        var rows =
          sortDirection === "NONE"
            ? this.state.dataSource.slice(0)
            : this.state.dataSource.sort(comparer);
        this.setState({ rows: rows });
      };

    render(){


    const ErrorComponent = ({ text }) => {
        // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
        return (
          <div
            style={{
              borderRadius: 3,
              width: 120,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              backgroundColor: colors.red,
            }}
          >
            
            <a
              style={{
                color: colors.white,
                // fontWeight:'bold'
              }}
            >
              {text}
            </a>
          </div>
        );
      };
  
      // const openFormatter = ({ value }) => {
      // // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
      // return <View style={{flexDirection:'row'}} > <Text style={{ color:colors.blue, fontWeight:'bold', position:'absolute', right:40}}>open</Text> {value}</View>;
      //   };
      const webshopFormatter = ({ value }) => {
        // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
        return (
          <View>
            {value.webshop ? (
              <Text style={{ color: colors.green }}>{value.webshop.name}</Text>
            ) : (
              <ErrorComponent text={"Geen Webshop"} />
            //   <Text>{JSON.stringify(value)}</Text>
            )}
          </View>
        );
      };
      const orderNumberFormatter = ({ value }) => {
        return (
          <View>
            {value ? (
              <Text style={{ color: colors.green }}>{value}</Text>
            ) : (
              <ErrorComponent text={"orderNumber"} />
            )}
          </View>
        );
      };
      const orderDateFormatter = ({ value }) => {
        return (
          <View>
            {value ? (
              <Text style={{ color: colors.green }}>
                {moment(value).format("DD MMMM YY")}
              </Text>
            ) : (
              <ErrorComponent text={"orderDate"} />
            )}
          </View>
        );
      };
      const createdAtFormatter = ({ value }) => {
        return (
          <View>
            {value ? (
              <Text style={{ color: colors.green }}>
                {moment(value).format("DD-MM-YY HH:mm")}
              </Text>
            ) : (
              <ErrorComponent text={"invoiceDate"} />
            )}
          </View>
        );
      };
      const priceFormatter = ({ value }) => {
        return (
          <View style={{flexDirection:'row'}}> 
          {value.map((item, index) => 
          
            <Text style={{marginLeft:4, color: index % 2 == 0 ? colors.blue :  colors.orange}}>{item.price}</Text>)
          }
          </View>
        );
      };
      const lineItemFormatter = ({ value }) => {
        return (
          <View>
            {value.length === 0 ? <ErrorComponent text={"0"} /> : value.length}
          </View>
        );
      };
      const productFormatter = ({ value }) => {
        return (
          <View>
            {value ? value.name :  <ErrorComponent text={'Geen productname'} /> }
          </View>
        );
      };
      const queueFormatter = ({ value }) => {
        return <View>{value.status}</View>;
      };
        return(
            <View style={{flex:1, backgroundColor:colors.background}}>
                  <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Prices"}
          />

{/* <ScrollView>

        <Text>fsadf{JSON.stringify(this.state.dataSource, null, 4)}</Text>
</ScrollView> */}
<ReactDataGrid
            columns={[
              {
                resizable: true,
                width: 80,
                key: "id",
                name: "id",
                sortable: true,
              },
              {
                resizable: true,
                key: "createdAt",
                name: "createdAt",
                width: 140,
                formatter: createdAtFormatter,
                sortable: true,
              },
              {
                resizable: true,
                width: 80,
                key: "price",
                name: "prices",
                sortable: true,
              },
             
              {
                resizable: true,
                width: 80,
                key: "productId",
                name: "productId",
                sortable: true,
              },
              
            

              {
                key: "product",
                name: "product",
                width: 500,
                formatter: productFormatter,
                sortable: true,
              },
              {
                key: "product",
                name: "webshop",
                width: 160,
                formatter: webshopFormatter,
                sortable: true,
              },
             
              // {
              //   key: "invoiceNumber",
              //   width: 160,
              //   name: "invoiceNumber",
              //   formatter: orderNumberFormatter,
              //   sortable: true,
              // },
              // {
              //   key: "steffie",
              //   width: 160,
              //   name: "invoiceDate",
              //   formatter: invoiceDateFormatter,
              //   sortable: true,
              // },
              // {
              //   key: "orderDate",
              //   width: 160,
              //   name: "orderDate",
              //   formatter: orderDateFormatter,
              //   sortable: true,
              // },
              // {
              //   key: "lineItems",
              //   width: 170,
              //   name: "Number of lineItems",
              //   formatter: lineItemFormatter,
              //   sortable: true,
              // },
              // {
              //   key: "queueId",
              //   name: "queueId",
              //   width: 80,
              //   sortable: true,
              // },
              // { key: "userId", name: "userId", width: 60,   sortable: true, },
              //   {
              //     key: "user",
              //     name: "email",
              //     width: 290,
              //     formatter: emailformatter,
              //     sortable: true,
              //   },
              
              //  { key: 'queueId', name: 'queueId' },
            ]}
            onGridSort={this.handleGridSort}
            //  rowGetter={i => rows[i]}
            rowGetter={(i) => this.state.dataSource[i]}
            rowsCount={this.state.dataSource.length}
            // contextMenu={}
            // rowsCount={20}
            //  width={200}
            minHeight={740}
            className="fill-grid"
            // RowRenderer={RowRenderer}
            onRowDoubleClick={(index, item, attribute) =>
              this.onRowDoubleClick(index, item, attribute)
            }
          />

                </View>
        )
    }
}