import React from "react";
import { View, Text, TextInput, StyleSheet, Dimensions, TouchableOpacity } from "react-native";
import colors from "../../../styles/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import SearchProductComponent from "../../../globalComponents/search/SearchProductComponent";
import AddBoxIcon from '@material-ui/icons/AddBox';

export default class CreateLineItemComponent extends React.Component {
  render() {
    const label = this.props.label;
    const value = this.props.value;
    const price = this.props.price;
    const url = this.props.url;
    const productId = this.props.productId;
    const placeholder = this.props.placeholder;
    return (

        <View>
      <View style={{ flexDirection: "row", marginVertical: 10, marginLeft:10 }}>
        {/* <View style={styles.textInputContainer}> */}
          <Text style={styles.text}>Product</Text> 
          <Text
            style={{
              position: "absolute",
                fontWeight: "bold",
              bottom: 42,
              color: colors.green,
              fontSize: 14,
              left: 70
            }}
          >
          
            {productId}
          </Text>
         <SearchProductComponent
          arr={this.props.arr}
          handleSubmit={value => this.props.handleSearchSubmit(value)}
          handleSelected={value => this.props.handleSelected(value)}
         />
        {/* </View> */}
        <View style={styles.textInputContainerPrice}>
          <Text style={styles.text}>Price</Text>
          <Text
            style={{
              position: "absolute",
              //  fontWeight: "bold",
              bottom: 17,
              color: colors.text,
              fontSize: 14,
              left: 10
            }}
          >
            €
          </Text>
          <TextInput
            type={"number"}
            autoFocus={this.props.autoFocus}
         //   onBlur={() => this.props.onBlur()}
            placeholderTextColor={colors.text}
            placeholder={"price"}
            onChangeText={text => this.props.onChangeTextPrice(text)}
            value={price}
            style={[styles.textInput]}
          />
        </View>
<View style={{flexDirection:'row'}}>


        {/* <TouchableOpacity
        onPress={()=> this.props.removeRow()}
        
        style={{alignSelf:'center'}}
        >

        <RemoveIcon

            style={{
                alignSelf: "center",
                color: colors.text,
                fontSize: 40,
                bottom: 40
                
                //    position:'absolute',
                //    bottom:20,
                //   right:20,
                //   top:20
                //   top:50
            }}
            />
            </TouchableOpacity> 
           */}
         <TouchableOpacity
        onPress={()=> this.props.addNewRow()}
        
        style={{alignSelf:'center',flexDirection:'row'}}
        >

        <AddBoxIcon

            style={{
                alignSelf: "center",
                color: colors.text,
                fontSize: 40,
                bottom: 40
                
                //    position:'absolute',
                //    bottom:20,
                //   right:20,
                //   top:20
                //   top:50
            }}
            />
            </TouchableOpacity> 
       
            </View>
     

        

       
       
      </View>
    
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    // marginLeft: 10,
    //   width: Dimensions.get("window").width - 120,
    alignSelf: "center",
    color: colors.green,
    paddingLeft: 30,
    zIndex: 1,
    width: 100
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center"
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: colors.white,
    marginHorizontal: 10,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    // bottom: 40,
    width: 350,
    //alignSelf:'center',

    backgroundColor: colors.component
  },
  textInputContainerPrice: {
    flexDirection: "row",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: colors.white,
    marginHorizontal: 10,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    // bottom: 40,
    width: 100,
    //alignSelf:'center',

    backgroundColor: colors.component
  },
  text: {
    position: "absolute",
    //  fontWeight: "bold",
    bottom: 60,
    color: colors.text,
    fontSize: 14,
    left: 5
  }
});
