import React from "react";
import { Text, Image, View, TouchableOpacity } from "react-native";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import colors from "../../../styles/colors";
import SendIcon from "@material-ui/icons/Send";
import TrafficIcon from '@material-ui/icons/Traffic';

export default class Monitor_ProductInvoicesTableComponent extends React.Component {
  render() {
    return (
      <TableContainer
        style={{ backgroundColor: colors.component }}
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: colors.component }}>
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  User id
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  orderNumber
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  invoiceNumber
                </Text>
              </TableCell>

              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  Product Name
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  PricePaid
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                    Amount lineItems
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  Current price
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  Price paid
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  Refunds
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.arr.map((row, index) => (
              // <TableRow key={row.name}>
              <TableRow
                // onClick={() => this.props.onClick(row, index)}
                key={row.id}
              >
                <TableCell
                  style={{ color: colors.text, flexDirection: "row" }}
                  component="th"
                  scope="row"
                >
                  <Text style={{ color: colors.yellow }}>{row.id}</Text>

                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onPress={() => this.props.onClick(row, index, "url")}
                    >
                      <TrafficIcon style={{ color: colors.yellow }} />
                    </TouchableOpacity>
                  </View>
                </TableCell>
                <TableCell
                  style={{ color: colors.text, flexDirection: "row" }}
                  component="th"
                  scope="row"
                >
                  <View style={{ flexDirection: "row" }}>
                    <Text style={{ color: colors.text }}>
                {row.orderNumber}
                    </Text>
                  </View>
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  <Text style={{ color: colors.text }}>{row.invoiceNumber}</Text>
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {/* <Image
                      style={{
                        alignSelf:'center',
                        left:10,
                        width: 30, height: 30, marginHorizontal: 4 }}
                      source={row.webshop.image}
                    /> */}
                  <Text style={{ color: colors.text }}>{row.lineItems[0].product.name}</Text>
                </TableCell>

                <TableCell style={{ color: colors.text }} align="left">
                <Text style={{ color: colors.text }}>{row.lineItems[0].pricePaid}</Text>
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                <Text style={{ color: colors.text }}>{row.lineItems.length}</Text>
                </TableCell>

                <TableCell>
                  <View style={{ flexDirection: "row" }}>
                    {row.user.devices.map(item => (
                      <View
                        style={{
                          height: 40,
                          borderLeftWidth: 1,
                          borderLeftColor: colors.blue,
                          width: 120,
                          flexDirection: "row",
                          marginHorizontal: 2,
                          marginBottom: 5

                          //  justifyContent:'center',
                          // alignItems:'center'
                        }}
                      >
                        <Text
                          style={{
                            //  alignSelf:'center',
                            marginTop: 5,
                            marginLeft: 5,
                            color: colors.green
                          }}
                        >
                          {item.model}
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            color: colors.white,
                            position: "absolute",
                            bottom: 2,
                            left: 5
                          }}
                        >
                          {/* {moment(item.createdAt).format("YYYY-MM-DD")} */}
                          <Text
                            style={{
                              fontSize: 12,
                              color: colors.red,
                              //  position:'absolute',
                              //bottom:2,
                              left: 5
                            }}
                          >
                            {" "}
                            {/* {moment(item.createdAt).format("hh:mm:ss")} */}
                          </Text>
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            color: colors.blue,
                            position: "absolute",
                            //        bottom:20,
                            bottom: 40,
                            right: 5
                          }}
                        >
                          {/* {moment(item.createdAt).fromNow()} */}
                        </Text>
                      </View>
                    ))}
                  </View>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
