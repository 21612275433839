import React, { Component } from "react";
import {
  View,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import TableComponent from "../components/TableComponent";

import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

export default class Step_0_newSteffieScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      dataSource: [],
      invoiceQue: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this._getDataSource();
  }

  _getDataSource = async () => {
    try {
      await axios
        .get(apiEndpoint.getSteffiesToProcess)
        .then((res) =>
          this.setState({ dataSource: res.data, isLoading: false })
        );
    } catch (err) {
      alert("Niet gelukt", err);
    }
  };

  

  // localhost:3000/invoices/admin/orders/processing

  handleNavigation = async (route, item) => {
    this.props.navigation.navigate(route, {
      item: item,
      //   header:auth_header
    });

    console.log("Item: =>" + console.table(item));
  };

  handleOnClick = (row) => {

    this.props.navigation.navigate("SteffieScrape", { item: row });
    console.log("userId: ", row.userId);
    console.log("row: ", row);
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background,
          }}
        >
          <ActivityIndicator />
        </View>
      );
    } else {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: colors.background,
            height: Dimensions.get("window").height,
            width: Dimensions.get("window").width,
            // position: "absolute"
          }}
        >
          <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Step_0_newSteffieScreen"}
          />

          <View>
            <TableComponent
              onClick={(row) => this.handleOnClick(row)}
              arr={this.state.dataSource}
            />
          </View>
        </View>
      );
    }
  }
}
