import React from "react";
import { Text, Image, View } from "react-native";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import colors from "../../../styles/colors";
import moment from 'moment'

export default class TableScrapeComponent extends React.Component {
  render() {
    return (
      <TableContainer
        style={{ backgroundColor: colors.component }}
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: colors.component }}>
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  Product Image
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  Webshop
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  Product name
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  ProductNumber
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  warrantyType
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
              <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                Created At
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
              <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                Age
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.arr.map(row => (
              // <TableRow key={row.name}>
              <TableRow onClick={() => this.props.onClick(row)} key={row.id}>
                <TableCell
                  style={{ color: colors.text, flexDirection: "row" }}
                  component="th"
                  scope="row"
                >
                  <View style={{ flexDirection: "row" }}>
                    {/* <Image
                      style={{ width: 30, height: 30, marginHorizontal: 4 }}
                      source={row.image}
                    /> */}
                    <Image
                      style={{ width: 30, height: 30, marginHorizontal: 4 }}
                     source={row.webshop ? row.webshop.image : null}
                    />
                  </View>
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  <Text>{row.webshop ? row.webshop.name :'Geen webshop'}</Text>
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                  {row.productNumber}
                </TableCell>

                <TableCell style={{ color: colors.text }} align="left">
                  {row.warrantyType}
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                  {moment(row.createdAt).format('DD MMMM YYYY - HH:mm')} uur
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                  {moment(row.createdAt).fromNow()}
                </TableCell>
                {/* <TableCell 
                  style={{color:colors.text}}
              align="right">{JSON.stringify(row.webshop)}</TableCell> */}
                {/* <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
