import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";




// ########## ICONS 4 ##############


import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

import TextInputMultiComponent from "../../../globalComponents/input/TextInputMultiComponent";
import SendIcon from "@material-ui/icons/Send";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";

export default class MessageToUserScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: "",
      isLoading: true
    };
  }

  componentDidMount() {
    this._getDataSource();
  }

  onRefresh = () => {
    this._getDataSource();
  };

  handleLogOut = () => {
    localStorage.clear(this.props.navigation.navigate("Login"));
  };

  _getDataSource = async () => {


    await axios
      .get(apiEndpoint.getDashboard)
      .then(res => this.setState({ dataSource: res.data, isLoading: false }))
      .catch(err => {
        console.log("err: steffiescherm" + err);
        //   this._getDataSource()
      });
  };

  handleNavigation = (route) => {
    this.props.navigation.navigate(route
      );

   // console.log("Item: =>" + console.table(item));
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background
          }}
        >
          {/* <ActivityIndicator /> */}
        </View>
      );
    } else {
      return (
        <View
          style={{
            // flex:1,
            height: Dimensions.get("screen").height,
            //  flex:1,
            backgroundColor: colors.background,
          //  flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
               <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Message to User"}
          />


<View style={{marginTop:50}}/>
<View style={{ marginVertical: 25 , height:40 }}>
           <TextInputComponent
                value={this.state.username}
                label="Title of message"
                placeholder="Username"
              />
                  </View>
                  <View style={{ marginVertical: 0 , height:40 }}> 
          <TextInputMultiComponent
            label="Push notification message"
            placeholder="sdfsdf"
            value={this.state.message}
            onChangeText={text => this.onChangeText(text)}
            />
          
          

        </View>

        <TouchableOpacity
        onPress={()=> alert('hoi')}
        style={{borderWidth:1,
          borderRadius:6,
          marginLeft:20,
          justifyContent:'center',
          alignItems:'center',
          marginTop:140,borderColor:colors.text, width:200, height:40}}
        >
          <Text style={{color:colors.text}}>Send Message To User</Text>
        </TouchableOpacity>
        </View>
      );
    }
  }
}


