import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

// ########## ICONS 4 ##############


import BobsHeader from "../../../globalComponents/Headers/BobsHeader";


export default class DonationsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: "",
      isLoading: true
    };
  }

  componentDidMount() {
    this._getDataSource();
  }

  _getDataSource = async () => {
    await axios
      .get(apiEndpoint.getDonations)
      .then(res => this.setState({ dataSource: res.data, isLoading: false }))
      .catch(err => {
        console.log("err: steffiescherm" + err);
      });
  };

  handleNavigation = (route) => {
    this.props.navigation.navigate(route
      );
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background
          }}
        >
          {/* <ActivityIndicator /> */}
        </View>
      );
    } else {
      return (
        <View
          style={{
            // flex:1,
            height: Dimensions.get("screen").height,
            //  flex:1,
            backgroundColor: colors.background,
            flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
               <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Donations Screen"}
          />


<Text style={{color:colors.text}}>{JSON.stringify(this.state.dataSource, null, "\t")}</Text>

          

      
        </View>
      );
    }
  }
}


