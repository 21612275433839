import React, { Component } from "react";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Linking,
} from "react-native";
import axios from "axios";
import moment from "moment";

import ReactDataGrid from "react-data-grid";

import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import TableComponent from "../components/TableComponent";

import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import SteffieTableComponent from "../../../globalComponents/lists/SteffieTableComponent";
// import { Data } from "react-data-grid-addons";

const rows = [
  { id: 0, title: "row1", count: 20 },
  { id: 1, title: "row1", count: 40 },
  { id: 2, title: "row1", count: 60 },
];

//   function HelloWorld() {
//     return (<ReactDataGrid
//     columns={columns}
//     rowGetter={i => rows[i]}
//     rowsCount={3}
//     minHeight={150} />);
//   }

class UnknownSteffiesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      dataSource: [],
      invoiceQue: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this._getDataSource();
  }

  _getDataSource = async () => {
    try {
      await axios
        .get(apiEndpoint.unkown_steffies)
        .then((res) =>
          this.setState({ dataSource: res.data, isLoading: false })
        );
    } catch (err) {
      alert(err);
    }
  };

  // localhost:3000/invoices/admin/orders/processing

  handleNavigation = async (route, item) => {
    this.props.navigation.navigate(route, {
      item: item,
    });
  };

  handleOnClick = (row) => {
    //Linking.openURL('http://localhost:3000/invoices/invoiceAsPdf/1')
// alert('hoi')
    //this.getDownload(row.id, row.userId)
    this.props.navigation.navigate("UnkownSteffie", { item: row });
    // console.log("userId: ", row.userId);UnkownSteffie
    console.log("row: ", row);
    //  alert('row: ' , row)
  };

  // onRowClick = (index, item) => {
  //     alert(JSON.stringify(item))
  // }

  onRowDoubleClick = (index, item, attribute) => {
    this.props.navigation.navigate("UnkownSteffie", {
      item: item,
      attribute: attribute,
    });
  };

  onRowsSelected = (rows) => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map((r) => r.rowIdx)
      ),
    });
  };

  handleGridSort = (sortColumn, sortDirection) => {
    var comparer = function (a, b) {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };
    // var rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);
    var rows =
      sortDirection === "NONE"
        ? this.state.dataSource.slice(0)
        : this.state.dataSource.sort(comparer);
    this.setState({ rows: rows });
  };

  render() {
    const sortRows = (initialRows, sortColumn, sortDirection) => (rows) => {
      const comparer = (a, b) => {
        if (sortDirection === "ASC") {
          return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else if (sortDirection === "DESC") {
          return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
      };
      return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    const ErrorComponent = ({ text }) => {
      // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
      return (
        <div
          style={{
            borderRadius: 3,
            width: 120,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            backgroundColor: colors.red,
          }}
        >
          {" "}
          <a
            style={{
              color: colors.white,
              // fontWeight:'bold'
            }}
          >
            {text}
          </a>{" "}
        </div>
      );
    };

    // const openFormatter = ({ value }) => {
    // // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
    // return <View style={{flexDirection:'row'}} > <Text style={{ color:colors.blue, fontWeight:'bold', position:'absolute', right:40}}>open</Text> {value}</View>;
    //   };
    const webshopFormatter = ({ value }) => {
      // return <View now={value} ><Text>{value ? value.name : 'NO_WEBSHOP'}</Text> </View>;
      return (
        <View>
          {value ? (
            <Text style={{ color: colors.green }}>{value.name}</Text>
          ) : (
            <ErrorComponent text={"Webshop"} />
          )}{" "}
        </View>
      );
    };
    const orderNumberFormatter = ({ value }) => {
      return (
        <View>
          {value ? (
            <Text style={{ color: colors.green }}>{value}</Text>
          ) : (
            <ErrorComponent text={"orderNumber"} />
          )}{" "}
        </View>
      );
    };
    const orderDateFormatter = ({ value }) => {
      return (
        <View>
          {value ? (
            <Text style={{ color: colors.green }}>
              {moment(value).format("DD MMMM YY")}
            </Text>
          ) : (
            <ErrorComponent text={"orderDate"} />
          )}{" "}
        </View>
      );
    };
    const invoiceDateFormatter = ({ value }) => {
      return (
        <View>
          {value ? (
            <Text style={{ color: colors.green }}>
              {moment(value).format("DD MMMM YY")}
            </Text>
          ) : (
            <ErrorComponent text={"invoiceDate"} />
          )}{" "}
        </View>
      );
    };
    const createdAtFormatter = ({ value }) => {
      return (
        <View>
          <Text>{moment(value).format(`HH:mm DD MMMM 'YY`)}</Text>{" "}
        </View>
      );
    };
    const lineItemFormatter = ({ value }) => {
      return (
        <View>
          {value.length === 0 ? <ErrorComponent text={"0"} /> : value.length}
        </View>
      );
    };
    const emailformatter = ({ value }) => {
      return (
        <View>
          {value.email ? value.email :  <ErrorComponent text={'Geen email'} /> }
        </View>
      );
    };
    const queueFormatter = ({ value }) => {
      return <View>{value.status}</View>;
    };

    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background,
          }}
        >
          <ActivityIndicator />
        </View>
      );
    } else {
      return (
        <View
          style={{
            backgroundColor: colors.background,
            height: Dimensions.get("screen").height,
            width: Dimensions.get("screen").width,
            position: "absolute",
          }}
        >

        {/* <Text>{JSON.stringify(this.state.dataSource, null, 4)}</Text> */}
          <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"UnkownSteffies"}
          />
          {/* {HelloWorld()} */}

          {/* <ReactDataGrid
            columns={[
              {
                resizable: true,
                width: 80,
                key: "id",
                name: "steffieId",
                sortable: true,
              },
              {
                key: "createdAt",
                name: "upload date",
                width: 140,
                formatter: createdAtFormatter,
                sortable: true,
              },

              {
                key: "webshop",
                name: "webshop",
                width: 160,
                formatter: webshopFormatter,
                sortable: true,
              },
              {
                key: "orderNumber",
                name: "orderNumber",
                width: 160,
                formatter: orderNumberFormatter,
                sortable: true,
              },
              {
                key: "invoiceNumber",
                width: 160,
                name: "invoiceNumber",
                formatter: orderNumberFormatter,
                sortable: true,
              },
              {
                key: "invoiceDate",
                width: 160,
                name: "invoiceDate",
                formatter: invoiceDateFormatter,
                sortable: true,
              },
              {
                key: "orderDate",
                width: 160,
                name: "orderDate",
                formatter: orderDateFormatter,
                sortable: true,
              },
              {
                key: "lineItems",
                width: 170,
                name: "Number of lineItems",
                formatter: lineItemFormatter,
                sortable: true,
              },
              {
                key: "queueId",
                name: "queueId",
                width: 80,
                sortable: true,
              },
              { key: "userId", name: "userId", width: 60,   sortable: true, },
                {
                  key: "user",
                  name: "email",
                  width: 290,
                  formatter: emailformatter,
                  sortable: true,
                },
              
              //  { key: 'queueId', name: 'queueId' },
            ]}
            onGridSort={this.handleGridSort}
            //  rowGetter={i => rows[i]}
            rowGetter={(i) => this.state.dataSource[i]}
            rowsCount={this.state.dataSource.length}
            // rowsCount={20}
            //  width={200}
            minHeight={740}
            className="fill-grid"
            onRowDoubleClick={(index, item, attribute) =>
              this.onRowDoubleClick(index, item, attribute)
            }
          /> */}

          {/* <Text style={{color:colors.blue}}>{JSON.stringify(this.state.dataSource, null, 4)}</Text> */}

          <View>
          <SteffieTableComponent

columns={[
  {
    resizable: true,
    width: 80,
    key: "id",
    name: "steffieId",
    sortable: true,
  },
  {
    key: "createdAt",
    name: "upload date",
    width: 140,
    // formatter: createdAtFormatter,
    sortable: true,
  },

  {
    key: "webshop",
    name: "webshop",
    width: 160,
    // formatter: webshopFormatter,
    sortable: true,
  },
  {
    key: "orderNumber",
    name: "orderNumber",
    width: 160,
    // formatter: orderNumberFormatter,
    sortable: true,
  },
  {
    key: "invoiceNumber",
    width: 160,
    name: "invoiceNumber",
    // formatter: orderNumberFormatter,
    sortable: true,
  },
  {
    key: "invoiceDate",
    width: 160,
    name: "invoiceDate",
    // formatter: invoiceDateFormatter,
    sortable: true,
  },
  // {
  //   key: "orderDate",
  //   width: 160,
  //   name: "orderDate",
  //   formatter: orderDateFormatter,
  //   sortable: true,
  // },
  {
    key: "lineItems",
    width: 170,
    name: "LineItems",
    // formatter: lineItemFormatter,
    sortable: true,
  },
  {
    key: "queueId",
    name: "queueId",
    width: 80,
    sortable: true,
  },
  { key: "userId", name: "userId", width: 60,   sortable: true, },
    {
      key: "user",
      name: "email",
      width: 290,
      // formatter: emailformatter,
      sortable: true,
    },
  
  //  { key: 'queueId', name: 'queueId' },
]}

// onClick={(index, item, attribute) =>
  // this.onRowDoubleClick(index, item, attribute)
// }

// onClick={()=>}

onClick={row => this.handleOnClick(row)}
arr={this.state.dataSource}
/>

          </View>
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    // width: "100%",
    height: Dimensions.get("window").height,
    // headers:'100%',
    backgroundColor: colors.background,
  },
});

export default UnknownSteffiesScreen;
