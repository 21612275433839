/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import colors from '../../styles/colors';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    inputRoot: {
      color: colors.green,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.text,
        color:'white'
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor:  colors.text,
        color:'white'
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.text,
        color:'white'
      },
  
     
    
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
      
      }
     
    }
  }));
  
  

export default function SearchPickerComponent(props) {

    const classes = useStyles();
  return (
    <Autocomplete

    classes={{inputRoot: classes.inputRoot}}
    InputProps={{disableUnderline: true}}
    style={{
      
      backgroundColor:colors.component, 
      
      borderWidth:1, 
      borderColor:colors.text,

      
    }}
      id="combo-box-demo"
      options={props.arr}
      onChange={((event, newValue) => props.handleSearchPickerComponent(newValue))}
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="MessageWebshop Template" variant="outlined" />}
    />
  );
}