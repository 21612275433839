import React from "react";
import { Text } from "react-native";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment'

import colors from "../../styles/colors";
export default class WebshopTableComponent extends React.Component {
  render() {

    const columns = this.props.columns
    return (
      <TableContainer
        style={{ backgroundColor: colors.component }}
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: colors.component }}>

              {columns.map(item => 
                
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                  >
                  {item ? item.name : '' }
                </Text>
              </TableCell>
                  )}
           
          
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.arr.map(row => (
              // <TableRow key={row.name}>
              <TableRow onClick={() => this.props.onClick(row)} key={row.id}>
                <TableCell
                  style={{ color: colors.yellow }}
                  component="th"
                  scope="row"
                >
                  {row.id}
                </TableCell>
                <TableCell
                  style={{ color: colors.yellow }}
                  component="th"
                  scope="row"
                >
                  {row.refeuroId}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >

                  {moment(row.createdAt).format('YYYY MMMM DD')} 
                  <Text style={{color:colors.green, marginLeft:10 }}>
                      {moment(row.createdAt).fromNow()}
                      </Text>
                </TableCell>
                <TableCell
                  style={{ color: colors.orange }}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {row.products.length}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {row.email}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                {row.whatsapp}
                  {/* {row.lineItems.length} */}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                     {row.contactPage}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {/* {row.user.id} */}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {/* {row.user.username} */}
                </TableCell>
           
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
