
import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  TextInput
} from "react-native";
import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import TextInputMultiComponent from "../../../globalComponents/input/TextInputMultiComponent";
import SendIcon from "@material-ui/icons/Send";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import axios from "axios";

import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from '@material-ui/icons/Android';
import VignetteIcon from '@material-ui/icons/Vignette';
import moment from 'moment'

export default class DeviceComponent extends React.Component{

render() {

    const item = this.props.item

    return(


<View
style={{
  borderRadius: 6,
  width: 700,
  backgroundColor: colors.component,
  height: 40,
  marginVertical: 5,
  justifyContent: "space-between",
  flexDirection: "row"
  //   alignItems:'center'
}}
>

{  item.manufacturer === "Apple" ? 

<AppleIcon
style={{
marginLeft: 10,
color: colors.text,
alignSelf: "center"
}}
/> :  <AndroidIcon
style={{
marginLeft: 10,
color: colors.text,
alignSelf: "center"
}}
/> 
}

  <Text style={{ color: colors.text, alignSelf: "center" }}>
    {item.manufacturer}
  </Text>
  <Text style={{ color: colors.text, alignSelf: "center" }}>{item.deviceName}</Text>

{
    item.pushNotificationToken ? 

<Text>Ja</Text>
: 

<Text>NEe</Text>
}
<View style={{flexDirection:'row'}}>

<TouchableOpacity
style={{alignSelf:'center', marginHorizontal:5}}
  onPress={() =>
   alert(item.pushNotificationToken)
  }
>
  <VignetteIcon
    style={{
      alignSelf: "center",
      // marginRight: 10,
      color: item.pushNotificationToken?  colors.yellow : colors.red
    }}
  />
</TouchableOpacity>
<TouchableOpacity
 style={{alignSelf:'center', marginHorizontal:5}}
  onPress={() =>
    this.sendRefundPushNotification(item.pushNotificationToken)
  }
>
  <SendIcon
    style={{
      alignSelf: "center",
      // marginRight: 10,
      color:  item.pushNotificationToken ?  colors.green : colors
    }}
  />
</TouchableOpacity>
</View>
</View>

    )
}
}