import React from "react";
import { View, Text, TouchableOpacity, Dimensions } from "react-native";
import colors from "../../../styles/colors";
import Iframe from "react-iframe";
// import WebView from 'react-native-webview';

const url="https://www.coolblue.nl/product/810829/iiyama-prolite-e2783qsu-b1.html"
export default class ProductWebsiteScreen extends React.Component {
  render() {
    return (
      <View
        style={{
          backgroundColor: colors.background,
          width: Dimensions.get("screen").width,
          height: Dimensions.get("screen").height,
          position: "absolute"
        }}
      >
        <Iframe
       //   url="http://www.youtube.com/embed/xDMP3i36naA"
       url="https://www.coolblue.nl/product/810829/iiyama-prolite-e2783qsu-b1.html"
          width="450px"
          height="450px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />

<td onClick={()=> window.open(url, "_blank")}>text</td>
        {/* <WebView source={{ uri: 'https://reactnative.dev/' }} /> */}
      </View>
    );
  }
}
