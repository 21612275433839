import React, { Component } from "react";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";


import BobsHeader from "../../../globalComponents/Headers/BobsHeader";


import Monitor_ProductInvoicesTableComponent from "../components/Monitor_ProductInvoicesTableComponent";

export default class Monitor_ProductInvoicesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      users: [],
      invoiceQue: [],
      isLoading: true,
      priceInput: "",
      sendNotificationModal: false,
      item: "",
      selected: ""
    };
  }

  componentDidMount() {
    this._dataSource();
    // this.getProductsIncludeWebshop();
  }

  _dataSource = async () => {
   

    const productId = this.props.navigation.getParam("item").id;
    console.log("_dataSource => :", productId);
    try {
      await axios
        .get(
          apiEndpoint.getProductInvoices +
            this.props.navigation.getParam("item").id
          
        )
        .then(res => {
          console.log("sdfdsf" + JSON.stringify(res.data));
          this.setState({ dataSource: res.data, isLoading: false });
        });
    } catch (err) {
      this._dataSource();
      //  alert('err: ' + err)
      console.log(err);
    }
  };

  handleNavigation = (route, item) => {
    this.props.navigation.navigate(route, {
      item: item
    });

    console.log("Item: =>" + console.table(item));
  };

  updateInvoiceToScraping = async (route, item) => {


    let data = {
      queStatus: "SCRAPING"
    };
    {
      try {
        await axios
          .put(apiEndpoint.invoiceToScraping + item.id, data)
          .then(res => this.handleNavigation(route, item));
        //  console.log('Dit zijn de labels: ' + res.data);
      } catch (err) {
        //  alert('err: ' + err);
        console.log("err: steffiescherm" + err);
      }
    }
  };

  handlePriceInput = text => {
    this.setState({
      priceInput: text
    });
  };

  handleOnClick = (item, type) => {
      this.handleSendNotificationModal()
  }


  handleSendNotificationModal = item => {
    const sendNotificationModal = this.state.sendNotificationModal;
    this.setState({
      sendNotificationModal: !sendNotificationModal,
      item: item
    });
  };

  priceChangeModalSubmit = () => {
    const currentPrice = this.state.dataSource.product;

    if (this.state.priceInput) console.log("this.state.item", this.state.item);
    this.createPrice();
    this.handlePriceChangeModal();
  };

  createPrice = async () => {

    const currentDate = new Date();
    const productId = this.state.selected.id;
    console.log("product id => ...".productId);

    let data = {
      productId: productId,
      price: this.state.priceInput,
      date: currentDate

      // color:'pink'
    };

    
      try {
        await axios
          .post(apiEndpoint.createPrice, data)
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            this.setState({
              webshopId: res.data,
              snackbarComponent: true
            });
            this._dataSource();
            //  alert("gelukt: ", JSON.stringify(res));
          });
      } catch (err) {
        this.createPrice();

        //  alert("jammer joh");
      
    }
  };

  render() {
    if (this.state.isLoading === true)
      return (
        <View
          style={{
            height: Dimensions.get("window").height,
            width: Dimensions.get("screen").width,
            backgroundColor: colors.background,
            position: "absolute"
          }}
        ></View>
      );

    return (
      <View
      style={{
        backgroundColor: colors.background,
        width: Dimensions.get("screen").width,
        height: Dimensions.get("screen").height,
        position: "absolute"
      }}
    >
        <BobsHeader
          handleBack={() => this.props.navigation.navigate("ProductsToMonitor")}
          title={"Monitor_ProductInvoices"}
        />

        <Monitor_ProductInvoicesTableComponent
          arr={this.state.dataSource}
          // onClick={()=> alert('hoi')}
          onClick={(row, index, type)=> this.props.navigation.navigate('SendNotification',
          {item:row,
            price:'8888'
        })
          }
       
        />

      </View>
    );
  }
}
