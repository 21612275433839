import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  TextInput,
} from "react-native";
import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import TextInputMultiComponent from "../../../globalComponents/input/TextInputMultiComponent";
import SendIcon from "@material-ui/icons/Send";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import axios from "axios";

import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from "@material-ui/icons/Android";
import VignetteIcon from "@material-ui/icons/Vignette";
import moment from "moment";
import SearchPickerComponent from "../../../globalComponents/search/SearchPickerComponent";

export default class PriceChangeNotificationScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      text: "Ha",
      userId: this.props.navigation.getParam("item").userId,
      username: this.props.navigation.getParam("item").user.username,
      senderName: this.props.navigation.getParam("item").user.senderName,
      product_name: this.props.navigation.getParam("item").lineItems[0].product
        .name,
      price_paid: this.props.navigation.getParam("item").lineItems[0].pricePaid,
      //   current_price: this.props.navigation.getParam("price"),
      refund_amount: "2 miljoen",
      webshop: this.props.navigation.getParam("item").lineItems[0].product
        .webshop.name,
      lineItemId: this.props.navigation.getParam("item").id,
      totalLineItems: this.props.navigation.getParam("item").lineItems.length,
      refund_created: false,
      orderNumber: this.props.navigation.getParam("item").orderNumber,
      invoiceNumber: this.props.navigation.getParam("item").invoiceNumber,
      fcmTokenOfDevice: "",
      refund: "",
      title: "",
      totalPricePaid: "",
      currentPrice: "",
      // dataSource:{},
      refundId: "",
      messageToWebshopTemplates:[],
      selectedTemplate:''  
    };
  }

  handlePriceSelect = (item) => {
    this.setState(
      {
        currentPrice: item.price,
        priceId: item.id,
      },
      () => {
        this.calculations();
      }
    );
  };

  componentDidMount() {
    // this.setTextMessage();
    //this.totalPricePaid()
    this._dataSource();
   
    //   this.setMessageToWebshop()

    // setTimeout(()=>  {this.calculateRefundAmount(); this.setTextMessage(); this.setMessageToWebshop(); this.totalPricePaid()}, 100)
  }

  calculations = () => {
    this.calculateRefundAmount();
    this.setTextMessage();
  //  this.setMessageToWebshop();
  this.getMessageToWebshopTemplates()
  }

  setMessageToWebshopTemplate = () => {
    //#webshopName#

    const str = "Visit Microsoft!";
const res = str.replace("Microsoft", "W3Schools");
  }
  _dataSource = async () => {

    // return alert(JSON.stringify(this.props.navigation.getParam("item")))
    const productId = this.props.navigation.getParam("item").lineItems[0]
      .productId;
    // console.log("_dataSource => :", productId);
    try {
      await axios
        .get(apiEndpoint.getPrices + productId)
        .then((res) => {
          this.setState({
            dataSource: res.data,
            isLoading: false,
            currentPrice: res.data[0].price,
          });
          console.log("sdfdsf" + JSON.stringify(res.data));
        })
        .then(() => {
          // this.calculateRefundAmount();
          // this.setTextMessage();
          // this.setMessageToWebshop();
          this.calculations()
          this.totalPricePaid();
        });
    } catch (err) {
      //  alert('err: ' + err)
      console.log(err);
    }
  };
  getMessageToWebshopTemplates = async () => {

    try {
      await axios
        .get(apiEndpoint.getMessageToWebshopTemplates)
        .then((res) => {
          this.setState({
            messageToWebshopTemplates: res.data,
          });
          console.log("messageToWebshopTemplates" + JSON.stringify(res.data));
        })
        .then(() => {
          // this.calculateRefundAmount();
          // this.setTextMessage();
          // this.setMessageToWebshop();
          //this.calculations()
          //this.totalPricePaid();
          this.setMessageToWebshop(1)
          console.log('hosdfisdfdf')
        });
    } catch (err) {
      //  alert('err: ' + err)
      console.log(err);
    }
  };

  calculateRefundAmount = () => {
    // const lineItemPrice = Number(this.props.navigation.getParam('item').lineItems[0].pricePaid)
    const lineItemPrice = 1;

    const itemCount = this.props.navigation.getParam("item").lineItems.length;
    const current_price = Number(this.state.currentPrice);
    const refund_amount = (lineItemPrice - current_price) * itemCount;
    this.setState({
      refund_amount: refund_amount,
    });
  };

  totalPricePaid = () => {
    const lineItemPrice = Number(
      this.props.navigation.getParam("item").lineItems[0].pricePaid
    );
    const totalLineItems = Number(
      this.props.navigation.getParam("item").lineItems.length
    );

    const totalPricePaid = lineItemPrice * totalLineItems;
    this.setState({
      totalPricePaid: totalPricePaid,
    });
  };

  setTextMessage = () => {
    const {
      username,
      product_name,
      price_paid,
      current_price,
      webshop,
      refund_amount,
    } = this.state;

    const currentPrice = this.state.currentPrice;

    let title = `${product_name} prijskeldering, neem actie!`;
    let text = `Ha ${username}, je ${product_name} gekocht bij ${webshop} voor € ${price_paid} kost nu € ${currentPrice}. Open de Steffie App, en volg de stappen en claim je geld (${refund_amount})terug. Doe het nu en wacht niet!  ~xox~ Steffie.`;
    this.setState({
      message: text,
      title: title,
    });
  };

  handleSelectedTemplate = item =>{

    if(item){

      this.setState({
        selectedTemplate:item
      },()=> this.setMessageToWebshop())
    }
  }

  setMessageToWebshop = (index) => {
    const {
      username,
      product_name,
      price_paid,
      current_price,
      webshop,
      refund_amount,
      orderNumber,
      invoiceNumber,
      senderName,
    } = this.state;


    let templateText 
    let templateTitle


    const priceDifference = Number(price_paid) - Number(this.state.currentPrice)

    const amountOfLineItems = this.props.navigation.getParam("item").lineItems.length



    if(amountOfLineItems === 1){

       templateText =this.state.selectedTemplate.text;
       templateTitle =this.state.selectedTemplate.title;
       const title = templateText.replace("#webshopName#", webshop)
       .replace('#username#', username)
       .replace('#product_name#', product_name)
       .replace('#currentPrice#', this.state.currentPrice)
       .replace('#price_paid#', price_paid)
       .replace('#refund_amount#', refund_amount)
       .replace('#senderName#', senderName)
       .replace('#invoiceNumber#', invoiceNumber)
       .replace('#orderNumber#', orderNumber)
       .replace('#priceDifference#', priceDifference)
      const text = templateText.replace("#webshopName#", webshop)
      .replace('#username#', username)
      .replace('#product_name#', product_name)
      .replace('#currentPrice#', this.state.currentPrice)
      .replace('#price_paid#', price_paid)
      .replace('#refund_amount#', refund_amount)
      .replace('#senderName#', senderName)
      .replace('#invoiceNumbe#', invoiceNumber)
      .replace('#orderNumber#', orderNumber)
      .replace('#priceDifference#', priceDifference)
    
      
      this.setState({
        messageToWebshopText: text,
        messageToWebshopTitle: title,
      })
    } 
    
    if(amountOfLineItems >=0 ){

      templateText =this.state.selectedTemplate.textPlural;
      templateTitle =this.state.selectedTemplate.titlePlural;
      const title = templateTitle.replace("#webshopName#", webshop)
      .replace('#username#', username)
      .replace(/#product_name#/g, product_name)
      .replace('#currentPrice#', this.state.currentPrice)
      .replace('#price_paid#', price_paid)
      .replace('#refund_amount#', refund_amount)
      .replace('#senderName#', senderName)
      .replace('#invoiceNumber#', invoiceNumber)
      .replace('#orderNumber#', orderNumber)
      .replace('#priceDifference#', priceDifference)
      .replace(/#amountOfLineItems#/g, amountOfLineItems)
     const text = templateText.replace("#webshopName#", webshop)
     .replace(/#username#/g, username)
     .replace(/#product_name#/g, product_name)
     .replace('#currentPrice#', this.state.currentPrice)
     .replace('#price_paid#', price_paid)
     .replace('#refund_amount#', refund_amount)
     .replace('#senderName#', senderName)
     .replace('#invoiceNumber#', invoiceNumber)
     .replace('#orderNumber#', orderNumber)
     .replace('#priceDifference#', priceDifference)
     .replace(/#amountOfLineItems#/g, amountOfLineItems)
 
      this.setState({
        messageToWebshopText: text,
        messageToWebshopTitle: title,
      })
    }
    

    // checken of er 1 lineItem is
    // if(amountOfLineItems === 1) {

    //   let title = `Over mijn ${product_name}, onlangs besteld: ordernummer ${orderNumber}`;
    //   let text = `Beste medewerker van ${webshop}, <br><br>Onlangs heb ik een ${product_name} bij jullie besteld. Tot mijn verbazing hebben jullie de prijs naar beneden aangepast, hij kost nu ${this.state.currentPrice} terwijl ik ${price_paid} per stuk ervoor heb betaald. Kunnen jullie het verschil terugstorten (dus ${price_paid} - ${this.state.currentPrice} = €${refund_amount})? Of moet ik de ${product_name}'s terugsturen en opnieuw bestellen, wel zonde voor het milieu dan. <br><br>Groetjes ${senderName}`;
    //   this.setState({
    //     messageToWebshopText: text,
    //     messageToWebshopTitle: title,
    //   });
   
    // } else {

    //   // ${price_paid} - ${currentPrice} = ${priceDifference} x ${amountOfLineItems} = ${refund_amount} 
    //   let title = `Over mijn ${product_name}'s, onlangs besteld: ordernummer ${orderNumber}`;
    //   let text = `Beste medewerker van ${webshop}, <br><br>Onlangs heb ik ${amountOfLineItems} ${product_name}'s bij jullie besteld. Tot mijn verbazing hebben jullie de prijs naar beneden aangepast, hij kost nu ${this.state.currentPrice}. Kunnen jullie het verschil terugstorten (dus ${price_paid} - ${this.state.currentPrice} = ${priceDifference} x ${amountOfLineItems} = ${refund_amount}? Of moet ik de ${product_name} terugsturen en opnieuw bestellen, wel zonde voor het milieu dan. <br><br>Groetjes ${senderName}`;
    //   this.setState({
    //     messageToWebshopText: text,
    //     messageToWebshopTitle: title,
    //   });
    // }

    
  };

  onChangeText = (type, text) => {
    this.setState({
      [type]: text,
    });
  };

  // rerfund ammount calculeren
  createRefund = async () => {

    const messageToWebshopTemplateId = this.state.selectedTemplate.id
    const priceId = this.state.priceId
      ? this.state.priceId
      : this.state.dataSource[0].id;

    const lineItems = this.props.navigation
      .getParam("item")
      .lineItems.map((item) => item.id);
    const steffieId = this.props.navigation.getParam("item").id;
    const date = new Date();
    let data = {
      date: date,
      lineItemId: lineItems,
      refundAmount: this.state.refund_amount,
      refundStatusId: 1,
      text: this.state.message,
      userId: this.state.userId,
      priceId: priceId,
      title: this.state.title,
      messageToWebshopText: this.state.messageToWebshopText,
      messageToWebshopTitle: this.state.messageToWebshopTitle,
      steffieId: steffieId,
      messageToWebshopTemplateId:messageToWebshopTemplateId
    };


 
    try {
      await axios
        .post(
          apiEndpoint.postRefund,
          data
          //    {
          //   headers: auth_header
          // }
        )
        .then((res) => {
          console.log("res: " + JSON.stringify(res));
          this.setState({
            refund_created: true,
            refund: res.data,
          });
          alert("res: ", res.data);
          // this.createMessageToUser(res.data.id)
        });
    } catch (err) {
      alert("Niet gelukt: ", err);
      //  this.createRefund();
    }
  };


  sendRefundPushNotification = async (pushNotificationToken) => {
    let data = {
      message: this.state.message,
      title:  "Prijsdaling, actie vereist :)",
      fcmTokenOfDevice: pushNotificationToken,
    };
      try {
        await axios.post(apiEndpoint.sendPushNotification, data).then((res) => {
          console.log("res: " + JSON.stringify(res));
          alert("message send!");
        });
      } catch (err) {
       console.log('err sendRefundPushNotification => ', err)
    }
  };

  render() {
    if (this.state.isLoading === true)
      return (
        <View
          style={{
            height: Dimensions.get("window").height,
            width: Dimensions.get("screen").width,
            backgroundColor: colors.background,
            position: "absolute",
          }}
        ></View>
      );
    return (
      <View
        style={{
          backgroundColor: colors.background,
          width: Dimensions.get("screen").width,
          height: Dimensions.get("screen").height,
        }}
      >
        <BobsHeader
          handleBack={() => this.props.navigation.navigate("ProductsToMonitor")}
          title={"PriceChangeNotification"}
        />


        <View
          style={{
            marginLeft: 10,
            marginTop: 60,
            flexDirection: "row",
          }}
        >
          <View>
            <View style={{ marginVertical: 10, marginBottom: 25, height: 40 }}>
              <TextInputComponent
                value={this.state.title}
                label="Title of message"
                placeholder="Username"
                onChangeText={(text) => this.onChangeText("title", text)}
              />
            </View>
            <TextInputMultiComponent
              label="Push notification message"
              placeholder="sdfsdf"
              value={this.state.message}
              onChangeText={(text) => this.onChangeText("message", text)}
            />


            <SearchPickerComponent
            handleSearchPickerComponent={item => this.handleSelectedTemplate(item)}
            arr={this.state.messageToWebshopTemplates}
            />

            <View style={{marginVertical:20}}/>

            <View
              style={{
                marginVertical: 10,
                marginBottom: 25,
                marginTop: 40,
                height: 40,
              }}
            >
              <TextInputComponent
                value={this.state.messageToWebshopTitle}
                label="Title of message to webshop"
                placeholder="Username"
                onChangeText={(text) =>
                  this.onChangeText("messageToWebshopTitle", text)
                }
              />
            </View>
            <TextInputMultiComponent
              label="Message to Webshop"
              placeholder="sdfsdf"
              value={this.state.messageToWebshopText}
              onChangeText={(text) =>
                this.onChangeText("messageToWebshopText", text)
              }
            />
          </View>

          <View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.username}
                label="Username"
                placeholder="Username"
              />
            </View>

            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.product_name}
                label="Prodcutname"
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.webshop}
                label="Webshop"
                placeholder="Webshop"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                label="PricePaid per lineItem"
                value={this.state.price_paid}
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                label="Total lineItems"
                value={this.state.totalLineItems}
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                label="Total pricePaid lineItems // calculations"
                value={this.state.totalPricePaid}
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10, flexDirection: "row" }}>
              <TextInputComponent
                value={this.state.currentPrice}
                label={`Current price => id: ${
                  this.state.priceId
                    ? this.state.priceId
                    : this.state.dataSource[0].id
                }`}
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.refund_amount}
                label="Refund amount"
                placeholder="Prodcutname"
              />
            </View>

            <Text style={{ color: colors.text }}>Price overview</Text>

            {this.state.dataSource.map((item) => (
              <View
                style={{
                  justifyContent: "space-between",
                  elevation: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  margin: 5,
                  width: 300,
                  height: 30,
                  backgroundColor: colors.component,
                  borderRadius: 6,
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ color: colors.text, marginLeft: 5 }}>
                    {moment(item.createdAt).format("YYYY-MM-DD")}
                  </Text>
                  <Text style={{ color: colors.yellow, marginLeft: 5 }}>
                    {moment(item.createdAt).format("HH:mm:ss")}
                  </Text>
                </View>
                <Text style={{ color: colors.green, marginRight: 5 }}>
                  € {item.price}
                </Text>

                <Text style={{ color: colors.red, marginRight: 5 }}>
                  id: {item.id}
                </Text>
                <TouchableOpacity
                  onPress={() => this.handlePriceSelect(item)}
                  style={{
                    width: 25,
                    height: 25,
                    borderWidth: 1,
                    borderColor: colors.text,
                  }}
                ></TouchableOpacity>
              </View>
            ))}
          </View>
        </View>

        {this.state.refund_created ? (
          <View style={{ marginLeft: 30, bottom: 20 }}>
            <Text
              style={{ color: colors.text, fontWeight: "bold", fontSize: 16 }}
            >
              Refund inserted in Database
            </Text>

            <Text
              style={{ color: colors.text, fontWeight: "bold", width: 140 }}
            >
              {" "}
              {JSON.stringify(this.state.refund, null, 4)}
            </Text>

            {/* {Object.entries(this.state.refund).map(([key, val]) => (
              <View style={{ flexDirection: "row", marginVertical: 3 }}>
                <Text
                  style={{ color: colors.text, fontWeight: "bold", width: 140 }}
                  key={key} 
                >
                  {key}:
                </Text>
                <Text style={{ color: colors.green, maxWidth: 300 }}>
                  {val}
                </Text>
              </View>
            ))} */}
          </View>
        ) : null}

        {this.state.refund_created ? (
          <View style={{ marginLeft: 30, bottom: 20 }}>
            <View
              style={{
                borderRadius: 6,
                width: 530,
                backgroundColor: colors.component,
                height: 40,
                marginVertical: 5,
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                //   alignItems:'center'
              }}
            >
              <Text style={{ color: colors.text, fontSize: 16 }}>
                Send notifaction to user{" "}
              </Text>
            </View>
            {this.props.navigation.getParam("item").user.devices.map((item) => (
              // {this.props.navigation.getParam('item').user.devices.map(item =>
              <View
                style={{
                  borderRadius: 6,
                  width: 500,
                  backgroundColor: colors.component,
                  height: 40,
                  marginVertical: 5,
                  justifyContent: "space-between",
                  flexDirection: "row",
                  //   alignItems:'center'
                }}
              >
                {item.manufacturer === "Apple" ? (
                  <AppleIcon
                    style={{
                      marginLeft: 10,
                      color: colors.text,
                      alignSelf: "center",
                    }}
                  />
                ) : (
                  <AndroidIcon
                    style={{
                      marginLeft: 10,
                      color: colors.text,
                      alignSelf: "center",
                    }}
                  />
                )}

                <Text style={{ color: colors.text, alignSelf: "center" }}>
                  {item.manufacturer}
                </Text>
                <Text style={{ color: colors.text, alignSelf: "center" }}>
                  {item.deviceName}
                </Text>

                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    style={{ alignSelf: "center", marginHorizontal: 5 }}
                    onPress={() => alert(item.pushNotificationToken)}
                  >
                    <VignetteIcon
                      style={{
                        alignSelf: "center",
                        // marginRight: 10,
                        color: colors.yellow,
                      }}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ alignSelf: "center", marginHorizontal: 5 }}
                    onPress={() =>
                      this.sendRefundPushNotification(
                        item.pushNotificationToken
                      )
                    }
                  >
                    <SendIcon
                      style={{
                        alignSelf: "center",
                        // marginRight: 10,
                        color: colors.green,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <TouchableOpacity
            onPress={() => this.createRefund()}
            style={{
              // top: 50,
              left: 330,
              bottom: 30,
              borderRadius: 6,
              borderColor: colors.text,
              borderWidth: 1,
              // alignSelf:"center",
              backgroundColor: colors.component,
              width: 100,
              height: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ color: colors.text }}>Create Refund</Text>
          </TouchableOpacity>
        )}

        {/* <Text>{JSON.stringify(this.props.navigation.getParam('item'), null, 4)}</Text> */}
        {/* <Text>{JSON.stringify(this.state.dataSource[0].price, null, 4)}</Text> */}
      </View>
    );
  }
}
