import React from "react";
import {
  Image,
  Dimensions,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  TextInput,
} from "react-native";

import FileBase from "react-file-base64";
import axios from "axios";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";
import SearchProductTypeComponent from "../../../globalComponents/search/SearchProductTypeComponent";
import HttpIcon from '@material-ui/icons/Http';
//import './WebshopEditScreen.css';

const DefaultImg = "";


export default class WebshopEditScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refeuroInvoiceId:0,
      //   DefaultImg:'',
      pictures: "",
      users: [],
      invoiceQue: [],
      boxImage:this.props.navigation.getParam("item", "<<<<empty>>>>").boxImage,
      name: this.props.navigation.getParam("item", "<<<<empty>>>>").name,
      support: this.props.navigation.getParam("item", "<<<<empty>>>>").support,
      id: this.props.navigation.getParam("item", "<<<<empty>>>>").id,
      image: this.props.navigation.getParam("item", "<<<<empty>>>>").image,
      phone: this.props.navigation.getParam("item", "<<<<empty>>>>").phone,
      email: this.props.navigation.getParam("item", "<<<<empty>>>>").email,
      validationStep: this.props.navigation.getParam("item", "<<<<empty>>>>").validationStep,
      returnDurationDaysPage: this.props.navigation.getParam(
        "item",
        "<<<<empty>>>>"
      ).returnDurationDaysPage,
      emailComplaints: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .emailComplaints,
      whatsapp: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .whatsapp,
      instagram: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .instagram,
      facebook: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .facebook,
      btwIdentificationNumber: this.props.navigation.getParam(
        "item",
        "<<<<empty>>>>"
      ).btwIdentificationNumber,
      termsAndConditionsPage: this.props.navigation.getParam(
        "item",
        "<<<<empty>>>>"
      ).termsAndConditionsPage,
      returnPolicyPage: this.props.navigation.getParam(
        "item",
        "<<<<empty>>>>"
      ).returnPolicyPage,
      twitter: this.props.navigation.getParam("item", "<<<<empty>>>>").twitter,
      youtube: this.props.navigation.getParam("item", "<<<<empty>>>>").youtube,
      linkedIn: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .linkedIn,
      pinterest: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .pinterest,
      snapchat: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .snapchat,
      owner: this.props.navigation.getParam("item", "<<<<empty>>>>").owner,
      kvk: this.props.navigation.getParam("item", "<<<<empty>>>>").kvk,
      contactPage: this.props.navigation.getParam("item", "<<<<empty>>>>")
        .contactPage,
      returnDurationDays: this.props.navigation.getParam(
        "item",
        "<<<<empty>>>>"
      ).returnDurationDays,
      convertedImageURL: "",
      imageURL: this.props.navigation.getParam(
        "item",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/SIPI_Jelly_Beans_4.1.07.tiff/lossy-page1-256px-SIPI_Jelly_Beans_4.1.07.tiff.jpgimageURL"
      ).imageURL,
      mondayOpening: null,
      mondayClosing: null,
      types: [],
    };
  }

  componentDidMount() {
    // this.getOpeningHours();
    // this.getUsers();
    // this._getProductTypes();
    this.just_one_steffie_by_webshopId()
    // this.getInvoiceQue()
  }
  _getProductTypes = async () => {
    try {
      await axios
        .get(apiEndpoint.getProductTypes)
        .then((res) =>
          this.setState({
            types: res.data.types,
            warrantyDarations: res.data.warrantyDurations,
            economicWarrantyDuration: res.data.economicWarrantyDurations,
          })
        );
    } catch (err) {
      this._getDataSource();
      console.log("err: steffiescherm" + err);
    }
  };

  getBaseFile = async (files) => {
    //const webshopId = this.props.navigation.;
    const webshopId = this.props.navigation.getParam("item").id;

    // create a local readable base64 instance of an image
    this.setState({
      baseImage: files.base64,
      image: files.base64,
    });

    let imageObj = {
      imageName: "base-image-" + Date.now(),
      image: files.base64.toString(),
      webshopId: webshopId,
    };

    axios
      .post(apiEndpoint.createImage, imageObj)
      .then((data) => {
        if (data.data.success) {
          alert("Image has been successfully uploaded using base64 format");
          this.setDefaultImage("base");
        }
      })
      .catch((err) => {
        alert("Error while uploading image using base64 format");
        this.setDefaultImage("base");
      });
  };

  getOpeningHours = async () => {
    const webshopId = this.props.navigation.getParam("item").id;
    try {
      await axios.get(apiEndpoint.openingHours + webshopId).then((res) => {
        this.setState(
          {
            mondayOpening: res.data[0].mondayOpening,
            mondayClosing: res.data[0].mondayClosing,
            tuesdayOpening: res.data[0].tuesdayOpening,
            tuesdayClosing: res.data[0].tuesdayClosing,
            wednesdayOpening: res.data[0].wednesdayOpening,
            wednesdayClosing: res.data[0].wednesdayClosing,
            thursdayOpening: res.data[0].thursdayOpening,
            thursdayClosing: res.data[0].thursdayClosing,
            fridayOpening: res.data[0].fridayOpening,
            fridayClosing: res.data[0].fridayClosing,
            saterdayOpening: res.data[0].saterdayOpening,
            saterdayClosing: res.data[0].saterdayClosing,
            sundayOpening: res.data[0].sundayOpening,
            sundayClosing: res.data[0].sundayClosing,
          },
          // () => alert(JSON.stringify(res.data[0]))
        );
      });
    } catch (err) {
      alert(err);
    }
  };

  handleTextInputClick = (text, x) => {
    this.setState({ [x]: text });
  };
  handleSubmit = () => {
    this.updateWebshop();
  };

  updateWebshop = async (i, value) => {
    let data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      emailComplaints: this.state.emailComplaints,
      whatsapp: this.state.whatsapp,
      instagram: this.state.instagram,
      facebook: this.state.facebook,
      twitter: this.state.twitter,
      youtube: this.state.youtube,
      linkedIn: this.state.linkedIn,
      pinterest: this.state.pinterest,
      snapchat: this.state.snapchat,
      owner: this.state.owner,
      kvk: this.state.kvk,
      contactPage: this.state.contactPage,
      returnDurationDays: this.state.returnDurationDays,
      imageURL: this.state.imageURL,
      returnDurationDaysPage: this.state.returnDurationDaysPage,
      termsAndConditionsPage: this.state.termsAndConditionsPage,
      btwIdentificationNumber: this.state.btwIdentificationNumber,
      image:this.state.image,
      returnPolicyPage:this.state.returnPolicyPage,
      support:this.state.support,

      // color:'pink'
    };

    const webshopId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.updateWebshop + webshopId, data)
          .then((res) => {
            alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };
  handleSaveImage = async (i, value) => {
    let data = {
      image:this.state.image,
      boxImage:this.state.boxImage
     
    };

    const webshopId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.updateWebshopImage + webshopId, data)
          .then((res) => {
            alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };


  updateOpeningsHours = async (i, value) => {
    let data = {
      mondayOpening: this.state.mondayOpening,
      mondayClosing: this.state.mondayClosing,
      tuesdayOpening: this.state.tuesdayOpening,
      tuesdayClosing: this.state.tuesdayClosing,
      wednesdayOpening: this.state.wednesdayOpening,
      wednesdayClosing: this.state.wednesdayClosing,
      thursdayOpening: this.state.thursdayOpening,
      thursdayClosing: this.state.thursdayClosing,
      fridayOpening: this.state.fridayOpening,
      fridayClosing: this.state.fridayClosing,
      saterdayOpening: this.state.saterdayOpening,
      saterdayClosing: this.state.saterdayClosing,
      sundayOpening: this.state.sundayOpening,
      sundayClosing: this.state.sundayClosing,
    };

    const webshopId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.openingHours + webshopId, data)
          .then((res) => {
            alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };

  setDefaultImage(uploadType) {
    if (uploadType === "multer") {
      this.setState({
        multerImage: DefaultImg,
      });
    } else if (uploadType === "firebase") {
      this.setState({
        firebaseImage: DefaultImg,
      });
    } else {
      this.setState({
        baseImage: DefaultImg,
      });
    }
  }

  handleBack = () => {
    if (this.props.navigation.getParam("route", "nope") !== "nope") {
      if (this.props.navigation.getParam("route", "nope") === "Webshops") {
        this.props.navigation.navigate("Webshop");
      } else if (
        this.props.navigation.getParam("route", "nope") === "WebshopsToScrape"
      ) {
        this.props.navigation.navigate("WebshopsToScrape");
      }
    } else {
      this.props.navigation.navigate("Dashboard");
    }
  };

  just_one_steffie_by_webshopId = async () => { 

    const webshopId = this.props.navigation.getParam("item").id;
    try {
    await axios
      .get(apiEndpoint.just_one_steffie_by_webshopId + webshopId)
      .then((res) =>
        this.setState({
          refeuroInvoiceId: res.data.refeuroInvoiceId,
        })
      );
  } catch (err) {
    // this._getDataSource();

    alert(err)
    console.log("err: steffiescherm" + err);
  }

}
  render() {
    return (
      <View
        style={{
          // position: "absolute",

          // height: Dimensions.get("screen").height,
          // width: Dimensions.get("window").width,
          backgroundColor: colors.background,
          flex:1,
        }}
      >
        <BobsHeader
          title={"WebshopEdit -xxxx"}
          handleBack={() => this.props.navigation.navigate("Dashboard")}
        />

        {/* <View style={{ marginTop: 60 }} /> */}

        <View style={{ flexDirection: "row" }}>

          
           
          <View>
          {/* <HttpIcon
           onClick={()=>   window.open(`https://wwww.${this.state.name}`)}
              
              style={{fontSize:50, color: colors.text,marginLeft:10,   alignSelf: "center",}}/> */}
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"name"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) => this.handleTextInputClick(text, "name")}
                value={this.state.name}
              />
                 <TextInputComponent
                label={"returnDurationDays"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "returnDurationDays")
                }
                value={this.state.returnDurationDays}
              />
               <TextInputComponent
                label={"returnPolicyPage"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "returnPolicyPage")
                }
                value={this.state.returnPolicyPage}
              />
            
             
            </View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"contactPage"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "contactPage")
                }
                value={this.state.contactPage}
              />
               <TextInputComponent
                label={"termsAndConditionsPage"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "termsAndConditionsPage")
                }
                value={this.state.termsAndConditionsPage}
              />
               <TextInputComponent
                label={"facebook"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "facebook")
                }
                value={this.state.facebook}
              />
             
            </View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"Email"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "email")
                }
                value={this.state.email}
              />
                 <TextInputComponent
                label={"phone"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "phone")
                }
                value={this.state.phone}
              />

<TextInputComponent
                label={"whatsapp"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "whatsapp")
                }
                value={this.state.whatsapp}
              />
              
             
             
            </View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"fully-supported  --> lijst supported webshops"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "support")
                }
                value={this.state.support}
              />
                 <TextInputComponent
                label={"validationStep"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "phone")
                }
                value={this.state.validationStep}
              />


              
             
             
            </View>
            <View
          style={{
            flexDirection: "row",
            marginHorizontal: 20,
            marginVertical: 20,
            // top:30,
            borderWidth: 1,
            borderColor: colors.white,
            marginTop: 10,
            borderRadius: 8,
            // bottom: 40,
            width: 740,
            //   top: 50,
            //alignSelf:'center',
            backgroundColor: colors.component,
          }}
        >
        
        
          <TextInput
            label={"image"}
            placeholderTextColor={colors.red}
            placeholder={`Afbeelding (base64 ) Open refeuroScraper - tabblad "Afbeeldingen u/d factuur" - "copy image adres"`}
            onChangeText={(text) => this.handleTextInputClick(text, "image")}
            value={this.state.image}
            style={[styles.textInput, { color: colors.green }]}
          />
        </View>
            <View
          style={{
            flexDirection: "row",
            marginHorizontal: 20,
            marginVertical: 20,
            // top:30,
            borderWidth: 1,
            borderColor: colors.white,
            marginTop: 10,
            borderRadius: 8,
            // bottom: 40,
            width: 740,
            //   top: 50,
            //alignSelf:'center',
            backgroundColor: colors.component,
          }}
        >

        
          <TextInput
            label={"boxImage"}
            placeholderTextColor={colors.red}
            placeholder={"<<<<wat de placeholder is voor als product niet gescraped kan worden) --- productImage || boxImage || webshopThumbnail/logo >>>>"}
            onChangeText={(text) => this.handleTextInputClick(text, "boxImage")}
            value={this.state.boxImage}
            style={[styles.textInput, { color: colors.green }]}
          />
        </View>

        
            <View style={{ flexDirection: "row", marginVertical: 20 }}/>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
            
              <TextInputComponent
                label={"instagram"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "instagram")
                }
                value={this.state.instagram}
              />
               <TextInputComponent
                label={"emailComplaints"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "emailComplaints")
                }
                value={this.state.emailComplaints}
              />
            </View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
           
              <TextInputComponent
                label={"twitter"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "twitter")
                }
                value={this.state.twitter}
              />
            </View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"youtube"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "youtube")
                }
                value={this.state.youtube}
              />
              <TextInputComponent
                label={"linkedIn"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "linkedIn")
                }
                value={this.state.linkedIn}
              />
            </View>
            {/* <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"pinterest"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "pinterest")
                }
                value={this.state.pinterest}
              />
              <TextInputComponent
                label={"snapchat"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "snapchat")
                }
                value={this.state.snapchat}
              />
            </View> */}
            {/* <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"owner"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "owner")
                }
                value={this.state.owner}
              />
              <TextInputComponent
                label={"kvk"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) => this.handleTextInputClick(text, "kvk")}
                value={this.state.kvk}
              />
            </View>
            */}
          
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
           
              <TextInputComponent
                label={"btwIdentificationNumber"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "btwIdentificationNumber")
                }
                value={this.state.btwIdentificationNumber}
              />
            </View>
          </View>

          <View>
       
          <TouchableOpacity
          onPress={()=>   window.open(`https://steffieapp.nl/web/unaccepted/${this.state.refeuroInvoiceId}`)}
            // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Open Refeuro Scraper</Text>
            </TouchableOpacity>
     
     
          <TouchableOpacity
          onPress={()=>   window.open(`https://${this.state.name}`)}
            // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Open Webshop-page</Text>
            </TouchableOpacity>
          <TouchableOpacity
          onPress={()=>  this.handleSubmit()}
            // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Save Information</Text>
            </TouchableOpacity>
          <TouchableOpacity
          onPress={()=>  this.handleSaveImage()}
            // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Save Image</Text>
            </TouchableOpacity>
          <TouchableOpacity
          // onPress={()=>  this.handleSubmit()}
          onPress={()=> window.open('https://onlinepngtools.com/convert-png-to-base64')}
            // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Image converter</Text>
            </TouchableOpacity>
            <Image
            style={{
              // bottom: 250,
                // backgroundColor: "white",
              // position: "absolute",
              // right: 560,
              width: 200,
              height: 200,
              resizeMode: "contain",
              // right:700,
              // marginBottom:50
            }}
            source={this.state.image}
          />
          </View>
 
        </View>

        {/* <View
          style={{
            flexDirection: "row",
            marginHorizontal: 20,
            marginVertical: 20,
            // top:30,
            borderWidth: 1,
            borderColor: colors.white,
            marginTop: 10,
            borderRadius: 8,
            // bottom: 40,
            width: 740,
            //   top: 50,
            //alignSelf:'center',
            backgroundColor: colors.component,
          }}
        >
          <Text style={[styles.text, { fontWeight: "normal" }]}>
            Webshop imageURL
          </Text>
          <TextInput
            label={"imageURL"}
            placeholderTextColor={colors.text}
            placeholder={"<<<<empty>>>>"}
            onChangeText={(text) => this.handleTextInputClick(text, "imageURL")}
            value={this.state.imageURL}
            style={[styles.textInput, { color: colors.green }]}
          />
        </View> */}
       
        <View
          style={{
            bottom: 100,
            // /  backgroundColor: "white",
            position: "absolute",
            right: 300,
          }}
        >
        
          {/* <img src={{uri:this.state.image}} /> */}
          {/* <Text style={{ color: colors.white }}>
            Als de afbeelding geen url heeft, dan afbeelding downloaden en via
            hieronder uploaden:
          </Text> */}
          {/* <FileBase type="file" multiple={false} onDone={this.getBaseFile} /> */}
        </View>

      
        <Text style={[styles.text, { fontWeight: "normal" }]}>
          support (fully-supported komt 'ie in de lijst van supported webshops
            boxImage (dus wat de placeholder is voor als product niet gescraped kan worden) --- productImage || boxImage || webshopThumbnail/logo 
          </Text>
          <Text style={[styles.text, { fontWeight: "normal" }]}>
          data:image/jpeg;base64,   Afbeelding (base64 ) Open refeuroScraper - tabblad "Afbeeldingen u/d factuur" - "copy image adres"<Text style={{color:colors.green}}>- Open refeuroScraper - tabblad "Afbeeldingen u/d factuur" - "copy image adres"</Text>
          </Text>
        
       
      </View>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    position: "absolute",
    fontWeight: "bold",
    bottom: 40,
    color: colors.text,
    fontSize: 14,
    left: 5,
  },
  loginButton: {
    position: "absolute",
    bottom: 10,
    borderTopWidth: 1,
    width: "100%",
    height: 40,
    justifyContent: "center",
    borderTopColor: colors.white,
    alignSelf: "center",
    marginRight: 30,
  },
  registerButton: {
    width: "60%",
    borderWidth: 1,
    alignSelf: "center",
    height: 30,
    marginTop: 10,
    justifyContent: "center",
    borderRadius: 6,
    borderColor: colors.white,
    bottom: 40,
    width: 350,
    backgroundColor: colors.component,
    alignSelf: "center",
  },
  logoContainer: {
    width: "100%",
    top: 30,
    position: "absolute",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  logo: {
    color: colors.white,
    fontSize: 80,
    alignSelf: "center",
    // opacity:0.5
  },
  textInput: {
    height: 40,
    // marginLeft: 10,
    width: Dimensions.get("window").width - 40,
    alignSelf: "center",
    color: colors.red,
    paddingLeft: 30,
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center",
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.white,
    marginTop: 10,
    borderRadius: 8,
    bottom: 40,
    width: 350,
    //alignSelf:'center',
    backgroundColor: colors.component,
  },
});
