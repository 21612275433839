import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import axios from "axios";

import colors from "../../../styles/colors";
import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import { Document, Page, pdfjs } from "react-pdf";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import InvoiceComponent from "../components/InvoiceComponent";

export default class __final_steffieScreen extends React.Component {
  constructor(props) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {

    if(this.props.navigation.getParam("item").id){

      this._getDataSource();
      this.getInvoice();
    } else {
      this.setState({isLoading:false})
    }
  }

  _getDataSource = async () => {
    const steffieId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getSteffie + steffieId)
        .then((res) => this.setState({ dataSource: res.data }));
    } catch (err) {
      alert("err");
    }
  };
  getInvoice = async () => {
    const invoiceId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getSteffieBase64 + invoiceId)
        .then((res) =>
          this.setState({ invoice: res.data.invoice, isLoading: false })
        );
    } catch (err) {
      console.log("Wt is er aan de hand? ", err);
      alert("error");
    }
  };


  // sendPushNotification = async () => {
  //   const fcmTokenOfDevice = req.body.fcmTokenOfDevice;
  //   const message = req.body.message
  //   const title = 'Je Steffie voor je bestelling biis klaar'

  //   try {
  //     await axios
  //       .post(apiEndpoint.sendPushNotification + invoiceId, data)
  //       .then((res) => {
  //         alert("jup");
  //         console.log("uh huh huh uh u : => ", res);
  //         // alert("gelukt");
  //       }).then(()=> this.sendPushNotification());
  //   } catch (err) {
  //     alert("jammer joh");
  //   }
  // }



  handleInvoiceToAccepted = async (i, value) => {

    const userId = this.props.navigation.getParam('item').userId
    const webshopId = this.props.navigation.getParam('item').webshopId
    let data = {
      queStatus: this.state.queStatus,
      emailComplaints: this.state.emailComplaints,
      invoiceDate: this.state.invoiceDate,
      paymentAmount: this.state.paymentAmount,
      deliveryCosts: this.state.deliveryCosts,
     
      webshopId:webshopId,
      queueId: 4,
      userId:userId
      //    returnPeriodDays: this.state.twitter
    };

    // const item = this.props.navigation.getParam('item')
    

    const invoiceId = this.props.navigation.getParam("item").id;

      try {
        await axios
          .put(apiEndpoint.putSteffie_lineItems_accepted + invoiceId, data)
          .then((res) => {
            alert("jup");
            console.log("uh huh huh uh u : => ", res);
            // alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
  };

handleInvoice


  render() {
    const { numPages } = this.state;

    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background,
          }}
        >
          <ActivityIndicator />
        </View>
      );
    }
    return (
      <View
        style={{
          backgroundColor: colors.background,
          height: Dimensions.get("screen").height,
          width: Dimensions.get("screen").width,
          position: "absolute",
        }}
      >
        <BobsHeader
          handleBack={() =>
            this.props.navigation.navigate("Steffies")
          }
          title={"__final_steffie x"}
        />

        <View style={{ flexDirection: "row" }}>
        <Document
            // file={samplePDF}
            // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
            file={`data:application/pdf;base64,${this.state.invoice}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>

          <View style={{flex:1, backgroundColor:colors.blue, position:'absolute', zIndex:1, height:1000, width:600, opacity:0.4}}/>

          {/* <Text style={{color:colors.text}}>{JSON.stringify(this.state.dataSource, null, 4)}</Text> */}

          <View style={{ marginHorizontal: 20 }}>
            <InvoiceComponent item={this.state.dataSource} />

            <TouchableOpacity
              onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 40,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.text,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.text }}>Steffie is goed</Text>
            </TouchableOpacity>
          </View>

          <View style={{ marginHorizontal: 60 }} />
         
        </View>

            <Text style={{color:colors.red, fontSize:17}}>{JSON.stringify(this.state.dataSource, null, 4)}</Text>
      </View>
    );
  }
}
