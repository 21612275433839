import React from "react";
import { View, Text, TextInput, StyleSheet, Dimensions } from "react-native";
import colors from "../../../styles/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';


export default class InputComponent extends React.Component {
  render() {
    const label = this.props.label;
    const value = this.props.value;
    const price = this.props.price;
    const url = this.props.url;
    const productId = this.props.productId;
    const placeholder = this.props.placeholder;
    return (

        <View>
      <View style={{ flexDirection: "row", marginVertical: 10 }}>
        <View style={styles.textInputContainer}>
          <Text style={styles.text}>Product</Text> 
          <Text
            style={{
              position: "absolute",
                fontWeight: "bold",
              bottom: 42,
              color: colors.green,
              fontSize: 14,
              left: 70
            }}
          >
          
            {productId}
          </Text>
          <TextInput
            type={"number"}
            autoFocus={this.props.autoFocus}
            placeholderTextColor={colors.text}
            placeholder={"product"}
            onChangeText={text => this.props.onChangeText(text)}
            value={value}
            style={[styles.textInput, { width: 350 }]}
          />
        </View>
        <View style={styles.textInputContainerPrice}>
          <Text style={styles.text}>Price</Text>
          <Text
            style={{
              position: "absolute",
              //  fontWeight: "bold",
              bottom: 11,
              color: colors.text,
              fontSize: 14,
              left: 10
            }}
          >
            €
          </Text>
          <TextInput
            type={"number"}
            autoFocus={this.props.autoFocus}
         //   onBlur={() => this.props.onBlur()}
            placeholderTextColor={colors.text}
            placeholder={"price"}
            onChangeText={text => this.props.onChangeTextPrice(text)}
            value={price}
            style={[styles.textInput]}
          />
        </View>

        {this.props.deleteOption ? null : (
         
         <View style={{flexDirection:'row', alignItems:'center'}}>

         <RemoveIcon
           onClick={() => this.props.remove()}
            style={{
              alignSelf: "center",
              color: colors.text,
              fontSize: 30,
              bottom: 40

              //    position:'absolute',
              //    bottom:20,
              //   right:20,
              //   top:20
              //   top:50
            }}
          />

          <Text style={{fontSize:20, color:colors.text}}> 1 </Text>

         <AddIcon
            onClick={() => this.props.remove()}
            style={{
              alignSelf: "center",
              color: colors.text,
              fontSize: 30,
              bottom: 40

              //    position:'absolute',
              //    bottom:20,
              //   right:20,
              //   top:20
              //   top:50
            }}
          />
          </View>
        )}
      </View>
   
       

      </View>
    );
  }
}

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    // marginLeft: 10,
    //   width: Dimensions.get("window").width - 120,
    alignSelf: "center",
    color: colors.green,
    paddingLeft: 30,
    zIndex: 1,
    width: 100
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center"
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: colors.white,
    marginHorizontal: 10,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    // bottom: 40,
    width: 350,
    //alignSelf:'center',

    backgroundColor: colors.component
  },
  textInputContainerPrice: {
    flexDirection: "row",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: colors.white,
    marginHorizontal: 10,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    // bottom: 40,
    width: 100,
    //alignSelf:'center',

    backgroundColor: colors.component
  },
  text: {
    position: "absolute",
    //  fontWeight: "bold",
    bottom: 42,
    color: colors.text,
    fontSize: 14,
    left: 5
  }
});
