import React, { Component } from "react";
import {
  View,
  Dimensions,
  ActivityIndicator,
  Linking
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import TableComponent from "../components/TableComponent";

import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

export default class SteffiesToScrapeDownloadPdfScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      dataSource: [],
      invoiceQue: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this._getDataSource();
  }

  _getDataSource = async () => {
    try {
      await axios
        .get(apiEndpoint.getSteffiesToProcess)
        .then((res) =>
          this.setState({ dataSource: res.data, isLoading: false })
        );
    } catch (err) {
      alert("Niet gelukt", err);
    }
  };
  
  
  downloadInvoice = async (item) => {
    const steffieId = item.id

    //let image = await axios.get(file, {responseType: 'arraybuffer'});
    
    const open =  await axios
    .get(apiEndpoint.getSteffiePdfDownload + steffieId) .then(response => {
      const content = response.headers['content-type'];
 //     download(response.data, file.file_name, content)
   })
   .catch(error => console.log(error));
    
    
//    Linking.openURL('http://localhost:1337/invoices/download')
  //   Linking.openURL(open)
  }
//download steffie
  _downLoadSteffie = async (item) => {
    const steffieId = item.id
    axios({
      url: apiEndpoint.getSteffiePdfDownload + steffieId, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'file.pdf'); //or any other extension
       document.body.appendChild(link);
     //
       link.click();
    });
  }
  downLoadSteffie = async (item) => {
    const steffieId = item.id
    axios({
      url: apiEndpoint.getSteffiePdfDownload + steffieId, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data],{type: 'application/pdf'}),  );
    //    const link = document.createElement('a');
    //    link.href = url;
    //    link.setAttribute('download', 'file.pdf'); //or any other extension
    //    document.body.appendChild(link);
    //  //
    //    link.click();
    window.open(url);
    });
  }

  

  // localhost:3000/invoices/admin/orders/processing

  handleNavigation = async (route, item) => {
    this.props.navigation.navigate(route, {
      item: item,
      //   header:auth_header
    });

    console.log("Item: =>" + console.table(item));
  };

  handleOnClick = (row) => {
    alert(row)
 //   this.downloadInvoice(row)

 //   this.props.navigation.navigate("SteffieScrape", { item: row });
    console.log("userId: ", row.userId);
    console.log("row: ", row);
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background,
          }}
        >
          <ActivityIndicator />
        </View>
      );
    } else {
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: colors.background,
            height: Dimensions.get("window").height,
            width: Dimensions.get("window").width,
            // position: "absolute"
          }}
        >
          <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"SteffiesToScrapeDownloadPdfScreen"}
          />

          <View>
            <TableComponent
              onClick={(row) => this.downLoadSteffie(row)}
              arr={this.state.dataSource}
            />
          </View>
        </View>
      );
    }
  }
}
