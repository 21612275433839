import React, { Component, createRef } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  TextInput,
  ActivityIndicator,
  Text,
  Image,
  TouchableOpacity
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";


import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import SearchComponent from "../../../globalComponents/search/SearchComponent";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import SnackbarComponent from "../../../globalComponents/snackbar/SnackbarComponent";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";
import FileBase from "react-file-base64";
import SearchProductTypeComponent from "../../../globalComponents/search/SearchProductTypeComponent";
import SearchProductWarrantyComponent from "../../../globalComponents/search/SearchProductWarrantyComponent";

const DefaultImg = "";
//import Dropzone from 'react-dropzone'



export default class ProductEditScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warrantyDarations:[],
      economicWarrantyDaration:[],
      webshopId: "...",
      types: [],
      // webshop_name:'',
      //  webshop_name: this.props.navigation.getParam("item", "??????????").webshop.name,
      snackbarComponent: false,
      webshopId: this.props.navigation.getParam("item", "??????????").webshopId,
      productId: this.props.navigation.getParam("item", "??????????").id,
      image: this.props.navigation.getParam("item", "??????????").image,
      productWebsiteUrl: this.props.navigation.getParam("item", "??????????").productWebsiteUrl,
      refundProbability: this.props.navigation.getParam("item", "??????????").refundProbability,
      productTypeId: this.props.navigation.getParam("item", "??????????").productTypeId,
      warrantyTypeId: this.props.navigation.getParam("item", "??????????").warrantyTypeId,
      imageUrl: this.props.navigation.getParam("item", "??????????").imageUrl,
      warrantyDurationId: this.props.navigation.getParam("item", "??????????").warrantyDurationId,
      warrantyDuration: this.props.navigation.getParam("item", "??????????").warrantyDuration,
    
 
      snackbarComponent: false,

      invoiceQue: [],
      webshopName: this.props.navigation.getParam("item", "??????????").webshop,
      name: this.props.navigation.getParam("item", "??????????").name,
      warrantyType: this.props.navigation.getParam("item", "??????????")
        .warrantyType,
      warrantyDurationMonths: this.props.navigation.getParam(
        "item",
        "??????????"
      ).warrantyDurationMonths,
      productNumber: this.props.navigation.getParam("item", "??????????")
        .productNumber,
      emailComplaints: this.props.navigation.getParam("item", "??????????")
        .emailComplaints,

      invoiceQue: [],
      name: this.props.navigation.getParam("item", "??????????").name,
      phone: this.props.navigation.getParam("item", "??????????").phone,
      email: this.props.navigation.getParam("item", "??????????").email,
      emailComplaints: this.props.navigation.getParam("item", "??????????")
        .emailComplaints,
      whatsapp: this.props.navigation.getParam("item", "??????????").whatsapp,
      instagram: this.props.navigation.getParam("item", "??????????").instagram,
      facebook: this.props.navigation.getParam("item", "??????????").facebook,
      twitter: this.props.navigation.getParam("item", "??????????").twitter,
      youtube: this.props.navigation.getParam("item", "??????????").youtube,
      linkedIn: this.props.navigation.getParam("item", "??????????").linkedIn,
      pinterest: this.props.navigation.getParam("item", "??????????").pinterest,
      snapchat: this.props.navigation.getParam("item", "??????????").snapchat,
      owner: this.props.navigation.getParam("item", "??????????").owner,
      kvk: this.props.navigation.getParam("item", "??????????").kvk,
      contactPage: this.props.navigation.getParam("item", "??????????")
        .contactPage,
      refundDurationDays: this.props.navigation.getParam("item", "??????????")
        .refundDurationDays
      // name: this.props.navigation.getParam("item", "??????????").name,
      // name: this.props.navigation.getParam("item", "??????????").name,
      // name: this.props.navigation.getParam("item", "??????????").name,
      // name: this.props.navigation.getParam("item", "??????????").name
    };
  }

  componentDidMount() {
    if (this.props.navigation.getParam("item", "nope") === "nope") {
      this.setState({
        webshop_name: ""
      });
    } else {
      this.setState({
        webshop_name: this.props.navigation.getParam("item", "??????????")
          .webshop.name
      });
    }

    // this.getUsers();
    // this.getInvoiceQue()
    this._getDataSource();
    this._getProductTypes();
    this._getProductWarranties();
  }

  _getDataSource = async () => {

    
      try {
        await axios
          .get(apiEndpoint.getWebshops)
          .then(res => this.setState({ dataSource: res.data }));
      } catch (err) {
        this._getDataSource();
        console.log("err: steffiescherm" + err);
      }
    
  };

  _getProductTypes = async () => {
    
      try {
        await axios
          .get(apiEndpoint.getProductTypes)
          .then(res => this.setState({ types: res.data.types,
          warrantyDarations:res.data.warrantyDurations,
          economicWarrantyDuration:res.data.economicWarrantyDurations,
        }));
      } catch (err) {
        this._getDataSource();
        console.log("err: steffiescherm" + err);
      
    }
  };
  _getProductWarranties = async () => {
    
      try {
        await axios
          .get(apiEndpoint.getProductWarranties)
          .then(res => this.setState({ warranties: res.data }));
      } catch (err) {
        this._getDataSource();
        console.log("err: steffiescherm" + err);
      }
    
  };

  handleNavigation = (route, item) => {
    this.props.navigation.navigate(route, {
      item: item
    });

    console.log("Item: =>" + console.table(item));
  };

  handleTextInputClick = (text, x) => {
    this.setState({ [x]: text });
  };

  handleSubmit = () => {
    this.updateProduct();
  };

  handleSelected = (value, type) => {
    if (type === "webshop") {
      if (!value) {
        this.setState({ webshopId: "<.?.>", webshop_name: "<.?.>" });
      } else {
        this.setState({
          webshopId: value.id,
          webshop_name: value.name
        });
      }
    } else if (type === "WarrantyType") {
      if (!value) {
        this.setState({ warrantyTypeId: "<.?.>", warrantyTypeName: "<.?.>" });
      } else {
        this.setState({
          warrantyTypeId: value.id,
          warrantyTypeName: value.name
        });
      }
    } else if (type === "WarrantyDuration") {
      if (!value) {
        this.setState({ warrantyDurationId: "<.?.>", warrantyDurationName: "<.?.>" });
      } else {
        this.setState({
          warrantyDurationId: value.id,
          warrantyDurationName: value.name
        });
      }
    } else if (type === "EconomicWarrantyDuration") {
      if (!value) {
        this.setState({ economicWarrantyDurationId: "<.?.>", economicWarrantyDurationName: "<.?.>" });
      } else {
        this.setState({
          economicWarrantyDurationId: value.id,
          economicWarrantyDurationName: value.name
        });
      }
    } else if (type === "ProductType") {
      if (!value) {
        this.setState({ productTypeId: "<.?.>", productTypeName: "<.?.>" });
      } else {
        this.setState({
          productTypeId: value.id,
          productTypeName: value.name
        });
      }
    }
  };

  updateProduct = async (i, value) => {

    let data = {
      name: this.state.name,
      imageUrl:this.state.imageUrl,
      productNumber: this.state.productNumber,
      warrantyType: this.state.warrantyType,
      warrantyDurationMonths: this.state.warrantyDurationMonths,
      productWebsiteURL: this.state.productWebsiteURL,
      refundProbability: this.state.refundProbability,
      refundExpectedAmount: this.state.refundExpectedAmount,
      productImage: this.state.productImage,
      typeId:this.state.productTypeId,
      warrantyId:this.state.warrantyTypeId,
      webshopId: this.state.webshopId,
      warrantyDurationId: this.state.warrantyDurationId,
      economicWarrantyDurationId: this.state.economicWarrantyDurationId,
      imageUrl:this.state.imageUrl
      // color:'pink'
    };

    alert(JSON.stringify(data))

    
      try {
        await axios
          .put(apiEndpoint.putProduct + this.state.productId, data)
          .then(res => {
            alert("gelukt", res);
          });
      } catch (err) {
        //  alert('err: ' + err);
        alert("jammer joh");
      
    }
  };
  createWebshop = async x => {
   

    const value = x.name;
    let data = {
      name: value,
      phone: this.state.phone,
      email: this.state.email,
      emailComplaints: this.state.emailComplaints,
      whatsapp: this.state.whatsapp,
      instagram: this.state.instagram,
      facebook: this.state.facebook,
      twitter: this.state.twitter,
      youtube: this.state.youtube,
      linkedIn: this.state.linkedIn,
      pinterest: this.state.pinterest,
      snapchat: this.state.snapchat,
      owner: this.state.owner,
      kvk: this.state.kvk,
      contactPage: x.contactPageURL,
      webshopURL: x.webshopURL,
      refundDurationDays: this.state.refundDurationDays
      // color:'pink'
    };

    {
      try {
        await axios
          .post(apiEndpoint.postWebshop, data)
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            this.setState({
              webshopId: res.data,
              snackbarComponent: true
            });

            //  alert("gelukt: ", JSON.stringify(res));
          });
      } catch (err) {
        this.createWebshop(x);
        //  alert("jammer joh");
      }
    }
  };

  handleSearchSubmit = value => {
    this.setState({ webshopId: value.name });
    this.createWebshop(value);
    console.log(value);
  };

  handleSnackbar = () => {
    this.setState({
      snackbarComponent: false
    });
  };

  getBaseFile = async files => {
  

    const productId = this.state.productId;
    //const webshopId = this.props.navigation.getParam("item").id;

    // create a local readable base64 instance of an image
    this.setState({
      baseImage: files.base64,
      image: files.base64
    });

    let imageObj = {
      imageName: "base-image-" + Date.now(),
      image: files.base64.toString(),
      productId:productId
    };

    axios
      .post(apiEndpoint.createImage, imageObj)
      .then(data => {
        if (data.data.success) {
          alert("Image has been successfully uploaded using base64 format");
          this.setDefaultImage("base");
        }
      })
      .catch(err => {
        alert("Error while uploading image using base64 format");
        this.setDefaultImage("base");
      });
  };

  setDefaultImage(uploadType) {
    if (uploadType === "multer") {
      this.setState({
        multerImage: DefaultImg
      });
    } else if (uploadType === "firebase") {
      this.setState({
        firebaseImage: DefaultImg
      });
    } else {
      this.setState({
        baseImage: DefaultImg
      });
    }
  }

  handleGoBack = () => {
    if (this.props.navigation.getParam("route", "nope") !== "nope") {
      if (this.props.navigation.getParam("route", "nope") === "Products") {
        this.props.navigation.navigate("Products");
      } else if (
        this.props.navigation.getParam("route", "nope") === "ProductsToScrape"
      ) {
        this.props.navigation.navigate("ProductsToScrape");
      } else {
        this.props.navigation.navigate("Dashboard");
      }
    }
    if (this.props.navigation.getParam("item", "nope") === "nope") {
      this.props.navigation.navigate("Dashboard");
    } else {
      this.props.navigation.navigate("ProductsToScrape");
    }
  };

  render() {
    const dropzoneRef = createRef();
    const item = this.props.navigation.getParam("item");
    return (
      <View
        style={{
          position: "absolute",

          height: Dimensions.get("screen").height,
          width: Dimensions.get("screen").width,
          backgroundColor: colors.background
        }}
      >
        <BobsHeader
          handleBack={() => this.handleGoBack()}
          title={"ProductEdit xx"}
        />

        <View
          style={{
            left: 20,
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <SearchComponent
            webshop={"webshop"}
            value={this.state.webshopName}
            arr={this.state.dataSource}
            handleSubmit={value => this.handleSearchSubmit(value, "webshop")}
            handleSelected={value => this.handleSelected(value, "webshop")}
          />

          <TrendingFlatIcon style={{ color: colors.text, fontSize: 50 }} />
          <View
            style={{
              top: 10,
              borderRadius: 6,

              left: 15,
              justifyContent: "center",
              alignItems: "center",
              width: 100,
              height: 30,
              backgroundColor: colors.component,
              borderWidth: 1,
              borderColor: colors.text
            }}
          >
            <Text
              style={{
                position: "absolute",
                right: 30,
                color: colors.white,
                bottom: 30
              }}
            >
              WebshopId
            </Text>
            <Text style={{ color: colors.white }}>{this.state.webshopId}</Text>
          </View>
          <View
            style={{
              top: 10,
              borderRadius: 6,
              marginLeft: 25,
              left: 15,
              justifyContent: "center",
              alignItems: "center",
              width: 200,
              height: 30,
              backgroundColor: colors.component,
              borderWidth: 1,
              borderColor: colors.text
            }}
          >
            <Text
              style={{
                position: "absolute",
                left: 0,
                color: colors.white,
                bottom: 30
              }}
            >
              Choosen webshop
            </Text>
            <Text style={{ color: colors.white }}>
              {this.state.webshop_name}
            </Text>
          </View>


        </View>

        <View style={{ marginVertical: 40 }} />
        <View style={{ flexDirection: "row", marginVertical: 10 }}>
          <TextInputComponent
            label={"Product name"}
            value={this.state.name}
            placeholder={"testPlaceholder"}
            onChangeText={text => this.handleTextInputClick(text, "name")}
          />
          <TextInputComponent
            label={"productNumber"}
            value={this.state.productNumber}
            placeholder={"testPlaceholder"}
            onChangeText={text =>
              this.handleTextInputClick(text, "productNumber")
            }
          />
        </View>
        <View style={{ flexDirection: "row", marginVertical: 10 }}>
        <TextInputComponent
            label={"productWebsiteUrl"}
            value={this.state.productWebsiteUrl}
            placeholder={"productWebsiteUrl"}
            onChangeText={text =>
              this.handleTextInputClick(text, "productWebsiteUrl")
            }
          />
         
          
        </View>

        <View style={{ flexDirection: "row", marginVertical: 10 }}>
         
          <TextInputComponent
            label={"refundExpectedAmount"}
            value={this.state.refundExpectedAmount}
            placeholder={"refundExpectedAmount"}
            onChangeText={text =>
              this.handleTextInputClick(text, "refundExpectedAmount")
            }
          />
           <TextInputComponent
            label={"Refund probability"}
            value={this.state.refundProbability}
            placeholder={"RefundProbability"}
            onChangeText={text =>
              this.handleTextInputClick(text, "refundProbability")
            }
          />
        </View>
        <View style={{ flexDirection: "row", marginVertical: 10 }}>
         
          <TextInputComponent
            label={"imageUrl"}
            value={this.state.imageUrl}
            placeholder={"imageUrl"}
            onChangeText={text =>
              this.handleTextInputClick(text, "imageUrl")
            }
          />
         
        </View>

        <View style={{ flexDirection: "row", marginVertical: 10 }}>
       

          <View
            style={{
              bottom: 50,
              // /  backgroundColor: "white",
              position: "absolute",
              right: 400
            }}
          >
            <Image
              style={{
                // bottom: 250,
                // // /  backgroundColor: "white",
                // position: "absolute",
                // right: 560,
                width: 200,
                height: 200,
                resizeMode: "contain",
                marginBottom: 10
              }}
              source={this.state.image}
            />
            <FileBase type="file" multiple={false} onDone={this.getBaseFile} />
          </View>
         
        </View>

        <View style={{flexDirection:'row'}}>
        <View style={{ marginLeft: 10 }}>

          <View style={{flexDirection:'row'}}>

          <Text style={{ color: colors.text }}>ProductType</Text>
          <Text style={{ color: colors.green, left:20 }}>{this.state.productTypeId}</Text>
          </View>
          <SearchProductTypeComponent
            webshop={"test"}
            arr={this.state.types}
            handleSubmit={value =>
              this.handleSearchSubmit(value, "ProductType")
            }
            handleSelected={value => this.handleSelected(value, "ProductType")}
          />
        </View>
        <View style={{ marginLeft: 10 }}>
          <View style={{flexDirection:'row'}}>

        <Text style={{ color: colors.text }}>Warranty Type</Text>
        <Text style={{ color: colors.green, left:20 }}>{this.state.wr}</Text>
          </View>
        <SearchProductWarrantyComponent
          webshop={"test"}
          arr={this.state.warranties}
          handleSubmit={value => this.handleSearchSubmit(value, "WarrantyType")}
          handleSelected={value => this.handleSelected(value, "WarrantyType")}
        />
        </View>
        </View>
        <View style={{flexDirection:'row', marginTop:10}}>

       
        <View style={{ marginLeft: 10 }}>
          <View style={{flexDirection:'row'}}>

          <Text style={{ color: colors.text }}>Warranty Duration (months)</Text>
        <Text style={{ color: colors.green, left:20 }}>{this.state.warrantyDurationId}</Text>
          </View>
        <SearchProductWarrantyComponent
          webshop={"test"}
          value={this.state.warrantyDuration ? this.state.warrantyDuration.name : null}
          arr={this.state.warrantyDarations}
          handleSubmit={value => this.handleSearchSubmit(value, "WarrantyDuration")}
          handleSelected={value => this.handleSelected(value, "WarrantyDuration")}

       
        />
        </View>
        <View style={{ marginLeft: 10 }}>
          <View style={{flexDirection:'row'}}>

        <Text style={{ color: colors.text }}>Ecnomic Warranty Duration (months)</Text>
        <Text style={{ color: colors.green, left:20 }}>{this.state.economicWarrantyDurationId}</Text>
          </View>
        <SearchProductWarrantyComponent
          webshop={"test"}
          value={this.state.productType}
          arr={this.state.economicWarrantyDuration}
          handleSubmit={value => this.handleSearchSubmit(value, "EconomicWarrantyDuration")}
          handleSelected={value => this.handleSelected(value, "EconomicWarrantyDuration")}

       
        />
        </View>
        </View>
        <TouchableOpacity
          onPress={() => this.handleSubmit()}
          style={{
            top: 50,
            left: 330,
            borderRadius: 6,
            borderColor: colors.text,
            borderWidth: 1,
            // alignSelf:"center",
            backgroundColor: colors.component,
            width: 100,
            height: 40,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text style={{ color: colors.text }}>Submit</Text>
        </TouchableOpacity>
        <SnackbarComponent
          handleClose={() => this.handleSnackbar()}
          open={this.state.snackbarComponent}
          autoHideDuration={2000}
        />
         <View style={{position:'absolute', right:10}}>

</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    position: "absolute",
    //  fontWeight: "bold",
    bottom: 42,
    color: colors.text,
    fontSize: 14,
    left: 5
  },
  loginButton: {
    position: "absolute",
    bottom: 10,
    borderTopWidth: 1,
    width: "100%",
    height: 40,
    justifyContent: "center",
    borderTopColor: colors.white,
    alignSelf: "center",
    marginRight: 30
  },
  registerButton: {
    width: "60%",
    borderWidth: 1,
    alignSelf: "center",
    height: 30,
    marginTop: 10,
    justifyContent: "center",
    borderRadius: 6,
    borderColor: colors.white,
    bottom: 40,
    width: 350,
    backgroundColor: colors.component,
    alignSelf: "center"
  },
  logoContainer: {
    width: "100%",
    top: 30,
    position: "absolute",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  logo: {
    color: colors.white,
    fontSize: 80,
    alignSelf: "center"
    // opacity:0.5
  },
  textInput: {
    height: 40,
    // marginLeft: 10,
    width: Dimensions.get("window").width - 40,
    alignSelf: "center",
    color: colors.green,
    paddingLeft: 30,
    zIndex: 1
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center"
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.white,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    bottom: 40,
    width: 350,
    //alignSelf:'center',
    backgroundColor: colors.component
  }
});

