import React from 'react'

import {View, Text, Dimensions, TouchableOpacity} from 'react-native'
import TextInputComponent from '../../../globalComponents/input/TextInputComponent'
import TextInputMultiComponent from '../../../globalComponents/input/TextInputMultiComponent'
import colors from '../../../styles/colors'
import BobsHeader from '../../../globalComponents/Headers/BobsHeader'

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import axios from "axios";


const rejectionReasons = [
  {id:1 , name:'Geen factuur'},
  {id:2 , name:'Factuur valt niet te lezen'},
  {id:3, name:"Factuur 'te oud', valt buiten retourtermijn"},
]

export default class SteffieRejectionScreen extends React.Component {

constructor(props){
  super(props);
  this.state = {
    messageToUserText:'fuck',
    messageToUserTitle:'Steffie niet aangemaakt, factuur afgewezen'
  }
}


noInvoiceMessage = () => {
  const steffie = this.props.navigation.getParam('item')
  const user = this.props.navigation.getParam('item').user
  const invoice = this.props.navigation.getParam('item').invoice
  
  const message = `
  Beste ${user.username},

  Helaas voldoet de door jouw ingestuurde factuur niet aan de richtlijnen. Hierdoor hebben wij jammer genoeg de factuur moeten afwijzen.

  Althans, de factuur die je instuurde, is geen factuur. Mocht je bezwaar willen aanmaken tegen deze beslissing, dan kan dat niet. Lekker uh puh. 
  (Heerlijk, zo'n machtpositie, ik begin de Ilimunati te begrijpen)

  Met vriendelijke groetjes,

  -xox- Steffie

  p.s. Wij zijn hard bezig om in App geluidsbestanden te kunnen afspelen, dan hoor je voortaan ons evil lachje, whahhahaha. Dus schroom niet om te doneren ;)
  `

  this.setState({
    messageToUserText:message
  })
}
unabelToReadInvoice = () => {
  const steffie = this.props.navigation.getParam('item')
  const user = this.props.navigation.getParam('item').user
  const invoice = this.props.navigation.getParam('item').invoice
  
  const message = `
Beste ${user.username},

Helaas voldoet de door jouw ingestuurde factuur niet aan de richtlijnen. Hierdoor hebben wij jammer genoeg de factuur moeten afwijzen.

Afwijsreden:
Onze stagiair heeft gelogen op zijn CV, hij zei dat hij kon kickboxen. Hier kwamen wij in de ring achter. Nu hebben we geen stagiair meer. 
Woutertje 2 moet nu de facturen overtypen. Hij heeft autisme en kon er niet tegen dat je factuur zo slecht te lezen is. 

Sorry voor dat Wouter de factuur afkeurde, Wouter kan er ook niet zoveel aan doen 
dat onze stagiair loog op zijn CV. 
  
  
Met vriendelijke groetjes,
  
-xox- Steffie 
  
  
p.s. Wouter's autisme is overigeins zijn eigen keuze en daar hebben wij geen respect voor.`

  this.setState({
    messageToUserText:message
  })
}
invoiceIsToOld = () => {
  const steffie = this.props.navigation.getParam('item')
  const user = this.props.navigation.getParam('item').user
  const invoice = this.props.navigation.getParam('item').invoice
  
  const message = `
Beste ${user.username},

Helaas voldoet de door jouw ingestuurde factuur niet aan de richtlijnen. Hierdoor hebben wij jammer genoeg de factuur moeten afwijzen.

Afwijsreden:
Op een oude moet je het leren, maar een te oude factuur, daar kunnen wij nu nog niet zo veel mee. 
Met vriendelijke groetjes,

-xox- Steffie 
  `

  this.setState({
    messageToUserText:message
  })
}


handleRejectionReason = (item) => {
  if(item.id ===1){
    this.noInvoiceMessage() 
  }

  if(item.id===2) {
    this.unabelToReadInvoice()
  }
  if(item.id===3) {
    this.invoiceIsToOld()
  }

}

onChangeText = (type, text) => {
  this.setState({
    [type]: text
  })
}

sendRejectionMessage = async () => {
 
  const steffie = this.props.navigation.getParam('item')
  const user = this.props.navigation.getParam('item').user
  const invoice = this.props.navigation.getParam('item').invoice


    let data = {
      userId: user.id,
      title: this.state.messageToUserTitle,
      text: this.state.messageToUserText,
    //  messageStatusId:1,
 //     messageTypeId:1,
      steffieId:steffie.id
     // lineItemId:lineItems,
    //  refundId:id,
    //  priceId:priceId
    };

    {
      try {
        await axios
          .post(apiEndpoint.postMessageToUserInvoiceRejection, 
            data,
          //    {
          //   headers: auth_header
          // }
          )
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            // this.setState({
            //   //refund_created: true,
            //   refund: res.data
            // });
            alert('Ok')
          });
      } catch (err) {
        alert('Nope....')
      }
    }
}


    render(){
        return(
            <View
            
            style={{
                backgroundColor: colors.background,
                width: Dimensions.get("screen").width,
                height: Dimensions.get("screen").height,
                position: "absolute"
              }}
            >
                 <BobsHeader
          handleBack={() => this.props.navigation.navigate("Dashboard")}
          title={"SteffieRejectionScreen"}
        />

        <View style={{marginTop:20}}/>

        <Text  style={{marginLeft:20, color:colors.text, fontWeight:'bold'}}>Kies afwijs reden</Text>

        {
          rejectionReasons.map((item, key) => 
          <TouchableOpacity

          onPress={()=> this.handleRejectionReason(item)}
          style={{backgroundColor:colors.component,
            width:500,
            margin:5,
            height:40,
            flexDirection:'row',
            alignItems:'center'
          }}
          key={key}
          
          >

<View style={{
  marginLeft:10,
  width:20, height:20, borderRadius:10, backgroundColor:colors.background}}/>
            <Text style={{marginLeft:20, color:colors.text, fontStyle:'italic'}}>{item.name}</Text>
            </TouchableOpacity>
            )
          }
          <View style={{marginTop:40}}/>



                
<View style={{ marginVertical: 10,marginBottom:25,marginTop:40, height:40 }}>
           <TextInputComponent
                value={this.state.messageToUserTitle}
                label="Title of message to User"
                placeholder="Username"
                onChangeText={text => this.onChangeText('messageToUserTitle', text)}
              />
                  
            </View>
          <TextInputMultiComponent
            label="Message to User"
            placeholder="sdfsdf"
            value={this.state.messageToUserText}
            onChangeText={text => this.onChangeText('messageToUserText', text)}
            />

            <TouchableOpacity

            onPress={()=> this.sendRejectionMessage() }
            
            style={{backgroundColor:colors.component,
              width:300,
              borderWidth:1,
              borderColor:colors.text,
              borderRadius:6,
              margin:5,
              height:40,
              flexDirection:'row',
              alignItems:'center',
              justifyContent:'center',

            }}
                        >

<Text style={{marginLeft:20, color:colors.text, fontStyle:'italic'}}>Verstuur afwijs bericht</Text>

            </TouchableOpacity>

<Text style={{marginLeft:20, color:colors.text, fontStyle:'italic'}}>{JSON.stringify(this.props.navigation.getParam('item'), null, 4)}</Text>

                </View>
        )
    }
}

