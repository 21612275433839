import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  TextInput
} from "react-native";
import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import TextInputMultiComponent from "../../../globalComponents/input/TextInputMultiComponent";
import SendIcon from "@material-ui/icons/Send";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import axios from "axios";

import AppleIcon from "@material-ui/icons/Apple";
import AndroidIcon from '@material-ui/icons/Android';
import VignetteIcon from '@material-ui/icons/Vignette';
import moment from 'moment'

export default class SendNotificationScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      text: "Ha",
      userId: this.props.navigation.getParam("item").userId,
      username: this.props.navigation.getParam("item").user.username,
      product_name: this.props.navigation.getParam("item").lineItems[0].product.name,
      price_paid:this.props.navigation.getParam("item").lineItems[0].pricePaid,
   //   current_price: this.props.navigation.getParam("price"),
      refund_amount: "2 miljoen",
      webshop: this.props.navigation.getParam("item").lineItems[0].product.webshop.name,
      lineItemId: this.props.navigation.getParam("item").id,
      totalLineItems: this.props.navigation.getParam('item').lineItems.length,
      refund_created: false,
      orderNumber: this.props.navigation.getParam("item").orderNumber,
      invoiceNumber: this.props.navigation.getParam("item").invoiceNumber,
      fcmTokenOfDevice: "",
      refund: "",
      title:'',
      totalPricePaid:'',
     // dataSource:{},
      refundId:''
    };
  }

  handlePriceSelect = (item) => {
      this.setState({
current_price:item.price,
priceId:item.id
      })
  }

  componentDidMount() {
  
    this.setTextMessage();
    this.totalPricePaid()
    this._dataSource()
    this.setMessageToWebshop()
  }

  _dataSource = async () => {
   const productId = this.props.navigation.getParam("item").lineItems[0].productId
    console.log("_dataSource => :", productId);
    try {
      await axios
        .get(
          apiEndpoint.getPrices +
          productId
          
        )
        .then(res => {
          console.log("sdfdsf" + JSON.stringify(res.data));
          this.setState({ dataSource: res.data, isLoading: false });
        });
    } catch (err) {
      this._dataSource();
      //  alert('err: ' + err)
      console.log(err);
    }
  };

  totalPricePaid = () => {  
    const lineItemPrice = Number(this.props.navigation.getParam('item').lineItems[0].pricePaid)
    const  totalLineItems = Number(this.props.navigation.getParam('item').lineItems.length)

    const totalPricePaid = lineItemPrice * totalLineItems
    this.setState({
      totalPricePaid:totalPricePaid
    })
    

  }

  setTextMessage = () => {
    const {
      username,
      product_name,
      price_paid,
      current_price,
      webshop,
      refund_amount,
    } = this.state;

    let title = `${product_name} prijskeldering, neem actie!`
    let text = `Ha ${username}, je ${product_name} gekocht bij ${webshop} voor € ${price_paid} kost nu € ${current_price}. Open de Steffie App, en volg de stappen en claim je geld (${refund_amount})terug. Doe het nu en wacht niet!  ~xox~ Steffie.`;
    this.setState({
      message: text,
      title:title
    });
  };

  setMessageToWebshop = () => {
    const {
      username,
      product_name,
      price_paid,
      current_price,
      webshop,
      refund_amount,
      orderNumber,
      invoiceNumber,
    } = this.state;

    let title = `Over mijn ${product_name}, onlangs besteld: ordernummer ${orderNumber}`
    let text = `Beste medewerker van ${webshop}, <br><br>Onlangs heb ik een ${product_name} bij jullie besteld. Tot mijn verbazing hebben jullie de prijs naar beneden aangepast, hij kost nu ${current_price}. Kunnen jullie het verschil terugstorten (dus €${refund_amount})? Of moet ik de ${product_name} terugsturen en opnieuw bestellen, wel zonde voor het milieu dan. <br><br>Groetjes ${username}`
 
 
    this.setState({
      messageToWebshopText: text,
      messageToWebshopTitle:title
    });
   }

  onChangeText = text => {
    this.setState({
      message: text
    });
  };

  createRefund = async () => {

    const priceId = this.state.priceId ? this.state.priceId : this.state.dataSource[0].id

   const lineItems =  this.props.navigation.getParam('item').lineItems.map((item) => item.id);
    const date = new Date()
    let data = {
      date: date,
      lineItemId: lineItems,
      refundAmount: "12312342.123123123123131313121",
      refundStatusId: 1,
      message: this.state.message,
      userId:this.state.userId,
      priceId:priceId,
      title:this.state.title,
      messageToWebshopText: this.state.messageToWebshopText,
      messageToWebshopTitle:this.state.messageToWebshopTitle
    };
    
      try {
        await axios
          .post(apiEndpoint.postRefund, data,
          //    {
          //   headers: auth_header
          // }
          )
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            this.setState({
              refund_created: true,
             refund: res.data
            }); 
            alert('res: ', res.data)
           // this.createMessageToUser(res.data.id)
          });
      } catch (err) {
        alert('Niet gelukt: ', err)
      //  this.createRefund();
      
    }
  };


// Ik weeet niet wat deze mdoet..... kan weg?
  createMessageToUser = async (id) => {
    const lineItems =  this.props.navigation.getParam('item').lineItems.map((item) => item.id);

    const priceId = this.state.priceId ? this.state.priceId : this.state.dataSource[0].id

    const date = new Date();
    let data = {
      userId: this.state.userId,
      title: this.state.title,
      message: this.state.message,
      messageStatusId:1,
      messageTypeId:1,
      //lineItemId:this.state.lineItemId,
      lineItemId:lineItems,
      refundId:id,
      priceId:priceId
    };

    {
      try {
        await axios
          .post(apiEndpoint.postMessageToUser, 
            data,
          //    {
          //   headers: auth_header
          // }
          )
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            // this.setState({
            //   //refund_created: true,
            //   refund: res.data
            // });
            alert('Ok')
          });
      } catch (err) {
        alert('Nope....')
      }
    }
  };

  sendRefundPushNotification = async pushNotificationToken => {
    const date = new Date();
    let data = {
      date: date,
      lineItemId: this.state.lineItemId,
      refundAmount: "12312342.123123123123131313121",
      refundStatusId: 1,
      message: this.state.message,
      title:this.state.title,
      fcmTokenOfDevice: pushNotificationToken,
    };
    {
      try {
        await axios
          .post(apiEndpoint.sendPushNotification, data,
            
          )
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            alert("message send!");
          });
      } catch (err) {
        this.sendRefundPushNotification();
      }
    }
  };

  render() {
    if (this.state.isLoading === true)
      return (
        <View
          style={{
            height: Dimensions.get("window").height,
            width: Dimensions.get("screen").width,
            backgroundColor: colors.background,
            position: "absolute"
          }}
        ></View>
      );
    return (
      <View
        style={{
          backgroundColor: colors.background,
          width: Dimensions.get("screen").width,
          height: Dimensions.get("screen").height
        }}
      >
        <BobsHeader
          handleBack={() => this.props.navigation.navigate("ProductsToMonitor")}
          title={"Send notification"}
        />
        <View
          style={{
            marginLeft: 10,
            marginTop: 60,
            flexDirection: "row" }}
        >
       <View>

          <View style={{ marginVertical: 10,marginBottom:25, height:40 }}>
           <TextInputComponent
                value={this.state.title}
                label="Title of message"
                placeholder="Username"
              />
                  
            </View>
          <TextInputMultiComponent
            label="Push notification message"
            placeholder="sdfsdf"
            value={this.state.message}
            onChangeText={text => this.onChangeText(text)}
            />

<View style={{ marginVertical: 10,marginBottom:25,marginTop:40, height:40 }}>
           <TextInputComponent
                value={this.state.messageToWebshopTitle}
                label="Title of message to webshop"
                placeholder="Username"
              />
                  
            </View>
          <TextInputMultiComponent
            label="Message to Webshop"
            placeholder="sdfsdf"
            value={this.state.messageToWebshopText}
            onChangeText={text => this.onChangeText(text)}
            />
            </View>

          <View>
        
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.username}
                label="Username"
                placeholder="Username"
              />
            </View>

            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.product_name}
                label="Prodcutname"
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.webshop}
                label="Webshop"
                placeholder="Webshop"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                label="PricePaid per lineItem"
                value={this.state.price_paid}
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                label="Total lineItems"
                value={this.state.totalLineItems}
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                label="Total pricePaid lineItems // calculations"
                value={this.state.totalPricePaid}
                placeholder="Prodcutname"
              />
            </View>
            <View style={{ marginVertical: 10, flexDirection:'row' }}>
          
              <TextInputComponent
                value={this.state.current_price ? this.state.current_price : this.state.dataSource[0].price}
                label={`Current price => id: ${this.state.priceId ? this.state.priceId : this.state.dataSource[0].id}`}
                placeholder="Prodcutname"
                />
             
              
              
               
            </View>
            <View style={{ marginVertical: 10 }}>
              <TextInputComponent
                value={this.state.refund_amount}
                label="Refund amount"
                placeholder="Prodcutname"
              />
            </View>


<Text style={{color:colors.text}}>Price overview</Text>

{
  this.state.dataSource.map(item => 
    
<View style={{
  justifyContent:'space-between',
  elevation:1,
  flexDirection:'row',
  alignItems:'center',
  margin:5,
  width:300, height:30, backgroundColor:colors.component, borderRadius:6}}>
    <View style={{flexDirection:'row'}}>

<Text style={{color:colors.text, marginLeft:5}}>{moment(item.createdAt).format('YYYY-MM-DD')}</Text>
<Text style={{color:colors.yellow, marginLeft:5}}>{moment(item.createdAt).format('HH:mm:ss')}</Text>
</View>
<Text style={{color:colors.green, marginRight:5}}>€ {item.price}</Text>

<Text style={{color:colors.red, marginRight:5}}>id: {item.id}</Text>
<TouchableOpacity
onPress={()=> this.handlePriceSelect(item)}
style={{width:25, height:25, borderWidth:1, borderColor:colors.text}}>


</TouchableOpacity>


</View>
)
}
          </View>
        </View>

        {this.state.refund_created ? (
          <View style={{ marginLeft: 30, bottom: 20 }}>
            <Text
              style={{ color: colors.text, fontWeight: "bold", fontSize: 16 }}
            >
              Refund inserted in Database
            </Text>

            <Text
                  style={{ color: colors.text, fontWeight: "bold", width: 140 }}
                 
                >     {JSON.stringify(this.state.refund, null, 4)}

                </Text>

            {/* {Object.entries(this.state.refund).map(([key, val]) => (
              <View style={{ flexDirection: "row", marginVertical: 3 }}>
                <Text
                  style={{ color: colors.text, fontWeight: "bold", width: 140 }}
                  key={key} 
                >
                  {key}:
                </Text>
                <Text style={{ color: colors.green, maxWidth: 300 }}>
                  {val}
                </Text>
              </View>
            ))} */}
          </View>
        ) : null}

        {this.state.refund_created ? (
          <View style={{ marginLeft: 30, bottom:20 }}>
              <View
                style={{
                  borderRadius: 6,
                  width: 530,
                  backgroundColor: colors.component,
                  height: 40,
                  marginVertical: 5,
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems:'center'
                  //   alignItems:'center'
                }}
              >
              
            <Text style={{color:colors.text, fontSize:16}}>Send notifaction to user </Text>
            </View>
            {this.props.navigation.getParam("item").user.devices.map(item => (
              // {this.props.navigation.getParam('item').user.devices.map(item =>
              <View
                style={{
                  borderRadius: 6,
                  width: 500,
                  backgroundColor: colors.component,
                  height: 40,
                  marginVertical: 5,
                  justifyContent: "space-between",
                  flexDirection: "row"
                  //   alignItems:'center'
                }}
              >
              
                {  item.manufacturer === "Apple" ? 
              
              <AppleIcon
              style={{
                marginLeft: 10,
                color: colors.text,
                alignSelf: "center"
              }}
              /> :  <AndroidIcon
              style={{
                marginLeft: 10,
                color: colors.text,
                alignSelf: "center"
              }}
              /> 
            }

                  <Text style={{ color: colors.text, alignSelf: "center" }}>
                    {item.manufacturer}
                  </Text>
                  <Text style={{ color: colors.text, alignSelf: "center" }}>{item.deviceName}</Text>
                


<View style={{flexDirection:'row'}}>

                <TouchableOpacity
                style={{alignSelf:'center', marginHorizontal:5}}
                  onPress={() =>
                   alert(item.pushNotificationToken)
                  }
                >
                  <VignetteIcon
                    style={{
                      alignSelf: "center",
                      // marginRight: 10,
                      color: colors.yellow
                    }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                 style={{alignSelf:'center', marginHorizontal:5}}
                  onPress={() =>
                    this.sendRefundPushNotification(item.pushNotificationToken)
                  }
                >
                  <SendIcon
                    style={{
                      alignSelf: "center",
                      // marginRight: 10,
                      color: colors.green
                    }}
                  />
                </TouchableOpacity>
              </View>
              </View>
            ))}
          </View>
        ) : (
          <TouchableOpacity
            onPress={() => this.createRefund()}
            style={{
              // top: 50,
              left: 330,
              bottom: 30,
              borderRadius: 6,
              borderColor: colors.text,
              borderWidth: 1,
              // alignSelf:"center",
              backgroundColor: colors.component,
              width: 100,
              height: 40,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text style={{ color: colors.text }}>Create Refund</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  }
}
