import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";

import axios from "axios";
import moment from 'moment'

import apiEndpoint from "../../../helpers/networking/apiEndpoint";


import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

import colors from "../../../styles/colors";
import LineItemsReactDataGrid from "../../../globalComponents/lists/LineItemsReactDataGrid";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";




export default class _SteffieEditOneScreen extends Component {
    constructor(props) {
      super(props);

      const item = this.props.navigation.getParam('item')
      
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      this.state = {
          numPages: null,
          rejectionModal: false,
          //steffieId:''
          //orderNumber: 'item.orderNumber' ?
       //   invoiceNumber: 'item.invoiceNumber',
      //    orderDate: 'item.orderDate' ? moment(item.orderDate).format('YYYY MMMM DD') : null,
      //    invoiceDate: ' item.invoiceDate' ? moment(item.invoiceDate).format('YYYY MMMM DD') : null,
        //  endReturnPeriodDate: ' item.endReturnPeriodDate '? moment(item.endReturnPeriodDate).format('YYYY MMMM DD') : null,
          //webshopName:item.webshop ? item.webshop.name : null,
         // uploadDate:item.createdAt,
         // lineItemsAmount:item.lineItems ? item.lineItems.length : null,
         // lineItems:item.lineItems,
          
        }
    }
    
      componentDidMount() {
        this._getDataSource()
        this._getOneSteffie();
    
      }

     
      _getDataSource = async steffieId => {
        // const steffieId = 2//this.props.navigation.getParam("item").id;
        try {
          await axios
            .get(apiEndpoint.getSteffieBase64 + steffieId)
            .then((res) =>
              this.setState({ dataSource: res.data.invoice, isLoading: false })
            );
        } catch (err) {
          this._getDataSource();
        }
      };


      // steffieId nog instellen
      _getOneSteffie = async () => {
        // const steffieId = this.props.navigation.getParam("item").id;
        try {
          await axios
            .get(apiEndpoint.system_one_steffie_validate)
            .then((res) =>
{

  const steffie = {...res.data[0]}
  // return alert('steffie ' + steffie.id)
  this.setState({ steffie:res.data[0], steffieId:steffie.id }
    , ()=> this._getDataSource(steffie.id ))
}
            );
        } catch (err) {
          // this._getDataSource();
        }
      };


      render() {
        const { numPages } = this.state;

        const steffie = {...this.state.steffie}
    
        if (this.state.isLoading === true)
          return (
            <View
              style={{
                backgroundColor: colors.background,
                width: Dimensions.get("screen").width,
                height: Dimensions.get("screen").height,
                position: "absolute",
              }}
            />
          );
    
        return (
          <View
            style={{
              backgroundColor: colors.background,
              width: Dimensions.get("screen").width,
              height: Dimensions.get("screen").height,
              //  position: "absolute",
            }}
          >


          {/* <Text>{this.state.dataSource}</Text> */}
          {/* <Text>{JSON.stringify(this.state.steffie, null, 4)}</Text> */}

              <div style={{display:'flex', flexDirection:'row'}}>
             

           
                  <Document
            //     style={{ position: "absolute" }}
            // file={samplePDF}
            // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
            // file={`data:application/pdf;base64,${this.state.steffie}`}
            // file={`data:application/pdf;base64,${this.state.dataSource}`}
            file={`data:application/pdf;base64,${this.state.dataSource}`}
            // file={`${this.state.dataSource}`}
            onLoadSuccess={this._removeTextLayerOffset}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
          <div style={{display:'flex', flexDirection:'column', width:'50%', marginTop:20}}>


             <div style={{display:'flex', flexDirection:'row',}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'upload date'}
        value={moment(this.state.uploadDate).format('YY MMMM DD - HH:mm:ss')}
        placeholder={'uploadDate'}
        />

{/* <Text style={{color:'red'}}>

         {JSON.stringify(steffie, null, 4)}
</Text> */}
         
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'webshop'}
        value={steffie.webshop ? steffie.webshop.name : 'nope'}
        placeholder={'webshopName'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'invoiceNumber'}
        value={steffie.invoiceNumber}
        placeholder={'invoiceNumber'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'invoiceDate'}
        value={steffie.invoiceDate}
        placeholder={'invoiceDate'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'endReturnPeriodDate'}
        value={steffie.endReturnPeriodDate}
        placeholder={'endReturnPeriodDate'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'orderNumber'}
        value={steffie.orderNumber}
        placeholder={'orderNumber'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'orderDate'}
        value={steffie.orderDate}
        placeholder={'orderDate'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'discount'}
        value={steffie.discount}
        placeholder={'discount'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'deliveryCosts'}
        value={steffie.deliveryCosts}
        placeholder={'deliveryCosts'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'totalAmount'}
        value={steffie.totalAmount}
        placeholder={'totalAmount'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'lineItemsAmount'}
        value={steffie.lineItemsAmount}
        placeholder={'lineItemsAmount'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:40}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'productName'}
        value={this.state.productName}
        placeholder={'productName'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'pricePaid'}
        value={this.state.pricePaid}
        placeholder={'pricePaid'}
        />

        <TouchableOpacity style={{
           width:100, height:40, 
            backgroundColor:colors.component, justifyContent:'center', alignItems:'center'}}>
            <Text style={{color:colors.green}}>Add </Text>
        </TouchableOpacity>
           </div> 

           
           {/* {JSON.stringify(this.state.lineItems)} */}
<LineItemsReactDataGrid 
    data={steffie.lineItems}
/>
</div>
</div>

              </View>
        )
      }
}

