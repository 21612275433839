import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import axios from "axios";

import colors from "../../../styles/colors";
import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import { Document, Page, pdfjs } from "react-pdf";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import InvoiceComponent from "../components/InvoiceComponent";

export default class InvoiceValidationScreen extends React.Component {
  constructor(props) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount(){
      this._getDataSource()
      this.getInvoice()
  }




  _getDataSource = async () => {
    const invoiceId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getInvoice + invoiceId)
        .then((res) =>
          this.setState({ dataSource: res.data })
        );
    } catch (err) {
   alert('err')
    }
  };
  getInvoice = async () => {
    const invoiceId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getInvoiceBase64 + invoiceId)
        .then((res) =>
          this.setState({ invoice: res.data.invoice, isLoading: false })
        );
    } catch (err) {
        console.log('Wt is er aan de hand? ', err)
     alert('error')
    }
  };

 
  handleInvoiceToAccepted = async (i, value) => {
    let data = {

      queStatus: this.state.queStatus,
      emailComplaints: this.state.emailComplaints,
      invoiceDate: this.state.invoiceDate,
      paymentAmount: this.state.paymentAmount,
      deliveryCosts: this.state.deliveryCosts,
      discount: this.state.discount,
      webshopId: this.state.webshopId,
      queueId:4,
      //    returnPeriodDays: this.state.twitter
    };

    const invoiceId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.putInvoice_lineItems_accepted + invoiceId, data)
          .then((res) => {

            alert('jup')
            console.log("uh huh huh uh u : => ", res);
            // alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };
  render() {
    const { numPages } = this.state;
    
      if (this.state.isLoading === true) {
        return (
          <View
            style={{
              //flex:1,
              height: Dimensions.get("window").height,
              backgroundColor: colors.background,
            }}
          >
            <ActivityIndicator />
          </View>
        );
      }
      return (
        <View
        style={{
          backgroundColor: colors.background,
          height: Dimensions.get("screen").height,
          width: Dimensions.get("screen").width,
          position: "absolute"
        }}
      >

        <BobsHeader
          handleBack={() => this.props.navigation.navigate("InvoicesToValidate")}
          title={"Invoices"}
        />

        <View style={{flexDirection:'row'}}>
           
    {/* <Text style={{color:colors.text}}>{JSON.stringify(this.state.dataSource, null, 4)}</Text> */}
      
      <View style={{marginHorizontal:20}}>
          
         <InvoiceComponent
          item={this.state.dataSource}
          />


<TouchableOpacity
onPress={()=> this.handleInvoiceToAccepted()}
style={{
    marginVertical:40,
              backgroundColor:colors.component, 
              borderWidth:1,
              borderColor:colors.text,
              width:200,
              height:40,
              justifyContent:'center',
              alignItems:'center',
              borderRadius:6
              
              }}>
                  <Text style={{color:colors.text}}>
                      Invoice is good
                  </Text>

          </TouchableOpacity>

          </View>

         <View style={{marginHorizontal:60}}/>
          <Document
            // file={samplePDF}
            // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
            file={`data:application/pdf;base64,${this.state.invoice}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
            >
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
          </Document>
                </View>
        </View>
      );
    }
  }

