import React, { Component } from "react";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

import PriceInputModal from "../components/PriceInputModal";
import TableMonitorComponent from "../components/TableMonitorComponent";

class ProductsMonitorScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      users: [],
      invoiceQue: [],
      isLoading: true,
      priceInput: "",
      priceChangeModal: false,
      item: "",
      selected: ""
    };
  }

  componentDidMount() {
    this._dataSource();
    // this.getProductsIncludeWebshop();
  }

  _dataSource = async () => {
    try {
      await axios
        .get(apiEndpoint.productsMonitor)
        .then(res => {
          console.log("sdfdsf" + JSON.stringify(res.data));
          this.setState({ dataSource: res.data, isLoading: false });
        });
    } catch (err) {
      this._dataSource();
      //  alert('err: ' + err)
      console.log(err);
    }
  };

  // getProductsIncludeWebshop = async () => {
 

  //   try {
  //     await axios
  //       .get(apiEndpoint.getProductsIncludeWebshop, {
  //         headers: auth_header
  //       })
  //       .then(res => {
  //         console.log("sdfdsf" + JSON.stringify(res.data));
  //         this.setState({ dataSource: res.data, isLoading: false });
  //       });
  //   } catch (err) {
  //     //  alert('err: ' + err)
  //     this.getProductsIncludeWebshop();

  //     console.log(err);
  //   }
  // };

  // localhost:3000/invoices/admin/orders/processing

  handleNavigation = (route, item) => {
    this.props.navigation.navigate(route, {
      item: item
    });

    console.log("Item: =>" + console.table(item));
  };

  updateInvoiceToScraping = async (route, item) => {
   

    let data = {
      queStatus: "SCRAPING"
    };
    {
      try {
        await axios
          .put(apiEndpoint.invoiceToScraping + item.id, data)
          .then(res => this.handleNavigation(route, item));
        //  console.log('Dit zijn de labels: ' + res.data);
      } catch (err) {
        //  alert('err: ' + err);
        console.log("err: steffiescherm" + err);
      }
    }
  };

  handlePriceInput = text => {
    this.setState({
      priceInput: text
    });
  };

  handleOnClick = (item, type) => {
    if (type === "url") {
      this.openPriceMonitor(item);
    } else if (type === "users") {
      this.props.navigation.navigate("MonitorProductSteffies", {
        item: item
      });
    }
  };

  openPriceMonitor = item => {
    console.log("Wat is item?? => :", item);
    window.open(item.productWebsiteUrl, "_blank");
    //    window.open("https://www.coolblue.nl/product/810829/iiyama-prolite-e2783qsu-b1.html", "_blank")
    const priceChangeModal = this.state.priceChangeModal;
    this.setState({
      priceChangeModal: !priceChangeModal,
      item: item
    });
  };

  handlePriceChangeModal = item => {
    const priceChangeModal = this.state.priceChangeModal;
    this.setState({
      priceChangeModal: !priceChangeModal,
      item: item
    });
  };

  priceChangeModalSubmit = () => {
    const currentPrice = this.state.dataSource.product;

    if (this.state.priceInput) console.log("this.state.item", this.state.item);
    this.createPrice();
    this.handlePriceChangeModal();
  };

  priceChangeModalSamePrice = async () => {
    const currentDate = new Date();

    const productId = this.state.selected.id;

    console.log("product id => ...".productId);
    let data = {
      lastMonitored: 'currentDate'
      // color:'pink'
    };

    
      try {
        await axios
          .put(apiEndpoint.putProductSamePrice + productId, data)
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            this.setState({
              webshopId: res.data,
              snackbarComponent: true
            });
            this._dataSource();
            this.handlePriceChangeModal();
            //  alert("gelukt: ", JSON.stringify(res));
          });
      } catch (err) {
     

          alert("jammer joh");
      
    }
  }

  createPrice = async () => {
   

    const currentDate = new Date();

    const productId = this.state.selected.id;

    console.log("product id => ...".productId);

    let data = {
      productId: productId,
      newPrice: this.state.priceInput,
      date: currentDate

      // color:'pink'
    };

    
      try {
        await axios
          .post(apiEndpoint.system_lowerPrice, data)
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            this.setState({
              webshopId: res.data,
              snackbarComponent: true
            });
            this._dataSource();
            //  alert("gelukt: ", JSON.stringify(res));
          });
      } catch (err) {
      //  this.createPrice();

        //  alert("jammer joh");
      
    }
  };

  render() {
    if (this.state.isLoading === true)
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("screen").height,
            width: Dimensions.get("screen").width,

            backgroundColor: colors.background,
            position: "absolute"
          }}
        ></View>
      );

    return (
      // <View
      //   style={{
      //     flex: 1,
      //     backgroundColor: colors.background
      //     //  width: Dimensions.get("screen").width,
      //     //  height: Dimensions.get("screen").height,
      //     //  position: "absolute"
      //   }}
      // >
      <View
      style={{
        backgroundColor: colors.background,
        width: Dimensions.get("screen").width,
        height: Dimensions.get("screen").height,
        position: "absolute"
      }}
    >
        <BobsHeader
          handleBack={() => this.props.navigation.navigate("Dashboard")}
          title={"ProductMonitorScreen"}
        />

        <TableMonitorComponent
          arr={this.state.dataSource}
          // onClick={()=> alert('hoi')}
          onClick={(row, index, type) => {
            this.handleOnClick(row, type);
            this.setState({
              selected: row,
              selectedIndex: index
            });
          }}
          handlePriceChangeModal={row => this.handlePriceChangeModal(row)}
        />

        <PriceInputModal
          selected={this.state.selected ? this.state.selected : []}
          priceChangeModalSamePrice={()=> this.priceChangeModalSamePrice()}
          // priceChangeModal={this.state.priceChangeModal}
          priceChangeModalSubmit={() => this.priceChangeModalSubmit()}
          handlePriceInput={text => this.handlePriceInput(text)}
          handlePriceChangeModal={() => this.handlePriceChangeModal()}
          priceChangeModal={this.state.priceChangeModal}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    // width: "100%",
    height: Dimensions.get("window").height,
    // headers:'100%',
    backgroundColor: colors.background
  }
});

export default ProductsMonitorScreen;
