import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import {
  ActivityIndicator,
  View,
  Dimensions,
  Text,
  TouchableOpacity
} from "react-native";

import { pdfjs } from "react-pdf";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import axios from "axios";

import colors from "../../../styles/colors";
import TextInputComponent from "../components/TextInputComponent";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import DoneIcon from '@material-ui/icons/Done';
import SearchComponent from "../../../globalComponents/search/SearchComponent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class PDFScreen extends Component {
  constructor(props) {
    super(props);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      numPages: null,
      isLoading: true,
      webshopId: "..."
    };
  }

  componentDidMount() {
    this._getDataSource();
    this.getWebshops();
  }

  onDocumentLoadSuccess = document => {
    const { numPages } = document;
    this.setState({
      numPages
    });
  };

  _getDataSource = async () => {
    const invoiceId = this.props.navigation.getParam("item").id;

  
    try {
      await axios
        .get(apiEndpoint.getInvoiceBase64 + invoiceId)
        .then(res =>
          this.setState({ dataSource: res.data.invoice, isLoading: false })
        );
    } catch (err) {
      this._getDataSource();
    }
  };
  getWebshops = async () => {
    // const invoiceId = this.props.navigation.getParam('item').id
 
    try {
      await axios
        .get(apiEndpoint.getWebshops)
        .then(res => this.setState({ webshops: res.data, isLoading: false }));
    } catch (err) {
      this.getWebshops();
    }
  };

  handleSearchSubmit = value => {
    this.setState({ webshopId: value.name });
    this.createWebshop(value);
    console.log(value);
  };

  handleSelected = value => {
    if (!value) {
      this.setState({ webshopId: "<.?.>" });
    } else {
      this.setState({
        webshopId: value.id
      });
    }
  };

  createWebshop = async x => {

    const value = x.name;
    let data = {
      name: value,
      productNumber: this.state.phone,
      email: this.state.email,
      emailComplaints: this.state.emailComplaints,
      whatsapp: this.state.whatsapp,
      instagram: this.state.instagram,
      facebook: this.state.facebook,
      twitter: this.state.twitter,
      youtube: this.state.youtube,
      linkedIn: this.state.linkedIn,
      pinterest: this.state.pinterest,
      snapchat: this.state.snapchat,
      owner: this.state.owner,
      kvk: this.state.kvk,
      contactPage: this.state.contactPage,
      refundDurationDays: this.state.refundDurationDays
      // color:'pink'
    };

    {
      try {
        await axios
          .post(apiEndpoint.postWebshop, data)
          .then(res => {
            console.log("res: " + JSON.stringify(res));
            this.setState({
              webshopId: res.data,
              snackbarComponent: true
            });

            //  alert("gelukt: ", JSON.stringify(res));
          });
      } catch (err) {
        this.createWebshop(x);
        //  alert("jammer joh");
      }
    }
  };

  render() {
    const { numPages } = this.state;

    if (this.state.isLoading === true)
      return (
        <View
          style={{
            backgroundColor: colors.background,
            width: Dimensions.get("screen").width,
            height: Dimensions.get("screen").height,
            position: "absolute"
          }}
        />
      );

    return (
      <View
        style={{
          backgroundColor: colors.background,
          width: Dimensions.get("screen").width,
          height: Dimensions.get("screen").height,
          position: "absolute"
        }}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            zIndex: 1
            //  left:30
          }}
          onPress={() => this.props.navigation.navigate("Invoices")}
        >
          <ChevronLeftIcon
            style={{
              color: colors.text,
              fontSize: 50
            }}
          />

          <View style={{ right: 10 }}>
            <Text
              style={{
                alignSelf: "center",
                color: colors.text,
                fontSize: 20
              }}
            >
              Terug
            </Text>
            <Text
              style={{ color: colors.text, alignSelf: "center", fontSize: 10 }}
            >
              ctrl + b
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            //  flexDirection:'row',
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            left: 130,
            top: 10
          }}
          onPress={() => alert("afgekeurd")}
        >
          <ThumbDownIcon
            style={{
              color: colors.red,
              fontSize: 30
            }}
          />

          {/* <View style={{right:10}}>

                <Text style={{
                    alignSelf:'center',
                    color:colors.text, fontSize:20}}>
                    Reject
                </Text>
               
                    </View> */}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            //  flexDirection:'row',
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            left: 190,
            top: 7
          }}
          onPress={() => this.props.navigation.navigate('PDF', 
          
          {item:this.props.navigation.getParam('item')})}
        >
          <MoreVertIcon
            style={{
              color: colors.text,
              fontSize: 40
            }}
          />

          {/* <View style={{right:10}}>

                <Text style={{
                    alignSelf:'center',
                    color:colors.text, fontSize:20}}>
                    Reject
                </Text>
               
                    </View> */}
        </TouchableOpacity>

        <View
          style={{
            position: "absolute",
            top: 60,
            zIndex: 1,
            left: 20

            // width:100
          }}
        >
          <SearchComponent
            arr={this.state.webshops}
            handleSubmit={value => this.handleSearchSubmit(value)}
            handleSelected={value => this.handleSelected(value)}
          />

          <TextInputComponent label={"Factuurnummer"} />
          <TextInputComponent label={"Ordernummer"} />
          <TextInputComponent label={"Factuurdatum"} />
          <TextInputComponent label={"Orderdatum"} />
          <TextInputComponent label={"Klantnummer"} />
        </View>

        <Text style={{ fontSize: 20, color: colors.white }}>
          {/* dataSource {JSON.stringify(this.state.dataSource)} */}
        </Text>
        <View style={{ alignItems: "center" }}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                backgroundColor: colors.background,
                justifyContent: "center",
                height: 800,
                width: 180
              }}
            ></View>
            <View
              style={{
                backgroundColor: colors.component,
                justifyContent: "center",
                height: 800,
                width: 180
              }}
            >
              <View
                style={{
                  backgroundColor: colors.background,
                  height: 120,
                  width: 120,
                  borderRadius: 120 / 2,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ color: colors.text, fontSize: 20 }}>Tik me</Text>
              </View>
            </View>

            <Document
              // file={samplePDF}
              // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
              file={`data:application/pdf;base64,${this.state.dataSource}`}
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
            <View
              style={{
                backgroundColor: colors.component,
                justifyContent: "center",
                height: 800,
                width: 180
              }}
            >
              <View
                style={{
                  backgroundColor: colors.background,
                  height: 120,
                  width: 120,
                  borderRadius: 120 / 2,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ color: colors.text, fontSize: 20 }}>Tik me</Text>
              </View>
            </View>
            <View
              style={{
                backgroundColor: colors.background,
                justifyContent: "center",
                height: 800,
                width: 180
              }}
            >
              <TextInputComponent label={"Huawei FreeBuds 3 Zwart"} />
              <TextInputComponent label={"x"} />
              <TextInputComponent label={"d"} />
            </View>
          </View>
        </View>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            //  flexDirection:'row',
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            right: 50,
            bottom:260,
            borderColor:colors.text,
            width:170,
            height:35,
            borderWidth:1,
            borderRadius:6,
            justifyContent:'center',
            alignItems:'center'
           // top: 10
          }}
          onPress={() => alert("afgekeurd")}
        >
          <DoneIcon
            style={{
              position:'absolute',
              color: colors.green,
              fontSize: 30,
              right:10
            }}
          />

          <Text style={{color:colors.text}}>Finish</Text>

          {/* <View style={{right:10}}>

                <Text style={{
                    alignSelf:'center',
                    color:colors.text, fontSize:20}}>
                    Reject
                </Text>
               
                    </View> */}
        </TouchableOpacity>

      </View>
    );
  }
}
