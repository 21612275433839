 import React from 'react'
 import {View, TouchableOpacity, Text,Image } from 'react-native'
 import CancelIcon from '@material-ui/icons/Cancel';
import colors from '../../../styles/colors'

 export default class InvoiceComponent extends React.Component {
state = {
    checkProduct:false,
    checkLineItem:false
}

handleCheck = (type) => {
    const check = this.state[type]
    this.setState({
        [type]:!check
    })

}

     render(){
         return(

            <View>

             <View style={{
                 borderRadius:6,
                 width:350, height:150, backgroundColor:colors.component
                 
                }}>



                {/* <Text>{JSON.stringify(this.props.item)}</Text> */}
                </View>
                {this.props.item ? this.props.item.lineItems.map(item=>

<View style={{flexDirection:'row', alignItems:'center'}}>

<View style={{backgroundColor:colors.component, left:20, 
    borderRadius:6,
    marginVertical:5,
    height:50,
    width:330,}}>
                <Text style={{color:colors.text }}>s{item.product.name}</Text>
                    
                </View>
                <View style={{left:30, flexDirection:'row'}}>

                <TouchableOpacity
                onPress={()=>this.handleCheck('checkLineItem')}

                style={{borderWidth:1, 
              
                    borderRadius:6,
                    marginHorizontal:3,
                    justifyContent:'center',
                    alignItems:'center',
                    borderColor:colors.text, width:30, height:30}}>

{this.state.checkLineItem ? 
                        <CancelIcon style={{fontSize:20, color:colors.red}}/>
                    :null}
                </TouchableOpacity>
                <TouchableOpacity 
                   onPress={()=>this.handleCheck('checkProduct')}
                style={{borderWidth:1, 
                    borderRadius:6,
                    marginHorizontal:3,
                    justifyContent:'center',
                    alignItems:'center',
                    borderColor:colors.text, width:30, height:30}}>


{this.state.checkProduct ? 
                        <CancelIcon style={{fontSize:20, color:colors.red}}/>
                    :null}

                </TouchableOpacity>
                </View>
                </View>
)
              :null  }
             </View>
         )
     }
 }