import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";

import axios from "axios";
import moment from 'moment'

import apiEndpoint from "../../../helpers/networking/apiEndpoint";


import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

import colors from "../../../styles/colors";
import LineItemsReactDataGrid from "../../../globalComponents/lists/LineItemsReactDataGrid";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";




export default class _SteffieEditScreen extends Component {
    constructor(props) {
      super(props);

      const item = this.props.navigation.getParam('item')
      
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      this.state = {
          numPages: null,
          rejectionModal: false,
          orderNumber: item.orderNumber,
          invoiceNumber: item.invoiceNumber,
          orderDate: item.orderDate ? moment(item.orderDate).format('YYYY MMMM DD') : null,
          invoiceDate:  item.invoiceDate ? moment(item.invoiceDate).format('YYYY MMMM DD') : null,
          endReturnPeriodDate:  item.endReturnPeriodDate ? moment(item.endReturnPeriodDate).format('YYYY MMMM DD') : null,
          webshopName:item.webshop ? item.webshop.name : null,
          uploadDate:item.createdAt,
          lineItemsAmount:item.lineItems ? item.lineItems.length : null,
          lineItems:item.lineItems
        }
    }
    
      componentDidMount() {
        this._getDataSource();
    
      }

      _getDataSource = async () => {
        const steffieId = this.props.navigation.getParam("item").id;
        try {
          await axios
            .get(apiEndpoint.getSteffieBase64 + steffieId)
            .then((res) =>
              this.setState({ dataSource: res.data.invoice, isLoading: false })
            );
        } catch (err) {
          this._getDataSource();
        }
      };


      render() {
        const { numPages } = this.state;

        
    
        if (this.state.isLoading === true)
          return (
            <View
              style={{
                backgroundColor: colors.background,
                width: Dimensions.get("screen").width,
                height: Dimensions.get("screen").height,
                position: "absolute",
              }}
            />
          );
    
        return (
          <View
            style={{
              backgroundColor: colors.background,
              width: Dimensions.get("screen").width,
              height: Dimensions.get("screen").height,
              //  position: "absolute",
            }}
          >

              <div style={{display:'flex', flexDirection:'row'}}>
             
<Text style={{color:'white', fontSize:20}}>
{this.props.navigation.getParam('item').endReturnPeriodDate}
</Text>
           
                  <Document
            //     style={{ position: "absolute" }}
            // file={samplePDF}
            // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
            file={`data:application/pdf;base64,${this.state.dataSource}`}
            onLoadSuccess={this._removeTextLayerOffset}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
          <div style={{display:'flex', flexDirection:'column', width:'50%', marginTop:20}}>


             <div style={{display:'flex', flexDirection:'row',}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'upload date'}
        value={moment(this.state.uploadDate).format('YY MMMM DD - HH:mm:ss')}
        placeholder={'uploadDate'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'webshop'}
        value={this.state.webshopName}
        placeholder={'webshopName'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'invoiceNumber'}
        value={this.state.invoiceNumber}
        placeholder={'invoiceNumber'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'invoiceDate'}
        value={this.state.invoiceDate}
        placeholder={'invoiceDate'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'endReturnPeriodDate'}
        value={this.state.endReturnPeriodDate}
        placeholder={'endReturnPeriodDate'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'orderNumber'}
        value={this.state.orderNumber}
        placeholder={'orderNumber'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'orderDate'}
        value={this.state.orderDate}
        placeholder={'orderDate'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'discount'}
        value={this.state.discount}
        placeholder={'discount'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'deliveryCosts'}
        value={this.state.deliveryCosts}
        placeholder={'deliveryCosts'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:20}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'totalAmount'}
        value={this.state.totalAmount}
        placeholder={'totalAmount'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'lineItemsAmount'}
        value={this.state.lineItemsAmount}
        placeholder={'lineItemsAmount'}
        />
           </div> 
             <div style={{display:'flex', flexDirection:'row', marginTop:40}}>
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'productName'}
        value={this.state.productName}
        placeholder={'productName'}
        />
        <TextInputComponent
        onChangeText={text => alert(text)}
        label={'pricePaid'}
        value={this.state.pricePaid}
        placeholder={'pricePaid'}
        />

        <TouchableOpacity style={{
           width:100, height:40, 
            backgroundColor:colors.component, justifyContent:'center', alignItems:'center'}}>
            <Text style={{color:colors.green}}>Add </Text>
        </TouchableOpacity>
           </div> 

           
           {/* {JSON.stringify(this.state.lineItems)} */}
<LineItemsReactDataGrid 
    data={this.state.lineItems}
/>
</div>
</div>

        <Text style={{color:'blue'}}>{JSON.stringify(this.props.navigation.getParam('item'), null, 4)}</Text>

              </View>
        )
      }
}

