import React from "react";
import { View, Text, TextInput, StyleSheet, Dimensions, TouchableOpacity } from "react-native";
import colors from "../../../styles/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';


export default class LineItemComponent extends React.Component {
  render() {
    const item = this.props.item

    return (

        <View>
     
     <TouchableOpacity
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                //  flex: 1,
                  marginVertical: 4,
                  marginHorizontal: 5,
                  width:300
                }}
              >
                <Text style={{ color: colors.yellow, width: 20 }}>
                  {item.id}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{ color: colors.green, width: 250 }}
                >
                  {" "}
                  {item.name.length < 31
                    ? `${item.name}`
                    : `${item.name.substring(0, 28)}...`}
                </Text>
                <Text
                  style={{
                    color: colors.red,
                  //  alignSelf: "flex-end",
                    width: 100,
                  }}
                >
                  € {item.price}
                </Text>
              </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    // marginLeft: 10,
    //   width: Dimensions.get("window").width - 120,
    alignSelf: "center",
    color: colors.green,
    paddingLeft: 30,
    zIndex: 1,
    width: 100
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center"
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: colors.white,
    marginHorizontal: 10,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    // bottom: 40,
    width: 350,
    //alignSelf:'center',

    backgroundColor: colors.component
  },
  textInputContainerPrice: {
    flexDirection: "row",
    marginHorizontal: 0,
    borderWidth: 1,
    borderColor: colors.white,
    marginHorizontal: 10,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    // bottom: 40,
    width: 100,
    //alignSelf:'center',

    backgroundColor: colors.component
  },
  text: {
    position: "absolute",
    //  fontWeight: "bold",
    bottom: 42,
    color: colors.text,
    fontSize: 14,
    left: 5
  }
});
