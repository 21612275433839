import React, { Component } from "react";
import {
Text,
  StyleSheet,

  View,
  Dimensions,

  ActivityIndicator,

} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import TableComponent from "../components/TableComponent";


import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

export default class InvoicesToScrapeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      dataSource:[],
      invoiceQue: [],
      isLoading: true
    };
  }

  componentDidMount() {
    this._getDataSource();
  }

  _getDataSource = async () => {
    try {
      await axios
        .get(apiEndpoint.getInvoicesToScrape)
        .then(res => this.setState({ dataSource: res.data, isLoading: false }));
    } catch (err) {
     alert('Niet gelukt', err)
    }
  };

 

  // localhost:3000/invoices/admin/orders/processing

  handleNavigation = async (route, item) => {

 

    this.props.navigation.navigate(route, {
      item: item,
   //   header:auth_header
    });

    console.log("Item: =>" + console.table(item));
  };

  updateInvoiceToScraping = async (route, item) => {
    let data = {
      queStatus: "SCRAPING"
    };
    {
      try {
        await axios
          .put(apiEndpoint.invoiceToScraping + item.id, data)
          .then(res => this.handleNavigation(route, item));
        //  console.log('Dit zijn de labels: ' + res.data);
      } catch (err) {
        //  alert('err: ' + err);
        console.log("err: steffiescherm" + err);
      }
    }
  };

  handleOnClick = row => {
 //Linking.openURL('http://localhost:3000/invoices/invoiceAsPdf/1')
    
    //this.getDownload(row.id, row.userId)
    this.props.navigation.navigate("InvoiceScrape", { item: row });
    console.log('userId: ' , row.userId)
    console.log('row: ' , row)
  //  alert('row: ' , row)


  };


  getDownload = async (invoiceId, userId) => {
  
 
    try {
      await axios
        .get(apiEndpoint.openInvoice + invoiceId)
        .then(res => {
          console.log("sdfdsf" + JSON.stringify(res.data));
          this.setState({ invoices: res.data, isLoading: false });
        });
    } catch (err) {
     
      //  alert('err: ' + err)
      console.log(err);
    }
  };
  
  // handleOnClick = row => {
  //   this.props.navigation.navigate("PDF", { item: row });
  // };

  render() {
    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background
          }}
        >
          <ActivityIndicator />
        </View>
      );
    } else {
      return (
        <View
          style={{
           flex:1,
            backgroundColor: colors.background,
            height: Dimensions.get("window").height,
            width: Dimensions.get("window").width,
           // position: "absolute"
          }}
        >

          <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Invoices"}
          />


          <View>
            <TableComponent
              onClick={row => this.handleOnClick(row)}
              arr={this.state.dataSource}
            />

          </View>
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    // width: "100%",
    height: Dimensions.get("window").height,
    // headers:'100%',
    backgroundColor: colors.background
  }
});


