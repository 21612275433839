import React from 'react'
import {View, Text, TouchableOpacity,Dimensions} from 'react-native'
import colors from '../../styles/colors'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import apiEndpoint from '../../helpers/networking/apiEndpoint';


export default class BobsHeader extends React.Component {
    render() {
        return(
            <View style={{
                
             //   marginHorizontal:5,
               // position:'absolute', 
               borderRadius:6,
                width:Dimensions.get('window').width, 
                height:60, 
                backgroundColor:colors.component,
                marginTop:10,
                marginBottom:10,
                flexDirection:'row',
                justifyContent:'center',
             //   alignSelf:'center'

            
            }}>
                
                <TouchableOpacity
                style={{
                    justifyContent:'center',
                    alignSelf:'center',
                    flexDirection:'row',
                    alignItems:'center',
                    position:'absolute', left:30}}
                onPress={()=> this.props.handleBack()}
                >
                    <ChevronLeftIcon style={{
                   
                        color:colors.text, 
                        fontSize:50,
                         }}/>


<View style={{right:10}}>

                <Text style={{
                    alignSelf:'center',
                    color:colors.text, fontSize:20}}>
                    Terug
                </Text>
                <Text style={{color:colors.text,  alignSelf:'center', fontSize:10,}}>
                    ctrl + b
                </Text>
                    </View>
                </TouchableOpacity>

                <Text style={{color:colors.text, fontSize:30, alignSelf:'center'}}>
             {this.props.title}
                </Text>

            </View>
        )
    }
}