import React from "react";
import { Text } from "react-native";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import colors from "../../../styles/colors";
export default class TableComponent extends React.Component {
  render() {
    return (
      <TableContainer
        style={{ backgroundColor: colors.component }}
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: colors.component }}>

              {[
                
                {name:' User Id'},
                {name:' Username'},
                {name:' Webshop'},
                {name:' Ordernumber'},
                {name:' Invoicenumber'},
                {name:' Discount'},
                ].map(item => 
                
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                  >
                  {item.name}
                </Text>
              </TableCell>
                  )}
           
          
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.arr.map(row => (
              // <TableRow key={row.name}>
              <TableRow onClick={() => this.props.onClick(row)} key={row.id}>
                <TableCell
                  style={{ color: colors.yellow }}
                  component="th"
                  scope="row"
                >
                  {row.id}
                </TableCell>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {row.user.username}
                </TableCell>
                <TableCell style={{ color: colors.text }} align="right">
                  {row.webshop ? row.webshop.name: null}
                </TableCell>

                <TableCell style={{ color: colors.text }} align="right">
                  {row.orderNumber}
                </TableCell>
                <TableCell style={{ color: colors.text }} align="right">
                  {row.invoiceNumber}
                </TableCell>
                <TableCell style={{ color: colors.text }} align="right">
                  {row.discount}
                </TableCell>
            
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
