import React from "react";
import { Text } from "react-native";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import colors from "../../../styles/colors";
export default class TableComponent extends React.Component {
  render() {
    return (
      <TableContainer
        style={{ backgroundColor: colors.component }}
        component={Paper}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: colors.component }}>
              <TableCell style={{ color: colors.text }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  id
                </Text>
              </TableCell>
            
              <TableCell style={{ color: colors.text }} align="left">
                Eail
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  Username
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
                Roles
              </TableCell>
              <TableCell style={{ color: colors.text }} align="left">
              State
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.arr.map(row => (
              // <TableRow key={row.name}>
              <TableRow 
              
              onClick={()=> this.props.handleClick(row)}
              key={row.id}>
                <TableCell
                  style={{ color: colors.text }}
                  component="th"
                  scope="row"
                >
                  {row.id}
                </TableCell>
             
                <TableCell style={{ color: colors.text }} align="left">
                  {row.email}
                </TableCell>

                <TableCell style={{ color: colors.text }} align="left">
                  {row.username}
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                  {row.roles.map((item, key) =>
                    item.name === "admin" ? (
                      <Text 
                      key={key}
                      style={{ color: "red", fontWeight: "bold" }}>
                        {item.name}{" "}
                      </Text>
                    ) : (
                      <Text style={{ color: colors.text }}>{item.name} </Text>
                    )
                  )}
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                {row.state ? row.state.name : 'error'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
