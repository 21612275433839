const mode ='production'
// const mode ='development'

// const mode="bobby_proof"
// const mode = "steffie-app.nl"

let url ;

// if(mode ==='production') {
//   const ip = 'api.steffie.app'
//    url = `https://${ip}`
// }
if(mode ==='bobby_proof') {
  const ip = 'bobbyproof.com'
  const port = 3002
  //  url = `https://${ip}:${port}`
  //  url = `https://${ip}:${port}`
   url = `https://${ip}`
}


if(mode ==='development'){
  const ip = 'localhost'
  
  // const port = 1337
  const port = 3002
  url = `http://${ip}:${port}`
}
if(mode ==='steffie-app.nl'){
  const ip = 'steffie-app.nl'
  
  // const port = 1337
  const port = 3002
  url = `https://${ip}`
}
if(mode ==='production'){
  const ip = 'steffie-app.nl'
  
  // const port = 1337
  const port = 3002
  url = `https://${ip}`
}



const apiEndpoint = {
  mode,

  deleteLineItemsNewLineItems:url + '/steffie_upload/deleteLineItems_scrapefunction/',
  just_one_steffie_by_webshopId:url + '/admin_steffies/just_one_steffie_by_webshopId/',
  update_all_unknown_steffies:url + '/steffie_upload/again/',
  updateUser:url + '/admin_users/',


  productFindOrCreate: url + '/admin_products/findOrCreate/',
  admin_messageToUser_unread:url + '/admin_messageToUser/unread/',
  includeWebshopPrices:url + '/admin_products/includeWebshopPrices',
  unkown_steffies:url + '/admin_steffies/unkown/',
  webshopDetected_steffies:url + '/admin_steffies/webshop_detected/',
  not_an_invoice:url + '/admin_steffies/not_an_invoice/',
  not_text_based:url + '/admin_steffies/not_text_based/',
  


  openingHours: url + '/admin_openingHours/',
  system_one_steffie_validate: url + '/system_one/steffie_validate',
  getOneSteffie: url + '/admin_steffies/',
  getAllSteffies: url + '/admin_steffies/',
  getCurrentSteffies: url + '/admin_steffies/current/',
  getScrape_error: url + '/admin_steffies/Scrape_error/',

  step_1_webshop_detected: url + '/admin_steffies/step_1_webshop_detected/',

  system_lowerPrice: url+'/system_lowerPrice/',
  createUniqueIdentifier: url+'/system_endpoint/createUniqueIdentifier/',
  notReadable: url+'/scrape_invoices/notReadable/',
  noInvoice: url+'/scrape_invoices/noInvoice/',
  multipleLineItems: url+'/scrape_invoices/multiple_lineItems/',
  invoiceTooOld: url+'/scrape_invoices/invoiceTooOld/',
  findOrCreateWebshop: url+'/scrape_invoices/findOrCreateWebshop/',
  findOrCreateWebshopStartDataExtraction: url+'/scrape_invoices/findOrCreateWebshopStartDataExtraction/',
  createLineItemCreateProductIfNotExist: url+'/scrape_invoices/createLineItemCreateProductIfNotExist/',


  getSteffiesToProcess: url + '/admin_steffies/steffiesToProcess/',
  putProductSamePrice: url + '/admin_products/samePrice/',
  getLineItems: url + '/admin_lineItems/',
  deleteLineItem: url + '/admin_lineItems/',
  getPrices: url + '/admin_prices/products/',
  getSteffiePdfDownload: url + '/admin_steffies/downloadPdf/',
  getMessageToWebshopTemplates: url + '/admin_messageToWebshopTemplates/',
  




  testRoute:  url + '/invoices/testbobtest/', // ProductScreen
  getMessagesToUsers:  url + '/admin_messages/', // ProductScreen
  sendPushNotification:  url + '/admin_pushnotification/', // ProductScreen
  postMessageToUser:  url + '/admin_messages/', // ProductScreen
  postMessageToUserInvoiceRejection:  url + '/admin_messages/invoiceRejection/', // ProductScreen
  
  getDonations:  url + '/admin_donations/', // ProductScreen


  createImage: url + '/admin_images/',

  getProductTypes: url + '/admin_types/', // ProductScreen
  getProductWarranties: url + '/admin_warranties/', // ProductScreen


  //Invoice
  getInvoiceBase64:  url + '/admin_invoices/base64/', // ProductScreen
  getSteffieBase64:  url + '/admin_steffies/base64/', // ProductScreen

  getInvoiceLineItems:  url + '/admin_invoices/invoiceLineItems/', // ProductScreen
  getSteffieLineItems:  url + '/admin_steffies/steffieLineItems/', // ProductScreen


  
  getInvoicesInQueue: url + '/admin_invoices/processing', // ProductScreen
  invoiceToScraping: url + '/invoices/test/', // ProductScreen


  putInvoice: url + '/admin_invoices/', // ProductScreen
  putSteffie: url + '/admin_steffies/', // ProductScreen
  
  
  
  getInvoices: url + '/admin_invoices/', // ProductScreen


  //putReadyForValidation: url + '/admin_invoices/ready_for_validation/', // ProductScreen
  putReadyForValidation: url + '/admin_steffies/ready_for_validation/', // ProductScreen


  //putInvoice_lineItems_accepted: url + '/admin_invoices/invoice_lineItems_accepted/', // ProductScreen
  putSteffie_lineItems_accepted: url + '/admin_steffies/steffie_lineItems_accepted/', // ProductScreen
 


  postLineItems:  url + '/admin_lineItems/', // ProductScreen
  postLineItem:  url + '/admin_lineItems/single/', // ProductScreen


    

    getUsers:  url + '/admin_users/', // ProductScreen
  
  postRefund: url +'/admin_refunds/',
  getRefunds:  url + '/admin_refunds/', // ProductScreen
  getRefundsAvailable:  url + '/admin_refunds/refundsAvailable/', // ProductScreen
  getRefundsClaimed:  url + '/admin_refunds/refundsClaimed/', // ProductScreen
  getRefundSuccess:  url + '/admin_refunds/refundSuccess/', // ProductScreen
  getRefundError:  url + '/admin_refunds/refundError/', // ProductScreen

    getWebshopProducts: url + '/admin_products/webshop/', // ProductScreen
    getProducts: url + '/admin_products/', // ProductScreen

    postProductWithPrice: url + '/admin_products/withprice/', // ProductScreen
    postProductScraping: url + '/admin_products/scraping/', // ProductScreen
    postProduct: url + '/admin_products/', // ProductScreen
    putProduct: url + '/admin_products/', // ProductScreen
    updateProductImage: url + '/admin_products/image/', // ProductScreen
    
    
    productsMonitor: url + '/admin_products/productsMonitor/', // ProductScreen

    createPrice: url + '/admin_prices/', // ProductScreen
    

  
  

    getDashboard: url + '/admin_panel/', // ProductScreen
    getTablet: url + '/admin_panel/tablet/', // ProductScreen
    

    getProductsIncludeWebshop: url + '/admin_products/includeWebshop/', // ProductScreen
    getTotalProductsToScrape: url + '/admin_panel/products/', // ProductScreen
    getTotalInvoicesToScrape: url + '/admin_panel/invoicesToScrape/', // ProductScreen
    getInvoicesToScrape: url + '/admin_invoices/processing/', // ProductScreen

    getInvoicesToValidate: url + '/admin_invoices/validate/', // ProductScreen
    getSteffiesToValidate: url + '/admin_steffies/validate/', // ProductScreen
    
    
    getInvoice: url + '/admin_invoices/invoice/', // ProductScreen
    getSteffie: url + '/admin_steffies/steffie/', // ProductScreen
    
    
    getWebshops: url + '/admin_webshops/', // ProductScreen
    updateWebshop: url + '/admin_webshops/', // ProductScreen
    updateWebshopImage: url + '/admin_webshops/imageUpdate/', // ProductScreen
    postWebshop: url + '/admin_webshops/', // ProductScreen
    // updateWebshopImage: url + '/admin_webshops/bob/', // ProductScreen


    
    getWebshopsToScrape: url + '/admin_webshops/toScrape/', // ProductScreen

  
    getUsersProductPrice: url + '/admin_lineItems/price_user/', // ProductScreen
    getUsersProduct: url + '/admin_lineItems/users_lineItem/', // ProductScreen
   
    getProductInvoices: url + '/admin_lineItems/invoices_success/', // ProductScreen
    getProductSteffies: url + '/admin_lineItems/steffies_success/', // ProductScreen
    
   	
    getProductsToScrape: url + '/admin_products/products_to_scrape/', // ProductScreen
    updateProductImage: url + '/admin_products/updateImage/', // ProductScreen
    
  

    updateProductBookmark: url + '/products/bookmark/', // ProductScreen
    getSteffies: url + '/invoices/orders/', // SteffieScreen
    updateLabels:url + '/labels/updateLabel/', // MyLabelsSCreen
    getLabels: url + '/labels/', // MyLabelsSCreen  && LabelPickerComponent
    createLabel:url + '/labels/', // MyLabelsSCreen
    deleteLabel: url + '/labels/', // MyLabelsSCreen
    getAnnotations: url + '/annotations/', // MyLabelsSCreen
    updateAnnotation: url + '/annotations/', // MyLabelsSCreen
    deleteAnnotations: url + '/annotations/', // MyLabelsSCreen
    createAnnotations: url + '/annotations/', // MyLabelsSCreen
    getAnnotationsProduct: url + '/annotations/product/', // MyLabelsSCreen
    login: url + '/authServer/login/', // LoginScreen
  //  register: url + '/users/register/', // modules/auth_sign/SignUpScreen
    refreshToken: url + '/authServer/refreshToken/', // /helpers/networking/authJWT.js                    
    invoiceUpload: url + '/upload/', // modules/core_invoiceUpload/screens/invoiceUploadScreen                ##  CORE
    openInvoice: url + '/invoices/invoiceAsPdf/', // modules/core_invoiceUpload/screens/openInvoiceScreen     ##  CORE
  };

  // To Do:
  // invoiceUpload <= die ene vanuit ShareIntent


  export default apiEndpoint;
  