import React from "react";
import { View, Text, TextInput, StyleSheet, Dimensions } from "react-native";
import colors from "../../../styles/colors";

export default class TextInputComponent extends React.Component {
  render() {
    const label = this.props.label;
    const value = this.props.value;
    const placeholder = this.props.placeholder;
    const error = this.props.error;
    const width = this.props.width
    return (
      <View style={{flexDirection: "row",
      marginHorizontal: 10,
      marginBottom:30,
      borderWidth: 1,
      borderColor: colors.white,
      //  paddingTop:10,
      // marginTop: 10,
      borderRadius: 8,
      // top: 2,
      width: width ? width : 240 ,
      height: 40,
      //alignSelf:'center',
      // marginVertical:4,
      backgroundColor: colors.component,}}>
        <Text
          style={{
            position: "absolute",
            
            //  fontWeight: "bold",
            bottom: 42,
            color: error ? colors.red : colors.text,
            fontSize: 14,
            left: 5,
          }}
        >
          {label}
        </Text>
        <TextInput type="text"
        
          // ref={(input) => { this.nameInput = input; }}
          // type={"item.type"}
          autoFocus={this.props.autoFocus}
          placeholderTextColor={colors.red}
          placeholder={placeholder}
          onChangeText={(text) =>
            this.props.onChangeText(text, "productWebsiteUrl")
          }
          value={value}
          // onBlur={(value) => this.props?.onBlur(value)}
          style={{
            height: 40,
            // marginLeft: 10,
            width: width ? width : Dimensions.get("window").width - 40,
            alignSelf: "center",
            color: colors.green,
            paddingLeft: 30,
            zIndex: 1,
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textInput: {
    height: 40,
    // marginLeft: 10,
    width: Dimensions.get("window").width - 40,
    alignSelf: "center",
    color: colors.green,
    paddingLeft: 30,
    zIndex: 1,
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center",
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    margin:30,
    borderWidth: 1,
    borderColor: colors.white,
    //  paddingTop:10,
    // marginTop: 10,
    borderRadius: 8,
    // top: 2,
    width: 240,
    height: 40,
    //alignSelf:'center',
    // marginVertical:4,
    backgroundColor: colors.component,
  },
  text: {
    position: "absolute",
    //  fontWeight: "bold",
    bottom: 42,
    color: colors.text,
    fontSize: 14,
    left: 5,
  },
});
