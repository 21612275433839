import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  View,
  Dimensions,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";


import moment from 'moment'
import DatePicker from '../../../globalComponents/pickers/DatePicker'


import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import axios from "axios";

import colors from "../../../styles/colors";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TextInputComponent from "../components/TextInputComponent";

import ThumbDownIcon from "@material-ui/icons/ThumbDown";

import SearchComponent from "../../../globalComponents/search/SearchComponent";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import InputComponent from "../components/InputComponent";
import InputRowComponent from "../components/InputRowComponent";
import DatePickerComponent from "../../../globalComponents/pickers/DatePickerComponent";

export default class InvoiceScrapeScreen extends Component {
  constructor(props) {
    super(props);

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.state = {
      numPages: null,
      isLoading: true,
      webshopId: this.props.navigation.getParam("item").webshopId,
      userId: this.props.navigation.getParam("item").userId,
      invoiceId: this.props.navigation.getParam("item").id,
      orderNumber: this.props.navigation.getParam("item").orderNumber,
      invoiceNumber: this.props.navigation.getParam("item").invoiceNumber,
      paymentAmount: this.props.navigation.getParam("item").paymentAmount,
      orderDate: this.props.navigation.getParam("item").orderNumber,
      invoiceDate: this.props.navigation.getParam("item").invoiceDate,
      discount: this.props.navigation.getParam("item").discount,

      product: "",
      pricePaid: "",
      url: "",
      productId: "",
      // products: this.props.navigation.getParam('item').lineItems,
      products: this.props.navigation.getParam('item').lineItems ,
      webshopProducts: [],
      lineItems:[],

     // webshopName: "",
    };
  }

  componentDidMount() {
    const webshop = this.props.navigation.getParam("item", null).webshop;
    if (webshop) {
    //  alert(webshop)
      // this.setState({
        this.setState({
          webshopName: webshop.name, 

        });

        this.getWebshopProducts()

        // hier nog producten
      //   webshopName: "xxxxxx",
      // });
    } else {
      this.setState({
        webshopName: '',
      });
    }

    this._getDataSource();
    this.getWebshops();
    this.getInvoiceLineItems()
    this.setDefaultDates()
  }

  setDefaultDates = () => {
    if(!this.props.navigation.getParam("item").invoiceDate) {
      const invoiceDate = new Date()

      const orderDate = new Date()
      
      
   
      this.setState({
        invoiceDate:invoiceDate,
        orderDate:orderDate
      })

    }
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
    });
  };

  _getDataSource = async () => {
    const invoiceId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getInvoiceBase64 + invoiceId)
        .then((res) =>
          this.setState({ dataSource: res.data.invoice, isLoading: false })
        );
    } catch (err) {
      this._getDataSource();
    }
  };
  getInvoiceLineItems = async () => {
    const invoiceId = this.props.navigation.getParam("item").id;
    try {
      await axios
        .get(apiEndpoint.getInvoiceLineItems + invoiceId)
        .then((res) =>
          this.setState({ lineItems: res.data, isLoading: false })
        );
    } catch (err) {
      console.log('Dit is dus niet gelukt ',invoiceId,  err)
      console.log('Dit is dus niet gelukt ', apiEndpoint.getInvoiceLineItems + invoiceId)
      console.log('Dit is dus niet gelukt ', err)
    //  this._getDataSource();
    }
  };
  getWebshops = async () => {
    try {
      await axios
        .get(apiEndpoint.getWebshops)
        .then((res) => this.setState({ webshops: res.data, isLoading: false }));
    } catch (err) {
      this.getWebshops();
    }
  };

  getWebshopProducts = async (value) => {
    let webshopId = ''
  if(value) {
    webshopId = value.id;
    
  } else {
    webshopId = this.state.webshopId;

  }


    try {
      await axios
        .get(apiEndpoint.getWebshopProducts + webshopId)
        .then((res) =>
          this.setState({ webshopProducts: res.data, isLoading: false })
        );
    } catch (err) {
      this.getWebshops();
    }
  };

  handleSearchSubmit = (value) => {
    this.setState({
      webshopId: value.name,
      contactPage: value.contactPageURL,
      webshopURL: value.webshopURL,
    });
    this.createWebshop(value);
  };
  handleSearchSubmit_product = (value) => {
    this.setState({
      product: value.name,
      productId: value.id, //toegevoegd
      productWebsiteUrl: value.productWebsiteUrl,
      type: value.type,
      price: value.price,
    });
    this.createProduct(value);
    console.log(
      "sadfaksjdf asdjf kjasdfas dfkhamsdf askdfmha sdfkmhasd fjakhmsdf asfhd"
    );
    console.log(value);
  };

  handleSelected = (value) => {
    if (!value) {
      this.setState({ webshopId: "<.?.>" });
    } else {
      this.setState({
        webshopId: value.id,
      });
      this.getWebshopProducts(value);
    }
  };

  updateProductId = (value) => {
    const i = this.state.products.length - 1;
    console.log("id: ", JSON.stringify(value) + " - ", "i: ", i);
    if (this.state.products.length === -1) {
      const newItems = [...this.state.products];
      newItems[0].productId = value.id;
      newItems[0].name = value.name;
      this.setState({
        products: newItems,
      });
    } else {
      const newItems = [...this.state.products];
      newItems[i].productId = value.id;
      newItems[i].name = value.name;
      this.setState({
        products: newItems,
      });
    }
  };

  updateText = (text, type, index) => {
    const newItems = [...this.state.products];
    newItems[index][type] = text;
    this.setState({
      products: newItems,
    });
  };

  addNewLineItem = () => {
    if (this.state.webshopId === "<.?.>") {
      alert("Kies een webshop");
    } else {
      const newelement = {
        product: this.state.product,
        productId: this.state.productId,
        pricePaid: this.state.pricePaid,
        //  url: this.state.url,
        invoiceId: this.state.invoiceId,
        userId: this.state.userId,
      };
      this.setState((prevState) => ({
        products: [...prevState.products, newelement],
      }));

      // this.createProduct();
    }
    // this.setState({
    //   product: "",
    //   price: "",
    //   url: "",
    //   autoFocus: true
    // });
    // setTimeout(()=> this.settingShit(), 2000);
  };

  createProduct = async (value) => {
    let data = {
      webshopId: this.state.webshopId,
      productWebsiteUrl: value.productWebsiteUrl,
      type: this.state.type,
      name: value.name,
      price: value.price,
    };

    
      try {
        await axios.post(apiEndpoint.postProductWithPrice, data).then((res) =>  {

        
          this.setState({
            pricePaid: "",
            url: "",
            productId: res.data.productId,
          }); this.getWebshopProducts()}
        );
      } catch (error) {
        console.log("createProduct error: => ", error);
      }
    
  };

  handleTextInputClick = (text, x) => {
    this.setState({ [x]: text });
  };
  handleTextInputSearch = (text, x) => {
    if (!text) {
      console.log("geen text");
    } else {
      this.setState({ [x]: text.name, productId: text.id });
    }
  };
  onChangeText = (text, x) => {
    this.setState({ [x]: text });
   // setTimeout(()=> alert(text), 3000)
  };

  handleSubmitInvoice = () => {
    this.updatInvoice();

    console.log("this.state.products => : ", this.state.products);
  };

  handleSubmitProducts = () => {
    this.createLineItems();
  };

  createLineItems = async (x) => {
    let data = {
      invoiceId: 1,
      lineItems: this.state.products,
    };
    {
      try {
        await axios.post(apiEndpoint.postLineItems, data).then((res) => {
          this.updateProductId(res.data);
          alert("Gelukt");
        });
      } catch (error) {
        alert("Niet gelukt");
      }
    }
  };
  createLineItem = async (x) => {
    let data = {
      productId: this.state.productId,
      pricePaid: this.state.pricePaid,
      //  url: this.state.url,
      invoiceId: this.state.invoiceId,
      userId: this.state.userId,
      lineItemStatusId:1
    };
    {
      try {
        await axios.post(apiEndpoint.postLineItem, data).then((res) => {
          this.getInvoiceLineItems();
        //  alert("Gelukt");
        });
      } catch (error) {
        alert("Niet gelukt");
     
      }
    }
  };

  updatInvoice = async (i, value) => {
    let data = {
      invoiceNumber: this.state.invoiceNumber,
      orderNumber: this.state.orderNumber,
      queStatus: this.state.queStatus,
      emailComplaints: this.state.emailComplaints,
      invoiceDate: this.state.invoiceDate,
      orderDate: this.state.orderDate,
      paymentAmount: this.state.paymentAmount,
      deliveryCosts: this.state.deliveryCosts,
      discount: this.state.discount,
      webshopId: this.state.webshopId,
   //   queueId:2,
      //    returnPeriodDays: this.state.twitter
    };

    const invoiceId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.putInvoice + invoiceId, data)
          .then((res) => {
            console.log("uh huh huh uh u : => ", res);
            // alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };

  onRemoveItem = (i) => {
    this.setState((state) => {
      const products = state.products.filter((item, j) => i !== j);
      return {
        products,
      };
    });
  };

  handleDate = date => {
    setTimeout(()=> alert(date), 1000)
  }

  createWebshop = async (x) => {
    const value = x.name;
    let data = {
      name: value,
      email: this.state.email,
      emailComplaints: this.state.emailComplaints,
      whatsapp: this.state.whatsapp,
      instagram: this.state.instagram,
      facebook: this.state.facebook,
      twitter: this.state.twitter,
      youtube: this.state.youtube,
      linkedIn: this.state.linkedIn,
      pinterest: this.state.pinterest,
      snapchat: this.state.snapchat,
      owner: this.state.owner,
      kvk: this.state.kvk,
      contactPage: this.state.contactPage,
      webshopURL: this.state.webshopURL,
      refundDurationDays: this.state.refundDurationDays,
      // color:'pink'
    };

    {
      try {
        await axios.post(apiEndpoint.postWebshop, data).then((res) => {
          console.log("res: " + JSON.stringify(res));
          this.setState({
            webshopId: res.data,
            snackbarComponent: true,
          });

          //  alert("gelukt: ", JSON.stringify(res));
        });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };

  handleReadyForValidation = async (i, value) => {
    let data = {
      invoiceId: this.state.invoiceId,
    };

    const invoiceId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.putReadyForValidation + invoiceId, data)
          .then((res) => {
            console.log("uh huh huh uh u : => ", res);

             alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };

  render() {
    const { numPages } = this.state;

    if (this.state.isLoading === true)
      return (
        <View
          style={{
            backgroundColor: colors.background,
            width: Dimensions.get("screen").width,
            height: Dimensions.get("screen").height,
            position: "absolute",
          }}
        />
      );

    return (
      <View
        style={{
          backgroundColor: colors.background,
          width: Dimensions.get("screen").width,
          height: Dimensions.get("screen").height,
          position: "absolute",
        }}
      >

       
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            //  left:30
          }}
          onPress={() => this.props.navigation.navigate("InvoicesToScrape")}
        >
          <ChevronLeftIcon
            style={{
              color: colors.text,
              fontSize: 50,
            }}
          />

          <View style={{ right: 10 }}>
            <Text
              style={{
                alignSelf: "center",
                color: colors.text,
                fontSize: 20,
              }}
            >
              Terug
            </Text>
            <Text
              style={{ color: colors.text, alignSelf: "center", fontSize: 10 }}
            >
              ctrl + b 
            </Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            //  flexDirection:'row',
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            left: 130,
            top: 10,
          }}
          onPress={() => alert("afgekeurd")}
        >
          <ThumbDownIcon
            style={{
              color: colors.red,
              fontSize: 30,
            }}
          />

          {/* <View style={{right:10}}>

                <Text style={{
                    alignSelf:'center',
                    color:colors.text, fontSize:20}}>
                    Reject
                </Text>
               
                    </View> */}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            justifyContent: "center",
            //  alignSelf:'center',
            //  flexDirection:'row',
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            left: 190,
            top: 7,
          }}
          onPress={() =>
            this.props.navigation.navigate(
              "PDF",

              { item: this.props.navigation.getParam("item") }
            )
          }
        >
          <MoreVertIcon
            style={{
              color: colors.text,
              fontSize: 40,
            }}
          />
        </TouchableOpacity>

        <View
          style={{
            position: "absolute",
            top: 60,
            zIndex: 1,
            left: 20,

            // width:100
          }}
        >
          <SearchComponent
            value={this.state.webshopName}
            arr={this.state.webshops}
            handleSubmit={(value) => {
              this.handleSearchSubmit(value);
              console.log("### search input value: => ", value);
            }}
            handleSelected={(value) => this.handleSelected(value)}
          />

          <View style={{ marginTop: 50 }} />
          <TextInputComponent
            onChangeText={(text) => this.onChangeText(text, "invoiceNumber")}
            label={"Factuurnummer"}
            value={this.state.invoiceNumber}
          />


          <TextInputComponent
            label={"Ordernummer"}
            value={this.state.orderNumber}
            onChangeText={(text) => this.onChangeText(text, "orderNumber")}
          />

         
          <View style={{bottom:50}}>

    

{/* <DatePicker
label={'Factuur datum'}
handleDate={date => this.onChangeText(date, 'invoiceDate')}
/>
</View>
          <View style={{bottom:50}}>

<DatePicker
label={'Orderdatum'}
handleDate={date => this.onChangeText(date, 'orderDate')}
/> */}
</View>
          <TextInputComponent
            label={"Klantnummer"}
            onChangeText={(text) => this.onChangeText(text, "customerNumber")}
          />
          <TextInputComponent
            label={"Totaal bedrag"}
            value={this.state.paymentAmount}
            onChangeText={(text) => this.onChangeText(text, "paymentAmount")}
          />
          <TextInputComponent
            label={"Discount"}
            value={this.state.discount}
            onChangeText={(text) => this.onChangeText(text, "discount")}
          />

<View> 
<Text style={{color:colors.text}}>Invoice date</Text>
{/* <DatePickerComponent
maxDate={ moment
  .utc(new Date())}
handlePicker={date => this.onChangeText(date, 'invoiceDate')}
value={this.state.invoiceDate}
/> */}
</View>
<View> 
<Text style={{color:colors.text}}>Order date date</Text>
{/* <DatePickerComponent
maxDate={ moment
  .utc(new Date())}
handlePicker={date => this.onChangeText(date, 'orderDate')}

value={this.state.orderDate}
/> */}
</View>


          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              onPress={() => this.handleSubmitInvoice()}
              style={{
                //  top: 50,
                //    left: 330,
                alignSelf: "center",
                borderRadius: 6,
                borderColor: colors.text,
                borderWidth: 1,
                // alignSelf:"center",
                backgroundColor: colors.component,
                width: 160,
                height: 40,
                marginHorizontal: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: colors.text }}>Update Invoice</Text>
            </TouchableOpacity>
          </View>
          
        </View>
<View style={{backgroundColor:colors.component, 
borderRadius:6,
borderWidth:1,
borderColor:colors.text,
marginTop:10,
alignItems:'center',
flexDirection:'row',
width:200, height:40, left:600}}>
<Text style={{
  marginLeft:10,
  color:colors.white}}>

  Calculated:
</Text>
<Text style={{
  marginLeft:10,
  color:colors.green}}>

 € 200
</Text>
</View>
        <View
          style={{
            position: "absolute",
            top: 60,
            zIndex: 1,
            left: 220,
          }}
        >
          {this.state.lineItems.map((item, i) => (
            <InputComponent
              key={i}
              onChangeText={(text) => this.updateText(text, "product", i)}
              onChangeTextUrl={(text) => this.updateText(text, "url", i)}
              onChangeTextPrice={(text) =>
                this.updateText(text, "pricePaid", i)
              }
              value={item.product.name}
              price={item.pricePaid}
              url={item.url}
              productId={item.productId}
              remove={() => this.onRemoveItem(i)}
              //  onBlur={() => this.handleOnBlur()}
            />
          ))}
          {/* <InputComponent
            onChangeTextPrice={text => this.handleTextInputClick(text, "pricePaid")}
            onChangeText={text => this.handleTextInputClick(text, "product")}
            onChangeTextUrl={text => this.handleTextInputClick(text, "url")}
            value={this.state.product}
            price={this.state.pricePaid}
            url={this.state.url}
            deleteOption={true}
            onBlur={() => this.handleOnBlur()}
          /> */}

          <View style={{ marginTop: 10 }} />
          <InputRowComponent
      
            arr={this.state.webshopProducts}
            handleSearchSubmit={(value) => {
              this.handleSearchSubmit_product(value);
              console.log("### search input value: => ", value);
            }}
            handleSelected={(value) =>
              this.handleTextInputSearch(value, "product")
            }
            //handleSelected={value => this.handleSelected(value)}
            onChangeTextPrice={(text) =>
              this.handleTextInputClick(text, "pricePaid")
            }
            //  onChangeText={text => this.handleTextInputClick(text, "product")}
            onChangeTextUrl={(text) => this.handleTextInputClick(text, "url")}
            value={this.state.product}
            price={this.state.pricePaid}
            url={this.state.url}
            deleteOption={true}
           // addNewRow={() => this.addNewLineItem()}
           addNewRow={() => this.createLineItem()}
          
          />
          <TouchableOpacity
            onPress={() => this.handleReadyForValidation()}
            style={{
              //top: 50,
              //    left: 330,
              alignSelf: "center",
              borderRadius: 6,
              borderColor: colors.green,
              borderWidth: 1,
              marginHorizontal: 10,
              // alignSelf:"center",
              backgroundColor: colors.component,
              width: 160,
              height: 40,
              marginTop: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ color: colors.text }}>{this.state.webshopId}</Text>
            <Text style={{ color: colors.text }}>Ready for validation</Text>
          </TouchableOpacity>
          <TextInput />
        </View>

        <View
          style={{
            alignSelf: "flex-end",
          }}
        >
          <Document
            // file={samplePDF}
            // file={`application/pdf;base64,${this.state.dataSource[1].invoice}`}
            file={`data:application/pdf;base64,${this.state.dataSource}`}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </View>
      </View>
    );
  }
}
