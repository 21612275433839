import React from 'react'
import {View, Text, TextInput, StyleSheet, Dimensions} from'react-native'
import colors from '../../../styles/colors'


export default class PriceInputComponent extends React.Component {

  // componentDidMount(){
  //   this.nameInput.focus();
  // }

  
    render(){
        const label = this.props.label
        const value = this.props.value
        const placeholder = this.props.placeholder
        return(
            <View style={styles.textInputContainer}>
            <Text style={styles.text}>{label}</Text>
            <TextInput
            // ref={(input) => { this.nameInput = input; }} 
              type={"item.type"}
              autoFocus={this.props.autoFocus}
              placeholderTextColor={colors.text}
              placeholder={placeholder}
              onChangeText={text => this.props.onChangeText(text)}
              value={value}
              style={[styles.textInput]}
             //
              onBlur={()=> this.props.onBlur()}
            />
          </View>

        )
    }
}

const styles = StyleSheet.create({
    textInput: {
        height: 40,
        // marginLeft: 10,
        width:  80,
        alignSelf: "center",
        color: colors.green,
        paddingLeft: 6,
        zIndex:1
      },
      textInputIcon: {
      //  left: 5,
        color: colors.white,
        fontSize: 30,
        alignSelf: "center"
      },
      textInputContainer: {
        flexDirection: "row",
       // marginHorizontal: 20,
        borderWidth: 1,
        borderColor: colors.white,
      //  paddingTop:10,
       // marginTop: 10,
        borderRadius: 8,
        bottom: 40,
        width: 80,
        marginVertical:10,
        //alignSelf:'center',

        backgroundColor: colors.component
      },
      text: {
        position: "absolute",
      //  fontWeight: "bold",
        bottom: 42,
        color: colors.text,
        fontSize: 14,
       // left: 5
      },
})