import { createSwitchNavigator } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import DashboardScreen from "../modules/dashboard/screens/DashboardScreen";
import UsersScreen from "../modules/user/screens/UsersScreen";
import InvoiceScrapingScreen from "../modules/invoiceScraping/screens/InvoiceScrapingScreen";
import AuthLoading from "../modules/authentication/screens/AuthLoading";
import LoginScreen from "../modules/auth_sign/screens/LoginScreen"
import ProductsScreen from "../modules/products/screens/ProductsScreen";
import InvoicesScreen from "../modules/invoices/screens/InvoicesScreen";
import InvoicesToScrapeScreen from "../modules/invoices/screens/InvoicesToScrapeScreen";
import WebshopsScreen  from '../modules/webshops/screens/WebshopsScreen'
import WebshopEditScreen from "../modules/webshops/screens/WebshopEditScreen";
import WebshopsToScrapeScreen from "../modules/webshops/screens/WebshopsToScrapeScreen";
import New_ProductScreen from "../modules/products/screens/New_ProductScreen";
// import WebshopScreen_Create_New from "../modules/webshops/screens/WebshopScreen_Create_New";
//import ProductEditScreen from "../modules/scrape_products/screens/ProductEditScreen";
import ProductsMonitorScreen from "../modules/products/screens/ProductsMonitorScreen";
import PDFScreen from "../modules/invoices/screens/PDFScreen";
import InvoiceScrapeScreen from "../modules/invoices/screens/InvoiceScrapeScreen";
import UserScreen from "../modules/user/screens/UserScreen";
import ProductWebsiteScreen from "../modules/products/screens/ProductWebsiteScreen";
import ProductEditScreen from "../modules/products/screens/ProductEditScreen";
import ProductMonitorUserScreen from "../modules/products/screens/ProductMonitorUserScreen";
import SendNotificationScreen from "../modules/products/screens/SendNotificationScreen";
import InvoicesValidateScreen from "../modules/invoices/screens/InvoicesValidateScreen";
import ProductsToScrapeScreen from "../modules/products/screens/ProductsToScrapeScreen";
import MessageToUserScreen from "../modules/message/screens/MessageToUserScreen";
import MessagesToUsersScreen from "../modules/message/screens/MessagesToUsersScreen";
import RefundsAvailableScreen from "../modules/refundsAvailable/screens/RefundsAvailableScreen";
import RefundsClaimedScreen from "../modules/refundsAvailable/screens/RefundsClaimedScreen";
import DonationsScreen from "../modules/donation/screens/DonationsScreen";
import InvoicesToValidateScreen from "../modules/toValidate/screens/InvoicesToValidateScreen";
import InvoiceValidationScreen from "../modules/toValidate/screens/InvoiceValidationScreen";
import Monitor_ProductInvoicesScreen from "../modules/products/screens/Monitor_ProductInvoicesScreen";
import SteffiesToScrapeScreen from "../modules/_processing_steffiesToScrape/screens/SteffiesToScrapeScreen";
import SteffiesToValidateScreen from "../modules/_processing_steffiesToValidate/screens/SteffiesToValidateScreen";
import MonitorProductSteffiesScreen from "../modules/_monitor_productSteffies/screens/MonitorProductSteffiesScreen";
import PriceChangeNotificationScreen from "../modules/_message_priceChange/screens/PriceChangeNotificationScreen";
import SteffieRejectionScreen from "../modules/_processing_steffieRejection/screens/SteffieRejectionScreen";
import SteffiesScreen from "../modules/_lists_steffies/screens/SteffiesScreen";
import LineItemsScreen from "../modules/lineItems/screens/LineItemsScreen";
import SteffiesToScrapeDownloadPdfScreen from "../modules/_processing_steffiesToScrape/screens/SteffiesToScrapeDownloadPdfScreen";
import TabletScreen from "../modules/tablet_dashboard/screens/TabletScreen";
import SteffieScrapeScreen from "../modules/_processsing_steffieScrape/screens/SteffieScrapeScreen";
import Step_0_newSteffieScreen from "../modules/Step_0_newSteffie/screens/Step_0_newSteffieScreen";
import Step_1_webshop_detectedScreen from "../modules/Step_1_webshop_detected/screens/Step_1_webshop_detectedScreen";
import SteffieScrapeScreenOld from "../modules/_processsing_steffieScrape/screens/SteffieScrapeScreenOld";
import Scrape_errorScreen from "../modules/step_scrape_error/screens/Scrape_errorScreen";
import _SteffieEditScreen from "../modules/_steffie_edit/screens/_SteffieEditScreen";
import _SteffieEditOneScreen from "../modules/_steffie_edit/screens/_SteffieEditOneScreen";
import __final_steffieScreen from "../modules/__final_steffie/screens/__final_steffieScreen";
import PricesScreen from "../modules/Prices/Screens/PricesScreen";
import UnknownSteffiesScreen from "../modules/unknownSteffies/screens/UnknownSteffiesScreen";
import UnknownSteffieScreen from "../modules/unknownSteffies/screens/UnknownSteffieScreen";
import MessagesToUsersUnreadScreen from "../modules/messagesToUser/screens/MessagesToUsersUnreadScreen";
import SteffieToValidateScreen from "../modules/_processing_steffiesToValidate/screens/SteffieToValidateScreen";
import WebshopDetectedSteffiesScreen from "../modules/webshopsDetectedSteffies/screens/WebshopDetectedSteffiesScreen";
import WebshopDetectedSteffieScreen from "../modules/webshopsDetectedSteffies/screens/WebshopDetectedSteffieScreen";
import ProductScreen from "../modules/products/screens/ProductScreen";
import CurrentSteffiesScreen from "../modules/_lists_steffies/screens/CurrentSteffiesScreen";
const MyNavigator = createSwitchNavigator({
    WebshopDetectedSteffie:WebshopDetectedSteffieScreen,
    WebshopDetectedSteffies:WebshopDetectedSteffiesScreen,
    SteffieToValidate:SteffieToValidateScreen,
    MessagesToUsersUnread:MessagesToUsersUnreadScreen,
    UnkownSteffie:UnknownSteffieScreen,
    UnknownSteffies:UnknownSteffiesScreen,
    Dashboard:DashboardScreen,
    Users:UsersScreen,
    InvoiceScraping:InvoiceScrapingScreen,
    AuthLoading:AuthLoading, 
    Login:LoginScreen,
    Products:ProductsScreen,
    Invoices:InvoicesScreen,
    Webshops:WebshopsScreen,
    WebshopEdit:WebshopEditScreen,
    WebshopsToScrape:WebshopsToScrapeScreen,
    ProductsToScrape: ProductsToScrapeScreen,
    New_Product:New_ProductScreen,
    ProductEdit:ProductEditScreen,
    ProductsToMonitor:ProductsMonitorScreen,
    InvoiceScrape:InvoiceScrapeScreen,
    PDF:PDFScreen,
    User:UserScreen,
    ProductWebsite:ProductWebsiteScreen,
    InvoicesToScrape:InvoicesToScrapeScreen,
    ProductMonitorUser:ProductMonitorUserScreen,
    SendNotification:SendNotificationScreen,
    InvoiceValidate:InvoicesValidateScreen,
    MessageToUser:MessageToUserScreen,
    MessagesToUsers:MessagesToUsersScreen,
    RefundsAvailable:RefundsAvailableScreen,
    RefundsClaimed:RefundsClaimedScreen,
    Donations:DonationsScreen,
    InvoicesToValidate:InvoicesToValidateScreen,
    InvoiceValidation:InvoiceValidationScreen,
    Monitor_ProductInvoices:Monitor_ProductInvoicesScreen,
    SteffiesToScrape:SteffiesToScrapeScreen,
    SteffiesToValidate:SteffiesToValidateScreen,
    MonitorProductSteffies:MonitorProductSteffiesScreen,
    PriceChangeNotification:PriceChangeNotificationScreen,
    SteffieRejection:SteffieRejectionScreen,
    Steffies:SteffiesScreen,
    LineItems:LineItemsScreen,
   SteffiesToScrapeDownloadPdf:SteffiesToScrapeDownloadPdfScreen,
   Tablet:TabletScreen,
   SteffieScrape:SteffieScrapeScreen,
   Step_0_newSteffie:Step_0_newSteffieScreen,
   Step_1_webshop_detected:Step_1_webshop_detectedScreen,
   SteffieScrapeOld:SteffieScrapeScreenOld,
   Scrape_error:Scrape_errorScreen,
   _SteffieEdit:_SteffieEditScreen,
   _SteffieEditOne:_SteffieEditOneScreen,
   __final_steffie:__final_steffieScreen,
   Prices:PricesScreen,
   Product:ProductScreen,
   CurrentSteffies:CurrentSteffiesScreen

    
}, 
   {
       initialRouteName: 'Login',
       cardStyle: { opacity: 1, backgroundColor: 'black', }
  //    initialRouteName: "App"
    },
    
);

const AppNavigator = createBrowserApp(MyNavigator, { cardStyle: { opacity: 1, backgroundColor: 'black', }});






export default AppNavigator

