import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import { HotKeys } from "react-hotkeys";
import ValueComponent from "../components/ValueComponent";
import ValueNestedComponent from "../components/ValueNestedComponent";
import axios from "axios";
import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import ReactDataGrid from "react-data-grid";
import TableComponent from "../components/TableComponent";

import moment from "moment";

const columns = [
  { key: "id", name: "userId" },
  { key: "name", name: "name" },
  { key: "username", name: "username" },
  { key: "lastname", name: "lastname" },
  { key: "email", name: "email" },
  { key: "createdAt", name: "createdAt" },
  { key: "lineItemId", name: "lineItems" },
  { key: "webshopId", webshopId: "webshopId" },
];

export default class UsersScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      isLoading: true,
    };
  }
  handleGoBack = () => {
    this.props.navigation.navigate("Dashboard");
    //this.props.navigation.navigate("Dashboard");
  };

  componentDidMount() {
    this._getDataSource();
  }

  _getDataSource = async () => {
    {
      try {
        await axios
          .get(apiEndpoint.getUsers)
          .then((res) =>
            this.setState({ dataSource: res.data, isLoading: false })
          );
      } catch (err) {
        this._getDataSource();
      }
    }
  };

  ControlSection = () => {
    return (
      <View>
        {Object.entries(this.state.items).map(([key, val]) => (
          <Text style={{ fontSize: 30 }} key={key}>
            {key}: {val}
          </Text>
        ))}
      </View>
    );
  };

  onRowClick = (index, item, attribute) => {

    // alert(JSON.stringify(attribute))
    this.props.navigation.navigate("User", { item: item })
  };

  render() {
    const item = this.props.navigation.getParam("item");

    // const handlers = {
    //   ACTION: ()=> this.handleGoBack('hoi'),
    //   ACTION: ()=> alert('b')
    // };

    // const keyMap = {
    //   ACTION: "a",
    //   ACTION: "b"
    // };

    const keyMap = {
      ACTION_ONE: "a",
      ACTION_TWO: "b",
      SHOW_DIALOG: {
        name: "Display keyboard shortcuts",
        sequences: ["shift+?", { sequence: "`", action: "keyup" }],
        action: "keyup",
      },
      GO_BACK: {
        name: "Terug naar vorige pagina",
        sequences: ["ctrl+b", { sequence: "`", action: "keyup" }],
        action: "keyup",
      },
    };

    const handlers = {
      ACTION_ONE: () => alert("1"),
      ACTION_TWO: () => alert("2"),
      GO_BACK: () => this.handleGoBack(),
    };

    const object = { a: 1, b: 2, c: 3 };

    //   if (this.state.isLoading === true) return<View
    //   style={{
    //     backgroundColor: colors.background,
    //     width: Dimensions.get("screen").width,
    //     height: Dimensions.get("screen").height,
    //     position: "absolute"
    //   }}
    // > <ActivityIndicator />
    // </View>

    const ProgressBarFormatter = ({ value }) => {
      return (
        <View now={value}>
          <Text>{value.username}</Text>{" "}
        </View>
      );
    };

    const createdAtFormatter = ({ value }) => {
      return (
        <View now={value}>
          <Text>{moment(value).format("HH:mm DD MMMM")}</Text>{" "}
        </View>
      );
    };
    const rolesFormatter = ({ value }) => {
      return value.map((item) =>
        item.name === "bob" || item.name === "woutertje" ? (
          <Text
            style={{
              color: colors.red,
              fontWeight: "bold",
              marginHorizontal: 4,
            }}
          >
            {item.name}
          </Text>
        ) : (
          <Text style={{ marginHorizontal: 4 }}>{item.name}</Text>
        )
      );
    };
    const stateFormatter = ({ value }) => {
    
      if (!value)
        return (
          <View
            style={{
              backgroundColor: colors.red,
              borderRadius: 3,
              width: 100,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ color: 'white' }}>Geen State </Text>
          </View>
        );
      if (value.name==='active')
        return (
          <View
            style={{
              backgroundColor: colors.green,
              borderRadius: 3,
              width: 100,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ color: 'white' }}>{value.name} </Text>
          </View>
        );
      if (value.name==='inactive')
        return (
          <View
            style={{
              backgroundColor: colors.blue,
              borderRadius: 3,
              width: 100,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ color: 'white' }}>{value.name} </Text>
          </View>
        );
      return <Text>{value.name}</Text>;
    };

    return (
      // <HotKeys focused={true} keyMap={keyMap} handlers={handlers}>
      <View
        style={{
          backgroundColor: colors.background,
          width: Dimensions.get("screen").width,
          height: Dimensions.get("screen").height,
          position: "absolute",
        }}
      >
        <BobsHeader
          handleBack={() => this.props.navigation.navigate("Dashboard")}
          title={"Users"}
        />

        {/* <ReactDataGrid
          columns={[
            { key: "id", name: "userId" },
            { key: "email", name: "email" },
            { key: "username", name: "username" },
            { key: "senderName", name: "senderName" },
            {
              key: "createdAt",
              name: "creation date",
              formatter: createdAtFormatter,
            },
            { key: "roles", name: "roles", formatter: rolesFormatter },
            { key: "state", name: "state", formatter: stateFormatter },
            //  { key: 'lineItems', name: 'Number of lineItems', formatter:lineItemFormatter },
          ]}
          //  rowGetter={i => rows[i]}
          rowGetter={(i) => this.state.dataSource[i]}
          rowsCount={200}
          width={400}
          minHeight={550}
          onRowDoubleClick={(index, item, attribute) =>
            this.onRowClick(index, item, attribute)
          }
          
          


        /> */}
        <TableComponent
            handleClick={row =>
              this.props.navigation.navigate("User", { item: row })
            }
            arr={this.state.dataSource}
          />
        {/* <Text style={{ color: colors.blue }}>
          {JSON.stringify(this.state.dataSource, null, 4)}
        </Text> */}
      </View>
      // </HotKeys>
    );
  }
}

const styles = StyleSheet.create({
  textHeader: {
    marginLeft: 4,
    color: colors.text,
    fontSize: 10,
  },
  text: {
    marginLeft: 10,
    color: colors.text,
    fontWeight: "bold",
    fontSize: 14,
  },
});
