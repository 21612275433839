 import React from 'react'
 import {View, TouchableOpacity, Text,Image } from 'react-native'
 import CancelIcon from '@material-ui/icons/Cancel';
import colors from '../../../styles/colors'

 export default class InvoiceComponent extends React.Component {
state = {
    checkProduct:false,
    checkLineItem:false
}

handleCheck = (type) => {
    const check = this.state[type]
    this.setState({
        [type]:!check
    })

}

     render(){
         
         return(

            <View>

             <View style={{
                 borderRadius:6,
                 width:350, height:150, backgroundColor:colors.component
                 
                }}>



                {/* <Text>{JSON.stringify(this.props.item)}</Text> */}
                </View>
                {this.props.item ? this.props.item.lineItems.map(item=>

<View> 
<View style={{flexDirection:'row', alignItems:'center'}}>

<View style={{backgroundColor:colors.component, left:20, 
    borderRadius:6,
    marginVertical:5,
    height:50,
    flexDirection:"row",
    alignItems:'center',
    justifyContent:'space-between',
    width:400,}}>
                <Text style={{color:colors.green, marginLeft:5, maxWidth:330 }}>{item.product.id}</Text>
                <Text style={{color:colors.green, marginLeft:5, maxWidth:330 }}>{item.product.name}</Text>
                <Text style={{color:colors.yellow, marginRight:5, width:60 }}>€ {item.pricePaid}</Text>
                    
                </View>
                
                <View style={{left:30, flexDirection:'row'}}>

                <TouchableOpacity
                onPress={()=>this.handleCheck('checkLineItem')}

                style={{borderWidth:1, 
              
                    borderRadius:6,
                    marginHorizontal:3,
                    justifyContent:'center',
                    alignItems:'center',
                    borderColor:colors.text, width:30, height:30}}>

{this.state.checkLineItem ? 
                        <CancelIcon style={{fontSize:20, color:colors.red}}/>
                    :null}
                </TouchableOpacity>
                <TouchableOpacity 
                   onPress={()=>this.handleCheck('checkProduct')}
                style={{borderWidth:1, 
                    borderRadius:6,
                    marginHorizontal:3,
                    justifyContent:'center',
                    alignItems:'center',
                    borderColor:colors.text, width:30, height:30}}>


{this.state.checkProduct ? 
                        <CancelIcon style={{fontSize:20, color:colors.red}}/>
                    :null}

                </TouchableOpacity>
                </View>
             
                </View>
                {item.serialNumbers.map(item=> 
                <View style={{backgroundColor:colors.component, left:20, 
                    borderRadius:6,
                    marginVertical:5,
                    height:40,
                    left:120,
                    flexDirection:"row",
                    alignItems:'center',
                    justifyContent:'space-between',
                    width:300,}}>
                    <Text style={{color:colors.text, marginLeft:5}}>{item.number}</Text>
                    <Text style={{color:colors.red, marginBottom:10, marginRight:5}}>{item.type}</Text>
                    </View>
                )}
                </View>
                
)
              :null  }
              
             </View>
         )
     }
 }