import React from "react";
import { Text, Image, View, TouchableOpacity } from "react-native";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import colors from "../../../styles/colors";
import moment from 'moment'

import LanguageIcon from '@material-ui/icons/Language';
import PeopleIcon from '@material-ui/icons/People';

export default class TableMonitorComponent extends React.Component {
  render() {
    return (
      <TableContainer
        style={{ backgroundColor: colors.component }}
        component={Paper}
      >

        <Table 
        
        style={{ backgroundColor: colors.component }} 
        
        stickyHeader 
        // aria-label="simple table">
        >
          <TableHead  
        style={{ backgroundColor: colors.component }} 
        >
            <TableRow style={{ backgroundColor: colors.component }}>
              <TableCell style={{ color: colors.text,backgroundColor: colors.component }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  Product id
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text, backgroundColor: colors.component }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  Product Image
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text, backgroundColor: colors.component }}>
              
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  Product name
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text, backgroundColor: colors.component }}>
              <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    color: colors.text
                  }}
                >
                  Webshop
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text,backgroundColor: colors.component }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  ProductNumber
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text,backgroundColor: colors.component }} align="left">
                <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                  price
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text, backgroundColor: colors.component }} align="left">
              <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                time monitored
                </Text>
              </TableCell>
              <TableCell style={{ color: colors.text, backgroundColor: colors.component }} align="left">
              <Text
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: colors.text
                  }}
                >
                Age
                </Text>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.arr.map((row, index) => (
              // <TableRow key={row.name}>
              <TableRow 
             // onClick={() => this.props.onClick(row, index)} 
              key={row.id}>
                <TableCell
                  style={{ color: colors.text, flexDirection: "row" }}
                  component="th"
                  scope="row"
                >
              

                  <Text style={{color:colors.yellow}}>
                  {row.id}
                   </Text>

<View style={{flexDirection:'row'}}>

                  <TouchableOpacity
                  onPress={()=> this.props.onClick(row, index, 'url')}
                  >

                   <LanguageIcon 
                   style={{color:colors.yellow}}
                   />
                  </TouchableOpacity>
                  <TouchableOpacity
                  onPress={()=> this.props.onClick(row, index, 'users')}
                  >

                   <PeopleIcon 
                      style={{color:colors.red}}
                   />
                  </TouchableOpacity>
                  </View>
                 
                </TableCell>
                <TableCell
                  style={{ color: colors.green, flexDirection: "row" }}
                  component="th"
                  scope="row"
                >
                
                  <Image
                      style={{ width: 30, height: 30, marginHorizontal: 4 }}
                      source={row.image}
                    />
                 {row.image}
          
                </TableCell>
                <TableCell
                  style={{ color: colors.green }}
                  component="th"
                  scope="row"
                >

                  <Text style={{color:colors.green}}>

                   
                  {row.name}
                  </Text>
                </TableCell>
                <TableCell
                  style={{ color: colors.green }}
                  component="th"
                  scope="row"
                >
                  {/* <Image
                      style={{
                        alignSelf:'center',
                        left:10,
                        width: 30, height: 30, marginHorizontal: 4 }}
                      source={row.webshop.image}
                    /> */}
                   <Text style={{color:colors.green}}>
                  
                    {row.webshop.name}</Text>
                </TableCell>
              
                <TableCell 
                onClick={()=> alert('sadf')}
                style={{ color: colors.green }} align="left">
                  {row.productNumber}  xxxxxxxx
                </TableCell>
                <TableCell style={{ color: colors.green }} align="left">
                  {row.productNumber}  xxxxxxxx
                </TableCell>
                <TableCell style={{ color: colors.green }} align="left">
                  {row.productNumber}  xxxxxxxx
                </TableCell>

                <TableCell >
                <View style={{flexDirection:'row'}}>
                {row.prices.map(item=>
              

             
         <View
         style={{
           height: 40,
           borderLeftWidth: 1,
           borderLeftColor: colors.blue,
           width: 120,
           flexDirection:'row',
           marginHorizontal:2,
           marginBottom:5
        
           //  justifyContent:'center',
           // alignItems:'center'
         }}
       >
         <Text
           style={{
             //  alignSelf:'center',
             marginTop: 5,
             marginLeft: 5,
             color: colors.green
           }}
         >
           € {item.price}
         </Text>
         <Text
           style={{
             fontSize: 10,
             color: colors.white,
             position: "absolute",
             bottom: 2,
             left: 5
           }}
         >
           {moment(item.createdAt).format("YYYY-MM-DD")}
           <Text
             style={{
               fontSize: 12,
               color: colors.red,
               //  position:'absolute',
               //bottom:2,
               left: 5
             }}
           >
             {" "}
             {moment(item.createdAt).format("hh:mm:ss")}
           </Text>
         </Text>
         <Text
           style={{
             fontSize: 10,
             color: colors.blue,
             position: "absolute",
     //        bottom:20,
             bottom: 40,
             right: 5
           }}
         >
           {moment(item.createdAt).fromNow()}
         </Text>
       </View>
                )}
                </View>
                </TableCell>

                {/* <TableCell style={{ color: colors.red }} align="left">

                  <View style={{flexDirection:'row'}}>
                  {row.prices.map(item=>
                    <Text style={{marginHorizontal:2, color:colors.yellow}}>
                      {item.price}
                    </Text>
                    )}
                  </View>
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
  
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                   

                  {moment(row.prices[0].createdAt).format('DD MMMM YYYY - HH:mm')} uur
                </TableCell>
                <TableCell style={{ color: colors.text }} align="left">
                  {moment(row.prices[0].createdAt).fromNow()}
                </TableCell> */}
            
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
