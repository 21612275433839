export default {
    white: '#e0e0e0',
    background: '#263238',
    red:'#cf6679',
    orange:'#FFA500',
  //  red: '#ef5350',
    blue:'#4fc3f7',
    yellow:'#F0E68C',
    component:'#37474F',
    green:'#29A19C',
    
    background: '#212747',
    component:'#313C5F',
    subComponent:'#495277',
    subText:'#495277',
    text:'#A5B2CD',
    active:'#72ADF0',


    dark_mode_tile: '1B1F43',
    dark_mode_background: '02052E' // https://dribbble.com/shots/6554421-Sport/attachments
}