import React from "react";
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
 
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
 
export default  class DatePickerComponent extends React.Component {
  state = {
    startDate: new Date()
  };
 
  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
 
  render() {
    return (
        
      <DatePicker
      maxDate={this.props.maxDate}
        selected={this.props.value}
        onChange={date => this.props.handlePicker(date)}
      />
    );
  }
}