import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  TextInput,
  Image,
  Modal
} from "react-native";
import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import { HotKeys } from "react-hotkeys";
import { Document, Page, pdfjs } from "react-pdf";

import axios from 'axios'
import apiEndpoint from '../../../helpers/networking/apiEndpoint';

//import Pdf from 'react-native-pdf'; // <-- is een React Native library, weet niet of het werkt.

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js";
const inputArr = [
  {
    icon: "account",
    value: "",
    secureTextEntry: false,
    placeholder: "ProductName"
  },
  { icon: "lock", value: "", secureTextEntry: true, placeholder: "Wachtwoord" }
];
const invoiceArr = [
  { value: "xxxxxx", color: colors.text, placeholder: "Winkel", focus: false }, // <-- deze kunnen we snel herkennen in scrapers
  { value: "xxxxxx", color: colors.text, placeholder: "Factuurnummer", focus: false },
  { value: "xxxxxx", color: colors.text, placeholder: "Klantnummer", focus: false },
  { value: "xxxxxx", color: colors.text, placeholder: "Factuurdatum", focus: false },
  { value: "xxxxxx", color: colors.text, placeholder: "Ordernummer", focus: false },
  { value: "xxxxxx", color: colors.text, placeholder: "Orderdatum", focus: false }
];

const productArr = [
  { value: "toch niet fit he?", color: colors.text, placeholder: "Product naam", focus: false },
  { value: "", color: colors.text, placeholder: "Betaald", focus: false }
  //  { value: "", color: colors.text, placeholder: "Serienummer", focus: false }
];
const webshops = [
  { icon: "account", value: "", secureTextEntry: false, name: "MediaMarkt" },
  { icon: "lock", value: "", secureTextEntry: true, name: "Coolblue" }
];

const pdf_file =
  "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
export default class InvoiceScrapingScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.navigation.getParam("item"),
      inputArr: invoiceArr,
      webshops: webshops,
      invoiceArr: invoiceArr,
      modal: true,
      setFocus: false,
      textInputText: "",
      tooInputItem: "Winkel",
      i: 0,
      nextItem: "Winkel",
      currentInput: "start"
    };
  }

  state = {
    numPages: null,
    pageNumber: 1
  };

  handleGoBack = () => {
    this.props.navigation.navigate("Dashboard");
  };

  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js";
  }

  onFileChange = url => {
    this.file = { url };
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handleTextInput = (input, i) => {
    let text = input;
    const updatedArray = [...this.state.inputArr];
    updatedArray[i].value = text;
    this.setState({
      inputArr: updatedArray
    });
  };
  _setFocus = i => {
    //   let text = input

    const newValue = this.state.inputArr[i].focus;
    const updatedArray = [...this.state.inputArr];
    updatedArray[i].focus = !newValue;
    this.setState({
      inputArr: updatedArray
    });
  };

  setFocus = () => {
    const setFocus = this.state.setFocus;
    this.setState({
      setFocus: !setFocus
    });
  };

  _handleTextInputTik = text => {
    let i = this.state.i;
    let nextItem = this.state.inputArr[this.state.i + 1].placeholder;
    const updatedArray = [...this.state.inputArr];
    updatedArray[i].value = text;
    // updatedArray[i].color = colors.green;
    this.setState({
      inputArr: updatedArray,
      i: i + 1,
      currentInput: text,
      nextItem: nextItem
    });
  };
  handleTextInputTik = text => {
    if (this.state.i >= this.state.inputArr.length - 1) {
      let i = this.state.i;
      //  let nextItem = this.state.invoiceArr[this.state.i+1].placeholder
      const updatedArray = [...this.state.inputArr];
      updatedArray[i].value = text;
      // updatedArray[i].color = colors.green;
      this.setState({
        inputArr: updatedArray,
        i: i + 1,
        currentInput: text,
        nextItem: "Werkt dit?"
      });

      this.setState(state => {
        const newItems = [...state.inputArr];
        newItems[i].color = colors.green;
        return { inputArr: newItems };
      });

 
    } else {
      let i = this.state.i;
      let nextItem = this.state.inputArr[this.state.i + 1].placeholder;
      const updatedArray = [...this.state.inputArr];
      updatedArray[i].value = text;
      // updatedArray[i].color = colors.green;
      this.setState({
        inputArr: updatedArray,
        i: i + 1,
        currentInput: text,
        nextItem: nextItem
      });

      this.setState(state => {
        const newItems = [...state.inputArr];
        newItems[i].color = colors.green;
        return { inputArr: newItems };
      });
    }
  };

  //handleOnBlur () {
  handleOnBlur = async (item) => {
    const invoiceArr = [
      { value: "", color: colors.text, placeholder: "Winkel", focus: false }, // <-- deze kunnen we snel herkennen in scrapers
      { value: "", color: colors.text, placeholder: "Factuurnummer", focus: false },
      { value: "", color: colors.text, placeholder: "Klantnummer", focus: false },
      { value: "", color: colors.text, placeholder: "Factuurdatum", focus: false },
      { value: "", color: colors.text, placeholder: "Ordernummer", focus: false },
      { value: "", color: colors.text, placeholder: "Orderdatum", focus: false }
    ];
    console.log("blur : " + item.id);

    let i = this.state.i;
    if (i === this.state.inputArr.length) {
      const header =  {
        'Content-Type': 'application/json',
       // 'auth-token': accessToken,
        //other header fields
      }
    
        let data = {
          webshopId: 1,     // dit moet dus een andere route zijn, gewoon een eigen: op naam zoeken
          invoiceNumber:'this.state.inputArr[1].value',
          customerNumber:this.state.inputArr[0].value,
          orderNumber:this.state.inputArr[2].value,
          invoiceDate:this.state.inputArr[1].value,
          orderdate:this.state.inputArr[1].value
        };
        {
          try {
            console.log('  invoiceNumber:this.state.inputArr[1].value,: ' +  JSON.stringify(this.state.inputArr))
            await axios
              .put(apiEndpoint.invoiceToScraping + item.id, data, {
                headers:header
              })
              .then(res => {
                this.setState({
                  inputArr: productArr,
                  i: 0
                });
                console.table(res)
              });
            //  console.log('Dit zijn de labels: ' + res.data);
          } catch (err) {
            //  alert('err: ' + err);
            console.log('err: steffiescherm' + err);
          }
        }
     
    } else {
      //   const nextItem = this.state.inputArr[i].placeholder
      //this.setState({tooInputItem:nextItem})
      
    }
    // alert('hoi')
  }
  // handleOnBlur () {
  //   console.log("blur");

  //   let i = this.state.i;
  //   if (i === this.state.inputArr.length) {

  //     this.setState({
  //       inputArr: productArr,
  //       i: 0
  //     });
  //   } else {
  //     //   const nextItem = this.state.inputArr[i].placeholder
  //     //this.setState({tooInputItem:nextItem})
      
  //   }
  //   // alert('hoi')
  // }


    // invoiceUpdateWebshop = async (item) => {
    //   //   const accessToken = await authJWT.getAccessTokenFromAsyncStorage();
        
    //     const header =  {
    //      'Content-Type': 'application/json',
    //     // 'auth-token': accessToken,
    //      //other header fields
    //    }
     
    //      let data = {
    //        webshopname: item,
    //      };
    //      {
    //        try {
    //          await axios
    //            .put(apiEndpoint.invoiceToScraping + item.id, data, {
    //              headers:header
    //            })
    //            .then(res => console.table(res));
    //          //  console.log('Dit zijn de labels: ' + res.data);
    //        } catch (err) {
    //          //  alert('err: ' + err);
    //          console.log('err: steffiescherm' + err);
    //        }
    //      }
    //    };
  

  handleTik = id => e => {
    this.nameOrId.focus();
  };

  //   handleTik = id => e => {
  //    // let i = this.state.i
  //     let i = this.state.blur + 2
  //     this[`textInput${i}`].focus();
  //   };

  handleFocus(event) {
    event.target.select();
  }

  render() {
    const source = {
      uri: "http://samples.leanpub.com/thereactnativebook-sample.pdf",
      cache: true
    };

    const item = this.props.navigation.getParam("item");

    const { pageNumber, numPages } = this.state;

    // const handlers = {
    //   ACTION: ()=> this.handleGoBack('hoi'),
    //   ACTION: ()=> alert('b')
    // };

    // const keyMap = {
    //   ACTION: "a",
    //   ACTION: "b"
    // };

    const keyMap = {
      ACTION_ONE: "a",
      ACTION_TWO: "b",
      SHOW_DIALOG: {
        name: "Display keyboard shortcuts",
        sequences: ["shift+?", { sequence: "`", action: "keyup" }],
        action: "keyup"
      },
      GO_BACK: {
        name: "Terug naar vorige pagina",
        sequences: ["ctrl+b", { sequence: "`", action: "keyup" }],
        action: "keyup"
      }
    };

    const handlers = {
      ACTION_ONE: () => alert("1"),
      ACTION_TWO: () => alert("2"),
      GO_BACK: () => this.handleGoBack()
    };

    const object = { a: 1, b: 2, c: 3 };

    return (
      <HotKeys focused={"true"} keyMap={keyMap} handlers={handlers}>
        <View
          style={{
            height: Dimensions.get("window").height,
            //flex:1,
            backgroundColor: colors.background
          }}
        >
          {/* <BobsHeader 
          
          title={'Invoice Scraping'}
          handleBack={() => this.handleGoBack()} /> */}

          {/* {this.ControlSection()} */}
          {/* <Document
          file={pdf_file}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document> */}
          {/* <Image

style={{position:'absolute', zIndex:1}}

source={{uri:"https://www.dailydeals.nl/shared/images/shops/562_large.png?d=1538731772"}}
/>

 */}

          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              //onPress={()=> this.handleTik()}
              onPress={() => {
                this.nameOrId.focus();
              }}
              style={{
                width: 400,
                height: Dimensions.get("screen").height,
                // justifyContent:'center',
                backgroundColor: colors.component
              }}
            >
              <Text
                style={{
                  color: colors.text,
                  fontSize: 30,
                  marginTop: 20,
                  fontWeight: "bold",
                  alignSelf: "center"
                }}
              >
                {this.state.nextItem}
              </Text>

              <Text
                style={{
                  color: colors.text,
                  fontSize: 12,
                  fontStyle: "italic",
                  alignSelf: "center"
                }}
              ></Text>
              <Text
                style={{
                  color: colors.text,
                  fontSize: 12,
                  fontStyle: "italic",
                  alignSelf: "center",
                  textDecorationLine: "underline"
                }}
              >
                ({this.state.currentInput})
              </Text>
              <Text
                style={{
                  color: colors.green,
                  fontSize: 10,
                  fontStyle: "italic",
                  alignSelf: "center"
                }}
              >
                just inserted
              </Text>

              <View
                style={{
                  width: 200,
                  alignSelf: "center",
                  top: "25%",
                  justifyContent: "center",
                  height: 200,
                  borderRadius: 100,
                  backgroundColor: colors.background
                }}
              >
                <Text
                  style={{
                    color: colors.subComponent,
                    alignSelf: "center",
                    fontWeight: "bold"
                  }}
                >
                  TIK ME
                </Text>
              </View>

              <View
                style={{
                  justifyContent: "space-between",
                  position: "absolute",
                  flexDirection: "row",
                  bottom: 60
                }}
              >
                <TouchableOpacity
                  style={{
                    elevation: 1,
                    borderRadius: 6,
                    alignSelf: "center",
                    justifyContent: "center",
                    width: 200,
                    borderWidth: 1,
                    borderColor: colors.component,
                    height: 150,
                    backgroundColor: colors.background
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "center",
                      color: colors.text,
                      fontSize: 16,
                      fontWeight: "bold"
                    }}
                  >
                    Serial nr
                  </Text>
                  <Text
                    style={{
                      alignSelf: "center",
                      fontStyle: "italic",
                      color: colors.text
                    }}
                  >
                    ctrl + s
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    elevation: 1,
                    borderRadius: 6,
                    alignSelf: "center",
                    justifyContent: "center",
                    width: 200,
                    borderWidth: 1,
                    borderColor: colors.component,
                    height: 150,
                    backgroundColor: colors.background
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "center",
                      color: colors.text,
                      fontSize: 16,
                      fontWeight: "bold"
                    }}
                  >
                    Discount
                  </Text>
                  <Text
                    style={{
                      alignSelf: "center",
                      fontStyle: "italic",
                      color: colors.text
                    }}
                  >
                    ctrl + s
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    elevation: 1,
                    borderRadius: 6,
                    alignSelf: "center",
                    justifyContent: "center",
                    width: 150,
                    borderWidth: 1,
                    borderColor: colors.component,
                    height: 150,
                    backgroundColor: colors.background
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "center",
                      color: colors.text,
                      fontSize: 16,
                      fontWeight: "bold"
                    }}
                  >
                    End of Scraping
                  </Text>
                  <Text
                    style={{
                      alignSelf: "center",
                      fontStyle: "italic",
                      color: colors.text
                    }}
                  >
                    ctrl + e
                  </Text>
                </TouchableOpacity>
              </View>
            </TouchableOpacity>

            <View style={{ flexDirection: "column" }}>
              <View>
                <Text
                  style={{
                    marginLeft: 5,
                    color: colors.component
                  }}
                >
                  UserId
                  {/* {item.user.username} */}
                </Text>
                <Text style={{ marginLeft: 10, color: colors.green }}>
                  {item.user.id}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    marginLeft: 5,
                    color: colors.component
                  }}
                >
                  Username
                </Text>
                <Text style={{ marginLeft: 10, color: colors.green }}>
                  {item.user.username}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    marginLeft: 5,
                    color: colors.component
                  }}
                >
                  email
                  {/* {item.user.username} */}
                </Text>
                <Text style={{ marginLeft: 10, color: colors.green }}>
                  {item.user.email}
                </Text>
              </View>

              {this.state.inputArr.map((item, i) => (
                <View
                  key={i}
                  style={[styles.textInputContainer, { top: 90, height: 40 }]}
                >
                  <Text
                    style={{
                      position: "absolute",
                      fontWeight: "bold",
                      bottom: 22,
                      color: colors.component,
                      fontSize: 14,
                      left: 5
                    }}
                  >
                    {item.placeholder}
                  </Text>
                  {/* <Icon name={item.icon} style={styles.textInputIcon} /> */}
                  <TextInput
                    //  ref={input => {
                    //     this[`textInput${i}`] = input;
                    //   }}
                    //   secureTextEntry={'item.secureTextEntry'}
                    type={"item.type"}
                    placeholderTextColor={colors.text}
                    placeholder={item.placeholder}
                    onChangeText={text => this.handleTextInputClick(text, i)}
                    value={item.value}
                    // autoFocus={this}

                    onFocus={this.handleFocus}
                    //        onBlur={()=>this.handleOnBlur(1)}
                    style={[styles.textInput, { height: 25 }]}
                  />
                </View>
              ))}

              <View style={[styles.textInputContainer, { top: 90 }]}>
                <Text
                  style={{
                    position: "absolute",
                    bottom: 32,
                    color: colors.text,
                    fontWeight: "bold",
                    fontSize: 14,
                    left: 5
                  }}
                >
                  The tic{item.placeholder}
                </Text>
                {/* <Icon name={item.icon} style={styles.textInputIcon} /> */}
                <TextInput
                  ref={input => {
                    this.nameOrId = input;
                  }}
                  //   secureTextEntry={'item.secureTextEntry'}
                  type={"item.type"}
                  placeholderTextColor={item.color}
                  placeholder={this.state.nextItem}
                  onChangeText={text => this.handleTextInputTik(text)}
                  value={item.value}
                  autoFocus={this}
                  onFocus={this.handleFocus}
                  onBlur={() => this.handleOnBlur(item)}
                  style={[styles.textInput, { height: 30 }]}
                />
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                style={{ width: 200, height: 20, backgroundColor: colors.co }}
              ></TouchableOpacity>
            </View>
          </View>

          {/* 
        <Text style={styles.text}>
            Op TextInput --> onBlur()
        </Text>
        <Text style={styles.text}>
         Flow:
        </Text>
        <Text style={styles.text}>
         Checken bij facturen aan welke kan het tikvlak moet komen, of misschien aan beide kanten, dus elke kant een scherm
        </Text> */}
        </View>
        {/* <TouchableOpacity
            onPress={() => this.handleLogin()}
            style={ {
                width: '60%',
                borderWidth: 1,
                marginHorizontal: 20,
              
                height: 30,
                marginTop: 10,
                justifyContent: 'center',
                borderRadius: 6,
                borderColor: colors.white,
                bottom:40,
                width:350,
                backgroundColor:colors.component,
             //   alignSelf:'center'
              }}>
            <Text style={{alignSelf: 'center', color: colors.white}}>
            NEXT
            </Text>
          </TouchableOpacity> */}
      </HotKeys>
    );
  }
}

const styles = StyleSheet.create({
  textHeader: {
    marginLeft: 4,
    color: colors.text,
    fontSize: 10
  },
  text: {
    marginLeft: 10,
    color: colors.text,
    fontWeight: "bold",
    fontSize: 12
  },
  pdf: {
    flex: 1,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height
  },
  loginButton: {
    position: "absolute",
    bottom: 10,
    borderTopWidth: 1,
    width: "100%",
    height: 40,
    justifyContent: "center",
    borderTopColor: colors.white,
    //   alignSelf: 'center',
    marginRight: 30
  },
  registerButton: {
    width: "60%",
    borderWidth: 1,
    marginHorizontal: 20,

    height: 30,
    marginTop: 10,
    justifyContent: "center",
    borderRadius: 6,
    borderColor: colors.white,
    bottom: 40,
    width: 350,
    backgroundColor: colors.component
    //   alignSelf:'center'
  },
  logoContainer: {
    width: "100%",
    top: 30,
    position: "absolute",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  logo: {
    color: colors.white,
    fontSize: 80,
    alignSelf: "center"
    // opacity:0.5
  },
  textInput: {
    height: 40,
    alignSelf: "flex-end",
    // marginLeft: 10,
    //  width: Dimensions.get('window').width - 400,
    //  alignSelf: 'center',
    color: colors.green,
    paddingLeft: 10
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center"
  },
  textInputContainer: {
    flexDirection: "row",
    //  paddingTop:40,
    //  marginHorizontal: 20,
    //  borderWidth: 1,
    borderColor: colors.subText,
    //  marginTop: 30,
    // borderRadius: 8,
    //   bottom:40,
    width: 180,
    height: 70,
    // alignSelf:'center',
    backgroundColor: colors.background
  }
});
