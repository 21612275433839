import React from 'react'
import {View, TouchableOpacity, Text} from 'react-native';

import colors from '../../../styles/colors'

export default class ListHeaderComponent extends React.Component {
    render(){

        const text = this.props.text
        const icon = this.props.icon
        const color = this.props.color
        return(
            <View style={{
                backgroundColor:colors.component, 
                width:250,
                height:40,
                justifyContent:'center',
                alignItems:'center',
                borderRadius:6,
                flexDirection:'row',
                marginBottom:5
                }}>

                    <View style={{
                        position:'absolute',
                        left:5
                        }}>
                        {icon}
                    </View>

                    <Text style={{
                        fontSize:20,
                        color:colors.text
                        
                        }}> {text}</Text>


            </View>
        )
    }


}