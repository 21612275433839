import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  ActivityIndicator
} from "react-native";
import colors from "../../../styles/colors";

export default class AuthLoading extends React.Component {
  constructor(props) {
    super(props);
    this.accessToAppCheck();
    this.state = {
      items: this.props.navigation.getParam("item"),
      loading:true
    };
  }
  
  handleGoBack = () => {
    this.props.navigation.navigate("Dashboard");
  };

  accessToAppCheck = () => {
      
  }


  localSetItem = () => {
    const item = "Test_1";
    localStorage.setItem("value", item);
  };
  localGetItem = () => {
    const x = localStorage.getItem("value");
    alert(x);
  };

  render() {
    const item = this.props.navigation.getParam("item");

 
    const object = { a: 1, b: 2, c: 3 };

    // if(this.state.loading === true) return <ActivityIndicator/>

    return (
      <View
        style={{
          height: Dimensions.get("window").height,

          backgroundColor: colors.background
        }}
      >
        <TouchableOpacity onPress={() => this.localSetItem()}>
          <Text
            style={{ color: colors.text, fontSize: 40, alignSelf: "center" }}
          >
            localSetItem()
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this.localGetItem()}>
          <Text
            style={{ color: colors.text, fontSize: 40, alignSelf: "center" }}
          >
            localGetItem()
          </Text>
        </TouchableOpacity>
        <Text style={{ color: colors.text, fontSize: 40, alignSelf: "center" }}>
          AuthLoading Scherm:
        </Text>
        <Text style={{ color: colors.text, fontSize: 40, alignSelf: "center" }}>
          - Async Storage
        </Text>

        {/* {this.ControlSection()} */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textHeader: {
    marginLeft: 4,
    color: colors.text,
    fontSize: 10
  },
  text: {
    marginLeft: 10,
    color: colors.text,
    fontWeight: "bold",
    fontSize: 14
  }
});
