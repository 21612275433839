import React from "react";
import { View, Text, TouchableOpacity, Dimensions,StyleSheet } from "react-native";
import colors from "../../../styles/colors";

export default class ValueNestedComponent extends React.Component {
 

  render() {
 

    // const handlers = {
    //   ACTION: ()=> this.handleGoBack('hoi'),
    //   ACTION: ()=> alert('b')
    // };

    // const keyMap = {
    //   ACTION: "a",
    //   ACTION: "b"
    // };

 
    return (
      
            <View
              style={styles.container}
            >
              <Text
                style={styles.textHeader}
              >
            {this.props.name}
              </Text>
              {this.props.value.map(item=> 
              <Text
                style={styles.text}
              >
                  {item.name}
              </Text>
                    )}
     </View>
    );
  }
}


const styles = StyleSheet.create({
    container:
        {
            justifyContent:'center',
            backgroundColor: colors.component,
            width: 200,
            borderRadius: 6,
            marginVertical:2,
            height:40,
          },
    
    textHeader: {
        marginLeft: 4,
        color: colors.text,
        fontSize: 10
      },
      text:{
        marginLeft: 10,
        color: colors.text,
        fontWeight: "bold",
        fontSize: 14
      }
})