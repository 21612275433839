import React from 'react';
import {View, Text, TouchableOpacity, TextInput} from 'react-native';



import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';

import colors from '../../../styles/colors';

export default class  EditLineItemModal extends React.Component {



    handlePriceChangeModal = () => {
        this.props.handlePriceChangeModal()
    }

    handlePriceInput = (text) => {
      this.props.handlePriceInput(text.target.value)
    }

    priceChangeModalSubmit = () => {
   
      this.props.priceChangeModalSubmit()
    //  alert('hoi')
    }

    priceChangeModalSamePrice = () => {
      this.props.priceChangeModalSamePrice()
    }

    
render() {


const selected = this.props.selected

  return (
    <div>
      <Dialog 
      style={{color:'black'}}
      open={this.props.editLineItemModal} 
      
   //   onClose={handleClose} 
      
      aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete lineItem</DialogTitle>

        <DialogContent>

          <View style={{flexDirection:'row'}}>

      <Text style={{color:colors.component, width:100}}>
       productId: {selected.id}

      </Text>
      <Text style={{marginLeft:20, color:colors.green}}>
      {selected.id}

      </Text>
     
          </View>
   <View style={{width:300}}>
<View style={{flexDirection:'row'}}>

<Text style={{color:colors.component, width:100}}>

          productName:
   </Text>

   <Text style={{marginLeft:20, color:colors.green}}>
{selected.name}
   </Text>
</View>
<View style={{flexDirection:'row'}}>

<Text style={{color:colors.component, width:100}}>
       pricePaid:
   </Text>
<Text style={{marginLeft:20, color:colors.red}}>
       € {selected.pricePaid}
   </Text>
</View>
   </View>
        
         
        </DialogContent>
      
         
          {/* <Button 
          
         color="primary">
            Submit
          </Button> */}


<View style={{flexDirection:'row', justifyContent:'space-between'}}>

          <TouchableOpacity
          style={{
            marginHorizontal:5,
            marginVertical:5,
            justifyContent:'center', alignItems:'center'}}
          onPress={()=> this.props.handleEditLineItemModal()}
          >
              <Text style={{color:colors.red ,fontWeight:'bold'}}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
          style={{justifyContent:'center',
          marginHorizontal:5,
          marginVertical:5,
          alignItems:'center'}}
          onPress={()=> this.props.deleteLineItem()}
          >
              <Text style={{color:colors.green, fontWeight:'bold'}}>Delete</Text>
          </TouchableOpacity>
          </View>
      
      </Dialog>
    </div>
  );
}
}