import React, { Component } from "react";
import {
  Button,
  Image,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator
} from "react-native";
import axios from "axios";
import colors from "../../../styles/colors";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import TableScrapeComponent from "../components/TableScrapeComponent";
import AppBarComponent from "../../../globalComponents/Headers/AppBarComponent";

import GridComponent from "../components/GridComponent";
import DrawerComponent from "../components/DrawerComponent";


import BobsHeader from "../../../globalComponents/Headers/BobsHeader";

export default class ProductsToScrapeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      invoiceQue: [],
      isLoading: true
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts = async () => {

    try {
      await axios
        .get(apiEndpoint.getProductsToScrape)
        .then(res => {
          console.log("sdfdsf" + JSON.stringify(res.data));
          this.setState({ products: res.data, isLoading: false });
        });
    } catch (err) {
      this.getProducts();
      //  alert('err: ' + err)
      console.log(err);
    }
  };

  // localhost:3000/invoices/admin/orders/processing

  handleNavigation = (route, item) => {
    this.props.navigation.navigate(route, {
      item: item
    });

    console.log("Item: =>" + console.table(item));
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <View
          style={{
            //flex:1,
            height: Dimensions.get("window").height,
            backgroundColor: colors.background
          }}
        >
          {/* <ActivityIndicator /> */}
        </View>
      );
    } else {
      return (
        <View
          style={{
            backgroundColor: colors.background,
            width: Dimensions.get("screen").width,
            height: Dimensions.get("screen").height,
            position: "absolute"
          }}
        >
          
          <BobsHeader
            handleBack={() => this.props.navigation.navigate("Dashboard")}
            title={"Scrape_products"}
          />

          <TableScrapeComponent
            onClick={row =>
           //   this.props.navigation.navigate("ProductEdit", { item: row })
           this.props.navigation.navigate("ProductEdit", { item: row, route:'ProductsToScrape' })
            }
            arr={this.state.products}
          />
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    // width: "100%",
    height: Dimensions.get("window").height,
    // headers:'100%',
    backgroundColor: colors.background
  }
});


