import React from "react";
import {
  Image,
  Dimensions,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  TextInput,
} from "react-native";

import FileBase from "react-file-base64";
import axios from "axios";

import apiEndpoint from "../../../helpers/networking/apiEndpoint";

import colors from "../../../styles/colors";
import BobsHeader from "../../../globalComponents/Headers/BobsHeader";
import TextInputComponent from "../../../globalComponents/input/TextInputComponent";
import SearchProductTypeComponent from "../../../globalComponents/search/SearchProductTypeComponent";
import HttpIcon from "@material-ui/icons/Http";
//import './WebshopEditScreen.css';

const DefaultImg = "";

export default class ProductScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.navigation.getParam("item", "<<<<empty>>>>").name,
      refeuroId: this.props.navigation.getParam("item", "<<<<empty>>>>").refeuroId,
      refeuroScrapeHandle: this.props.navigation.getParam("item", "<<<<empty>>>>").refeuroScrapeHandle,
      id: this.props.navigation.getParam("item", "<<<<empty>>>>").id,
      createdBy: this.props.navigation.getParam("item", "<<<<empty>>>>").createdBy,
      image: this.props.navigation.getParam("item", "<<<<empty>>>>").image,
      productNumber: this.props.navigation.getParam("item", "<<<<empty>>>>").productNumber,
      refeuroScrapeHandle: this.props.navigation.getParam("item", "<<<<empty>>>>").refeuroScrapeHandle,
      lastMonitored: this.props.navigation.getParam("item", "<<<<empty>>>>").lastMonitored,
      warrantyDurationMonths: this.props.navigation.getParam("item", "<<<<empty>>>>").warrantyDurationMonths,
      economicWarrantyDurationMonths: this.props.navigation.getParam("item", "<<<<empty>>>>").economicWarrantyDurationMonths,
      productWebsiteUrl: this.props.navigation.getParam("item", "<<<<empty>>>>").productWebsiteUrl,
      prices: this.props.navigation.getParam("item", "<<<<empty>>>>").prices,
      originImageURL: this.props.navigation.getParam("item", "<<<<empty>>>>").originImageURL,
    };
  }

  componentDidMount() {}

  handleTextInputClick = (text, x) => {
    this.setState({ [x]: text });
  };
  handleSubmit = () => {
    this.updateWebshop();
  };

  updateWebshop = async (i, value) => {
    let data = {
      name: this.state.name,
      productNumber: this.state.productNumber,
      image: this.state.image,
      refeuroScrapeHandle: this.state.refeuroScrapeHandle,
      lastMonitored: this.state.lastMonitored,
      warrantyDurationMonths: this.state.warrantyDurationMonths,
      economicWarrantyDurationMonths: this.state.economicWarrantyDurationMonths,
      prices: this.state.prices,
      productWebsiteUrl: this.state.productWebsiteUrl,
      

      // color:'pink'
    };

    const webshopId = this.props.navigation.getParam("item").id;

    {
      try {
        await axios
          .put(apiEndpoint.updateWebshop + webshopId, data)
          .then((res) => {
            alert("gelukt");
          });
      } catch (err) {
        alert("jammer joh");
      }
    }
  };

  render() {
    return (
      <View
        style={{
          backgroundColor: colors.background,
          flex: 1,
        }}
      >
        <BobsHeader
          title={"Product"}
          handleBack={() => this.props.navigation.navigate("Dashboard")}
        />
        <View style={{ flexDirection: "row" }}>
          <View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"name"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) => this.handleTextInputClick(text, "name")}
                value={this.state.name}
              />
              <TextInputComponent
                label={"createdBy"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "returnDurationDays")
                }
                value={this.state.createdBy}
              />
              <TextInputComponent
                label={"refeuroId"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "returnPolicyPage")
                }
                value={this.state.refeuroId}
              />
            </View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"productNumber"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "contactPage")
                }
                value={this.state.productNumber}
              />
              <TextInputComponent
                label={"refeuroScrapeHandle"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "termsAndConditionsPage")
                }
                value={this.state.refeuroScrapeHandle}
              />
              <TextInputComponent
                label={"lastMonitored"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "facebook")
                }
                value={this.state.lastMonitored}
              />
            </View>
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
              <TextInputComponent
                label={"productWebsiteUrl"}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "email")
                }
                value={this.state.productWebsiteUrl}
              />
              <TextInputComponent
                label={"originImageURL"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "phone")
                }
                value={this.state.originImageURL}
              />

             
            </View>
            <View
              style={{
                flexDirection: "row",
                marginHorizontal: 20,
                marginVertical: 20,
                // top:30,
                borderWidth: 1,
                borderColor: colors.white,
                marginTop: 10,
                borderRadius: 8,
                // bottom: 40,
                width: 740,
                //   top: 50,
                //alignSelf:'center',
                backgroundColor: colors.component,
              }}
            >
              <Text style={[styles.text, { fontWeight: "normal" }]}>
                Afbeelding (base64 )
                <Text style={{ color: colors.green }}>
                 
                </Text>
              </Text>

              <TextInput
                label={"image"}
                placeholderTextColor={colors.text}
                placeholder={"<<<<empty>>>>"}
                onChangeText={(text) =>
                  this.handleTextInputClick(text, "image")
                }
                value={this.state.image}
                style={[styles.textInput, { color: colors.green }]}
              />
            </View>
           
          </View>

          <View>
            <TouchableOpacity
              onPress={() =>
                window.open(
                  `https://steffieapp.nl/web/unaccepted/${this.state.refeuroInvoiceId}`
                )
              }
              // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Open Refeuro Scraper</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => window.open(`https://${this.state.name}`)}
              // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Open Webshop-page</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => this.handleSubmit()}
              // onPress={()=> this.nextSteffie()}
              // onPress={() => this.handleInvoiceToAccepted()}
              style={{
                marginVertical: 10,
                backgroundColor: colors.component,
                borderWidth: 1,
                borderColor: colors.green,
                width: 200,
                height: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 6,
              }}
            >
              <Text style={{ color: colors.green }}>Save Information</Text>
            </TouchableOpacity>
            <Image
              style={{
                // bottom: 250,
                // backgroundColor: "white",
                // position: "absolute",
                // right: 560,
                width: 200,
                height: 200,
                resizeMode: "contain",
                // right:700,
                // marginBottom:50
              }}
              source={this.state.image}
            />
          </View>
        </View>

        <View
          style={{
            bottom: 100,
            // /  backgroundColor: "white",
            position: "absolute",
            right: 300,
          }}
        ></View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    position: "absolute",
    fontWeight: "bold",
    bottom: 40,
    color: colors.text,
    fontSize: 14,
    left: 5,
  },
  loginButton: {
    position: "absolute",
    bottom: 10,
    borderTopWidth: 1,
    width: "100%",
    height: 40,
    justifyContent: "center",
    borderTopColor: colors.white,
    alignSelf: "center",
    marginRight: 30,
  },
  registerButton: {
    width: "60%",
    borderWidth: 1,
    alignSelf: "center",
    height: 30,
    marginTop: 10,
    justifyContent: "center",
    borderRadius: 6,
    borderColor: colors.white,
    bottom: 40,
    width: 350,
    backgroundColor: colors.component,
    alignSelf: "center",
  },
  logoContainer: {
    width: "100%",
    top: 30,
    position: "absolute",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  logo: {
    color: colors.white,
    fontSize: 80,
    alignSelf: "center",
    // opacity:0.5
  },
  textInput: {
    height: 40,
    // marginLeft: 10,
    width: Dimensions.get("window").width - 40,
    alignSelf: "center",
    color: colors.white,
    paddingLeft: 30,
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: "center",
  },
  textInputContainer: {
    flexDirection: "row",
    marginHorizontal: 20,
    borderWidth: 1,
    borderColor: colors.white,
    marginTop: 10,
    borderRadius: 8,
    bottom: 40,
    width: 350,
    //alignSelf:'center',
    backgroundColor: colors.component,
  },
});
