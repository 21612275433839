import React from 'react'
import {View, Text, TouchableOpacity, Dimensions} from 'react-native'
import axios from 'axios'
import apiEndpoint from '../../../helpers/networking/apiEndpoint'
import colors from '../../../styles/colors'
export default class RefundsClaimedScreen extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading:true
        }
    }

    componentDidMount(){
     this._getDataSource()   
    }

   // JSON.stringify(jsObj, null, "\t"); // stringify with tabs inserted at each level
//JSON.stringify(jsObj, null, 4);    // stringify with 4 spaces at each level

    _getDataSource = async () => {
        await axios
          .get(apiEndpoint.getRefundsClaimed, 
            )
          .then(res => this.setState({ dataSource: res.data, isLoading: false }))
          .catch(err => {
            console.log("err: steffiescherm" + err);
            //   this._getDataSource()
          });
      };
    
    render(){
        if (this.state.isLoading === true) {
            return (
              <View
                style={{
                  //flex:1,
                  height: Dimensions.get("window").height,
                  backgroundColor: colors.background
                }}
              >
                {/* <ActivityIndicator /> */}
              </View>
            );
          } else {
        return(
            <View
            style={{
                //flex:1,
                height: Dimensions.get("window").height,
                backgroundColor: colors.background
              }}
            >
                <Text style={{color:colors.text}}>{JSON.stringify(this.state.dataSource, null, "\t")}</Text>

            </View>
        )
    }
}
}