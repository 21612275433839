import React from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  TextInput,
  Dimensions,
  StyleSheet,
} from 'react-native';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import colors from '../../../styles/colors';
// import {ifIphoneX} from 'react-native-iphone-x-helper';

// import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';
import apiEndpoint from '../../../helpers/networking/apiEndpoint';
//import authJWT from '../../../helpers/networking/authJWT';
// import {Toast} from 'native-base';
import SpaIcon from '@material-ui/icons/Spa';
const inputArr = [
  {icon: 'account', value: '', secureTextEntry:false, placeholder:'Email of Username'},
  {icon: 'lock', value: '', secureTextEntry:true, placeholder:'Wachtwoord'},
];

const { detect } = require('detect-browser');



export default class LoginScreen extends React.Component {
  
  constructor(props){
      super(props);

  }
  state = {
    inputArr: inputArr,
  };

  // handleLogin = async () => {
  //  this.props.navigation.navigate('Dashboard')
  // };
  setTokens = async res => {

    try {
      await localStorage.setItem('accessToken', res.data.accessToken);
    } catch (error) {
      console.log('error Accestoken : ' + error);
      // Error saving data
    }
    try {
      await localStorage.setItem('refreshToken', res.data.refreshToken);
         this.props.navigation.navigate('Dashboard');
        // this.props.navigation.navigate('Intro_Welcome');
    } catch (error) {
      console.log('error Refreshtoknen : ' + error);
    }
  };


 handleLogin = ()=> {

  const browser = detect();
 
// handle the case where we don't detect the browser
if (browser) {
  console.log(browser.name);
  console.log(browser.version);
  console.log(browser.os);
}

  let data = {
    email: this.state.inputArr[0].value,
    password: this.state.inputArr[1].value,
    type:browser.os,
    uniqueId:browser.os,
    model:browser.name,
    // pushNotificationToken:browser.name,
    systemName: browser.name,
    systemVersion:browser.version,
    manufacturer: browser.os,
    macAddress: browser.os,
    ipAddress: browser.os,
    deviceName: browser.os

  };
  
    axios.post(apiEndpoint.login,data,{
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
      //  alert('res', res)
     // console.log('res: => ', res)
         this.setTokens(res);
      
      }).catch(err => 

        alert(err)

       // console.log('Login Error : => ' + err)
        );
 
};

   




  handleTextInput = (input, i) => {
    let text = input
    const updatedArray = [...this.state.inputArr];
    updatedArray[i].value = text;
    this.setState({
      inputArr: updatedArray,
    });
  };

  render() {
    return (
      <View
        style={{
       //   flex: 1,
          height:Dimensions.get('window').height,
          backgroundColor: colors.background,
          justifyContent: 'center',
        }}>
          <View style={styles.logoContainer}>
          {/* <Icon
            onPress={() => this._signOutAsync('sdfj')}
            name={'tag-heart'}
            style={styles.logo}
          /> */}
          <Text
            style={{color: colors.text, fontSize: 20, alignSelf: 'center'}}>
           
          </Text>

          <Text
            style={{
              color: colors.text,
              fontSize: 14,
              alignSelf: 'center',
              left: 2,
            }}>
           Faka neef
          </Text>
<SpaIcon
style={{color:colors.component, fontSize:100, marginTop:60}}
/>
        </View>
        <View
          style={{
            justifyContent: 'center',
          }}>
          <View style={{bottom:20}}>
            {this.state.inputArr.map((item, i) => (
              <View 
              key={i}
              style={styles.textInputContainer}>
                {/* <Icon name={item.icon} style={styles.textInputIcon} /> */}
                <TextInput
                secureTextEntry={item.secureTextEntry}
                type={item.type}
                  placeholderTextColor={colors.white}
                  placeholder={item.placeholder}
                  onChangeText={text => this.handleTextInput(text, i)}
                   value={item.value}
                  

                  style={styles.textInput}
                />
              </View>
            ))}
          </View>

          <TouchableOpacity
            onPress={() => this.handleLogin()}
            style={styles.registerButton}>
            <Text style={{alignSelf: 'center', color: colors.white}}>
            INLOGGEN
            </Text>
          </TouchableOpacity>
        </View>
        {/* <TouchableOpacity
          onPress={() => this.handleRegister()}
          style={styles.loginButton}>
          <Text style={{color: colors.white, alignSelf: 'center'}}>
            REGISTREER
          </Text>

        </TouchableOpacity> */}

        {/* <Icon
            name={'tag-heart'}
            style={{
              position:'absolute',
              alignSelf:'center',
              top:30,
              fontSize: 300, color: colors.white, opacity: 0.3,}}
          /> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  loginButton: {
    position: 'absolute',
    bottom: 10,
    borderTopWidth: 1,
    width: '100%',
    height: 40,
    justifyContent: 'center',
    borderTopColor: colors.white,
    alignSelf: 'center',
    marginRight: 30,
  },
  registerButton: {
    width: '60%',
    borderWidth: 1,
    alignSelf: 'center',
    height: 30,
    marginTop: 10,
    justifyContent: 'center',
    borderRadius: 6,
    borderColor: colors.white,
    bottom:40,
    width:350,
    backgroundColor:colors.component,
    alignSelf:'center'
  },
  logoContainer: {
    width: '100%',
    top: 30,
    position: 'absolute',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  
    
  },
  logo: {
    color: colors.white,
    fontSize: 80,
    alignSelf: 'center',
   // opacity:0.5
  },
  textInput: {
    height: 40,
   // marginLeft: 10,
    width: Dimensions.get('window').width - 90,
    alignSelf: 'center',
    color: colors.white,
    paddingLeft:30
  },
  textInputIcon: {
    left: 5,
    color: colors.white,
    fontSize: 30,
    alignSelf: 'center',
  },
  textInputContainer: {
    flexDirection: 'row',
    marginHorizontal: 20,
    borderWidth: 1,
    borderColor:colors.subText,
    marginTop: 10,
    borderRadius: 8,
    bottom:40,
    width:350,
    alignSelf:'center',
    backgroundColor:colors.component,
  },
});
