import React from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Image,
  Alert,
  TextInput,
} from "react-native";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import moment from "moment";
import TextInputComponent from "./TextInputComponent";
import DatePicker from "react-datepicker";
import apiEndpoint from "../../../helpers/networking/apiEndpoint";
import axios from "axios";
import colors from "../../../styles/colors";
import  CheckIcon from "@material-ui/icons/Check";
import { Box, Modal, Typography } from "@material-ui/core";

// import colors from "../../../styles/colors";

export default class InvoiceComponent extends React.Component {
  state = {
    checkProduct: false,
    checkLineItem: false,
    steffie: this.props.item,
    newProductName: null,
    newPricePaid: null,
    newImageUrl: null,
    newProductWebsiteUrl: null,
    lineItems: this.props.item?.lineItems,
  };

  handleCheck = (type) => {
    const check = this.state[type];
    this.setState({
      [type]: !check,
    });
  };

  updateValues = (key, value, index) => {
    //   const newItems = [...this.state.item];
    const newItems = this.state.steffie;
    newItems[key] = value;
    this.setState({
      steffie: newItems,
    });
  };

  updateLineItems = (key, value, index) => {
    //   const newItems = [...this.state.item];
    const newItems = this.state.lineItems;
    newItems[index][key] = value;
    this.setState({
      lineItems: newItems,
    });
  };
  updateProduct = (key, value, index) => {
    //   const newItems = [...this.state.item];
    const newItems = this.state.lineItems;
    newItems[index].product[key] = value;
    this.setState({
      lineItems: newItems,
    });
  };

  updateInvoiceData = async (i, value) => {
    // let data = {
    //   queStatus: this.state.queStatus,
    //   emailComplaints: this.state.emailComplaints,
    //   orderNumber: this.state.orderNumber,
    //   invoiceNumber: this.state.invoiceNumber,
    //   paymentAmount: this.state.paymentAmount,
    //   discount: this.state.discount,
    //   invoiceDate: this.state.invoiceDate,
    //   orderDate: this.state.orderDate,
    //   paymentAmount: this.state.paymentAmount,
    //   deliveryCosts: this.state.deliveryCosts,
    //   endReturnPeriodDate: this.state.endReturnPeriodDate,
    // };

    // const item = this.props.navigation.getParam('item')

    const invoiceId = this.state.steffie.id;

    try {
      await axios
        .put(apiEndpoint.putSteffie + invoiceId, this.state.steffie)
        .then((res) => {
          alert("jup");
          console.log("uh huh huh uh u : => ", res);
          // alert("gelukt");
        });
    } catch (err) {
      alert("jammer joh");
    }
  };

  // Dus checken of het product al bestaat in de database <-- zo ja, dan
  nieuwLineItemToevoegen = () => {
    if (window.confirm(`Wil je een nieuw orderregel toevoegen?`)) {
      // Save it!
      console.log("Thing was saved to the database.");
      this.addNewLinItem()
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }

  }



  updateLineItem = async () => {

    

  }

  // Dit moet een findOrCreateProduct worden, en dan post lineItem, kan wel volledig op de backend

  postLineItem = async () => {

    const data = {
      productName: this.state.newProductName,
      pricePaid: this.state.newPricePaid,
      webshopId: this.state.steffie.webshopId,
      invoiceId: this.state.steffie.invoices[0].id,
      steffieId: this.state.steffie.id,
      userId: this.state.steffie.userId,
      imageUrl: this.state.newImageUrl,
      productWebsiteUrl: this.state.newProductWebsiteUrl,
    };


    // steffie: this.props.item,
    // newProductName: null,
    // newPricePaid: null,
    // newImageUrl: null,
    // newProductWebsiteUrl: null,


    try {
      await axios
        .post(apiEndpoint.createLineItemCreateProductIfNotExist, data)
        .then((res) => {

          alert('jup')
        });
      //   this.getSteffieLineItems();
      //   if (type === "next") {
      //     this.setState({
      //       pricePaid: "",
      //       productName: "",
      //     });
      //   }
      // });
    } catch (error) {
      alert("Niet gelukt");
    }
  }






  addNewLinItem = () => {

    this.postLineItem()
    
    const newLineLitem = {
      newLineItem: this.state.newProductName,
      pricePaid: this.state.newPricePaid,
      // newImageUrl:this.state.newImageUrl,
      productWebsiteUrl: this.state.newProductWebsiteUrl,
      product: {
        name: this.state.newProductName,
        productWebsiteUrl: this.state.newProductWebsiteUrl,
        originImageURL: this.state.originImageURL,
      },
    };
    this.setState({
      lineItems: [...this.state.lineItems, newLineLitem],
    });
  };

  updateLineItem = async  () => {
    // axios call
    // state updaten
  };


  //postLineItem
  deleteLineItem = async (lneItemId) => {
       await axios.delete(apiEndpoint.deleteLineItem + lneItemId).then(()=> alert('ok')).catch((err)=> alert(err))
  }

  saveLineItemChanges = (item) => {
    if (window.confirm(`De wijzigingen van ${item.product.name} opslaan?`)) {
      // Save it!
      console.log("Thing was saved to the database.");
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  };

  removeLineItem = (item) => {
    if (window.confirm(`${item.product.name} verwijderen?`)) {
      // Save it!
      this.deleteLineItem(item.id)
      console.log("Thing was saved to the database.");
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
    // showAlert()
    const newItems = this.state.lineItems;

    // alert(id);
  };

  render() {
    const { refeuroInvoiceId } = this.state.steffie
    return (
      <div>

        {JSON.stringify(this.state.steffie)}
        <tr>
          <td>
            <span style={{ color: colors.text }}>invoiceNumber</span>
          </td>
          <td>
            <input
              value={this.state.steffie?.invoiceNumber}
              placeholder={"x"}
              onChange={(e) =>
                this.updateValues("invoiceNumber", e.target.value)
              }
              style={{
                color: colors.red,
                marginLeft: 10,
                backgroundColor: colors.component,
              }}
            />
          </td>
          <td>
          {/* <TouchableOpacity
            onPress={() =>
              window.open(
                `https://steffieapp.nl/web/unaccepted/${refeuroInvoiceId}`
              )
            }
            // onPress={()=> this.nextSteffie()}
            // onPress={() => this.handleInvoiceToAccepted()}
            style={{
              // marginVertical: 10,
              backgroundColor: colors.component,
              borderWidth: 1,
              borderColor: colors.green,
              width: 200,
              height: 40,
              marginRight: 5,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 6,
            }}
          >
            <Text style={{ color: colors.green }}>Open Refeuro Scraper</Text>
          </TouchableOpacity> */}
          <button onClick={() =>
                   window.open(
                    `https://steffieapp.nl/web/unaccepted/${refeuroInvoiceId}`
                  )
                }>Open Refeuro Scraper</button>
          </td>
        </tr>

        <tr>
          <td>
            <span style={{ color: colors.text }}>orderNumber</span>
          </td>{" "}
          <td>
            <input
              value={this.state.steffie.orderNumber}
              placeholder={"x"}
              // onChange={val => console.log(val)}
              onChange={(e) => this.updateValues("orderNumber", e.target.value)}
              style={{
                color: colors.red,
                marginLeft: 10,
                backgroundColor: colors.component,
              }}
            />
          </td>
          <td>

            <button onClick={() =>
                  this.updateInvoiceData()
                }>Wijzigingen opslaan</button>
          {/* <TouchableOpacity
                onPress={() =>
                  this.updateInvoiceData()
                }
                style={{
                  // marginVertical: 10,
                  backgroundColor: colors.component,
                  borderWidth: 1,
                  borderColor: colors.text,
                  width: 200,
                  height: 35,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 6,
                }}
              >
                <Text style={{ color: colors.text }}>Wijzigingen opslaan</Text>
                <CheckIcon
                  style={{ color: colors.green, position: "absolute", right: 10 }}
                />
              </TouchableOpacity> */}
          </td>
        </tr>

        <tr>
          <td>
            <span style={{ color: colors.text }}>discount</span>
          </td>
          <td>
            <input
              value={this.state.steffie.discount}
              placeholder={"x"}
              onChange={(e) => this.updateValues("discount", e.target.value)}
              style={{
                color: colors.red,
                marginLeft: 10,
                backgroundColor: colors.component,
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ color: colors.text }}>paymentAmount</span>
          </td>
          <td>
            <input
              value={this.state.steffie.paymentAmount}
              placeholder={"x"}
              onChange={(e) =>
                this.updateValues("paymentAmount", e.target.value)
              }
              style={{
                color: colors.red,
                marginLeft: 10,
                backgroundColor: colors.component,
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ color: colors.text }}>orderDate</span>
          </td>
          <td>
            <input
              type="date"
              value={moment(this.state.steffie?.orderDate).format("YYYY-MM-DD")}
              placeholder={"x"}
              onChange={(e) => this.updateValues("orderDate", e.target.value)}
              style={{
                color: colors.red,
                marginLeft: 10,
                backgroundColor: colors.component,
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ color: colors.text }}>invoiceDate</span>
          </td>
          <td>
            <input
              type="date"
              value={moment(this.state.steffie?.invoiceDate).format(
                "YYYY-MM-DD"
              )}
              placeholder={"x"}
              onChange={(e) => this.updateValues("invoiceDate", e.target.value)}
              style={{
                color: colors.red,
                marginLeft: 10,
                backgroundColor: colors.component,
              }}
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ color: colors.text }}>endReturnPeriodDate</span>
          </td>
          <td>
            <input
              type="date"
              value={moment(this.state.steffie?.endReturnPeriodDate).format(
                "YYYY-MM-DD"
              )}
              placeholder={"x"}
              onChange={(e) =>
                this.updateValues("endReturnPeriodDate", e.target.value)
              }
              style={{
                color: colors.red,
                marginLeft: 10,
                backgroundColor: colors.component,
              }}
            />
          </td>
        </tr>

        <table>
          <thead>
            <th>Productnaam</th>
            <th>PricePaid</th>
            <th>Product URL</th>
            <th>Product image url</th>
            <th>Product image</th>
          </thead>
          <tbody>
            {this.state.lineItems
              ? this.state.lineItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        value={item.product.name}
                        placeholder={"Productname ..."}
                        onChange={(e) =>
                          this.updateProduct("name", e.target.value, index)
                        }
                        style={{
                          color: colors.red,
                          marginLeft: 10,
                          width: 300,
                          backgroundColor: colors.component,
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={item.pricePaid}
                        placeholder={"PricePaid"}
                        onChange={(e) =>
                          this.updateLineItems(
                            "pricePaid",
                            e.target.value,
                            index
                          )
                        }
                        style={{
                          width: 50,
                          color: colors.red,
                          marginLeft: 10,
                          backgroundColor: colors.component,
                        }}
                      />
                    </td>
                    <td>
                      <input
                        value={item.product.productWebsiteUrl}
                        placeholder={"productWebsiteUrl"}
                        onChange={(e) =>
                          this.updateProduct(
                            "productWebsiteUrl",
                            e.target.value,
                            index
                          )
                        }
                        style={{
                          color: colors.red,
                          marginLeft: 10,
                          backgroundColor: colors.component,
                        }}
                      />
                    </td>

                    <td>
                      <input
                        value={item.product.originImageURL}
                        placeholder={"Product image url ..."}
                        onChange={(e) =>
                          this.updateProduct(
                            "originImageURL",
                            e.target.value,
                            index
                          )
                        }
                        style={{
                          color: colors.red,
                          marginLeft: 10,
                          backgroundColor: colors.component,
                        }}
                      />
                    </td>
                    <td>
                      <Image
                        style={{
                          // bottom: 250,
                          // backgroundColor: "white",
                          // position: "absolute",
                          // right: 560,
                          width: 50,
                          height: 50,
                          resizeMode: "contain",
                          // right:700,
                          // marginBottom:50
                        }}
                        source={item.product.image}
                      />
                    </td>
                    <td>
                      <CancelIcon onClick={() => this.removeLineItem(item)} />
                    </td>
                    <td>
                      <SaveIcon
                        onClick={() => this.saveLineItemChanges(item)}
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td>
                <input
                  value={this.state.newProductName}
                  placeholder={"Productname ..."}
                  onChange={(e) =>
                    this.setState({ newProductName: e.target.value })
                  }
                  style={{
                    color: colors.red,
                    marginLeft: 10,
                    width: 300,
                    backgroundColor: colors.component,
                  }}
                />
              </td>
              <td>
                <input
                  value={this.state.newPricePaid}
                  placeholder={"PricePaid"}
                  onChange={(e) =>
                    this.setState({ newPricePaid: e.target.value })
                  }
                  style={{
                    width: 50,
                    color: colors.red,
                    marginLeft: 10,
                    backgroundColor: colors.component,
                  }}
                />
              </td>
              <td>
                <input
                  value={this.state.newProductWebsiteUrl}
                  placeholder={"productWebsiteUrl"}
                  onChange={(e) =>
                    this.setState({ newProductWebsiteUrl: e.target.value })
                  }
                  style={{
                    color: colors.red,
                    marginLeft: 10,
                    backgroundColor: colors.component,
                  }}
                />
              </td>

              <td>
                <input
                  value={this.state.newImageUrl}
                  placeholder={"Product image url ..."}
                  onChange={(e) =>
                    this.setState({ newImageUrl: e.target.value })
                  }
                  style={{
                    color: colors.red,
                    marginLeft: 10,
                    backgroundColor: colors.component,
                  }}
                />
                {this.state.newImageUrl}
              </td>
              <td>
                <Image
                  style={{
                    // bottom: 250,
                    // backgroundColor: "white",
                    // position: "absolute",
                    // right: 560,
                    width: 50,
                    height: 50,
                    resizeMode: "contain",
                    // right:700,
                    // marginBottom:50
                  }}
                  // source={item.product.image}
                />
              </td>

              <td>
                <AddIcon onClick={() => this.nieuwLineItemToevoegen()} />
              </td>
            </tr>
          </tbody>
        </table>

     

        {/* {JSON.stringify(this.state.steffie)} */}
      </div>
    );
  }
}
